import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getCustomerAndUserInfo} from '../../appSlice';
import {
  getTaskConfigsData,
  updateTableLoading,
  setTasksBulkEditMode,
  initializeBulkEditTasks,
  updateBulkEditTasks,
  setTasksBulkEditErrors,
} from './settingsSlice';
import {
  searchFind,
  rowApplicationTypeMapping,
  modalBoxStyle,
  hideCell,
  squareButton,
  fetchPostAuthSafe,
  unitsLengthSubmitConversion,
  unitsLengthDisplayConversion,
  deepCopy,
  handleAllowOnlyNumbers
} from '../../app/utils';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../components/Table';
import {Tailselect} from '../../components/Tailselect';
import {TabMenuTableWrapper} from '../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../components/CustomTablePagination';
import {BulkUploadModal} from '../../components/BulkUploadModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {BulkEditInputs} from './BulkEditInputs';
import {
  ModalSubmitting,
  SettingsText,
  SettingsTextAndToggle,
  SettingsSelect,
  SettingsSwitch,
  ViewOnlyText,
  NoBorderTableRow,
  NoBorderTableCell
} from '../../components/GeneralComponents';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
  TextField,
  Box,
  Button,
  CircularProgress,
  Chip,
  Grid,
  Select,
  Typography,
  Switch,
  MenuItem,
  Alert
} from '@mui/material';

function TasksTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.tableLoading.task;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const tasksBulkEditErrors = useSelector((state) => {
    return state.settings.tasksBulkEditErrors;
  });
  const tasksBulkEditMode = useSelector((state) => {
    return state.settings.tasksBulkEditMode;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTask, setModalTask] = useState('');
  const [modalError, setModalError] = useState('');
  const [taskNamesList, setTaskNamesList] = useState([]);
  const [propagateAllColumns, setPropagateAllColumns] = useState({});

  const [modalFormValues, setModalFormValues] = useState({
    id: '',
    name: '',
    rowApplicationType: '',
    rowApplicationValue: '',
    enableAcreageCapping: false,
    defaultReiHours: '',
    defaultReiHoursEnabled: false,
    cycleDays: '',
    cycleDaysEnabled: false,
    avgSpeedTarget: 0, // Note: Current not editable, but bulk upload & backend for this is in place
    enableSpeedVisualization: false,
    speedTargetMinKph: 0,
    speedTargetMaxKph: 0,
    archived: false,
  });

  const [filterOptions, setFilterOptions] = useState({
    taskConfigTaskId: [],
    taskConfigRapType: Object.keys(rowApplicationTypeMapping).map((key) => {
      if (rowApplicationTypeMapping[key] == 'Limited') {
        return null;
      }
      return rowApplicationTypeMapping[key];
    }),
    taskConfigStatus: ['Active', 'Archived'],
  });

  const [filters, setFilters] = useState({
    taskConfigTaskId: [],
    taskConfigRapType: [],
    taskConfigStatus: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function resetBulkEdits() {
    const taskDictCopy = deepCopy(taskConfigDict);
    Object.keys(taskConfigDict).forEach((taskId) => {
      taskDictCopy[taskId].rowsOrWidthValue =
        taskDictCopy[taskId].rowApplicationType == 0
          ? unitsLengthSystem == 'imperial'
            ? pasreFloat(unitsLengthDisplayConversion(taskDictCopy[taskId].width, 'ft').toFixed(2))
            : pasreFloat(taskDictCopy[taskId].width.toFixed(2))
          : taskDictCopy[taskId].applicationRows;
    });
    dispatch(initializeBulkEditTasks(taskDictCopy));
  }

  useEffect(async () => {
    let tempTableData = [];
    let rapLimitedPresence = false;
    const taskDictCopy = deepCopy(taskConfigDict);
    Object.keys(taskConfigDict).forEach((taskId) => {
      taskDictCopy[taskId].rowsOrWidthValue =
        taskDictCopy[taskId].rowApplicationType == 0
          ? unitsLengthSystem == 'imperial'
            ? parseFloat(unitsLengthDisplayConversion(taskDictCopy[taskId].width, 'ft').toFixed(2))
            : pasreFloat(taskDictCopy[taskId].width.toFixed(2))
          : taskDictCopy[taskId].applicationRows;

      tempTableData.push(taskDictCopy[taskId]);
      if (
        rowApplicationTypeMapping[taskDictCopy[taskId]?.rowApplicationType] &&
        rowApplicationTypeMapping[taskDictCopy[taskId]?.rowApplicationType] == 'Limited'
      ) {
        rapLimitedPresence = true;
      }
    });
    tempTableData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    tempTableData.sort((a, b) => {
      return a.archived === b.archived ? 0 : a.archived ? 1 : -1;
    });

    const taskConfigFilterOptions = tempTableData.map((task) => {
      const tempTaskOption = {
        value: task.taskId,
        text: task.name,
      };
      return tempTaskOption;
    });
    const newFilterOptions = {
      ...filterOptions,
      taskConfigTaskId: taskConfigFilterOptions,
    };
    if (rapLimitedPresence) {
      newFilterOptions.taskConfigRapType = Object.keys(rowApplicationTypeMapping).map((key) => {
        return rowApplicationTypeMapping[key];
      });
    }
    setFilterOptions(newFilterOptions);
    tempTableData = tempTableData.filter((task) => {
      return filterRow(task);
    });

    const tempTaskNamesList = Object.keys(taskConfigDict).map((taskId) => {
      return taskConfigDict[taskId].name;
    });
    setTaskNamesList(tempTaskNamesList);

    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    dispatch(initializeBulkEditTasks(taskDictCopy));

    setTableData(tempTableData);
  }, [taskConfigDict]);

  useEffect(async () => {
    let tempTableData = [];
    const taskDictCopy = deepCopy(taskConfigDict);
    Object.keys(taskDictCopy).forEach((taskId) => {
      taskDictCopy[taskId].rowsOrWidthValue =
        taskDictCopy[taskId].rowApplicationType == 0
          ? unitsLengthSystem == 'imperial'
            ? parseFloat(unitsLengthDisplayConversion(taskDictCopy[taskId].width, 'ft').toFixed(2))
            : parseFloat(taskDictCopy[taskId].width.toFixed(2))
          : taskDictCopy[taskId].applicationRows;
      tempTableData.push(taskDictCopy[taskId]);
    });
    tempTableData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    tempTableData.sort((a, b) => {
      return a.archived === b.archived ? 0 : a.archived ? 1 : -1;
    });
    tempTableData = tempTableData.filter((task) => {
      return filterRow(task);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);
  }, [filters, searchText]);

  useEffect(async () => {
    const newTask = !modalTask;
    const taskData = taskConfigDict[modalTask];

    // Determine which application value to use
    let applicationValue = 0;
    if (taskData && taskData.rowApplicationType == 2) {
      // Default to 1 row to ensure acreage calculation is not 0
      applicationValue = Math.max(0.5, taskData.applicationRows);
    } else if (taskData) {
      applicationValue = taskData.width;
      if (unitsLengthSystem == 'imperial') {
        applicationValue = parseFloat(unitsLengthDisplayConversion(applicationValue, 'ft').toFixed(2));
      } else {
        applicationValue = parseFloat(unitsLengthDisplayConversion(applicationValue, 'm').toFixed(2));
      }
    }

    // Do unit conversion for speed targets
    let avgSpeedTargetValue = 0; // Note: Current not editable, but bulk upload & backend for this is in place
    let minSpeedTargetValue = 0;
    let maxSpeedTargetValue = 0;
    if (taskData) {
      avgSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.avgSpeedTarget, 'kph').toFixed(2));
      minSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.speedTargetMinKph, 'kph').toFixed(2));
      maxSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.speedTargetMaxKph, 'kph').toFixed(2));
      if (unitsLengthSystem == 'imperial') {
        avgSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.avgSpeedTarget, 'mph').toFixed(2));
        minSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.speedTargetMinKph, 'mph').toFixed(2));
        maxSpeedTargetValue = parseFloat(unitsLengthDisplayConversion(taskData.speedTargetMaxKph, 'mph').toFixed(2));
      }
    }

    setModalFormValues({
      id: newTask ? '' : taskData.taskId,
      name: newTask ? '' : taskData.name,
      rowApplicationType: newTask ? 0 : taskData.rowApplicationType,
      rowApplicationValue: newTask ? 0 : applicationValue,
      enableAcreageCapping: newTask ? false : taskData.enableAcreageCapping,
      defaultReiHours: newTask ? '' : taskData.defaultReiHours,
      defaultReiHoursEnabled: newTask ? false : taskData.defaultReiHours != '',
      cycleDays: newTask ? '' : taskData.cycleDays,
      cycleDaysEnabled: newTask ? false : taskData.cycleDays != '',
      avgSpeedTarget: newTask ? 0 : avgSpeedTargetValue,
      enableSpeedVisualization: newTask ? false : taskData.enableSpeedVisualization,
      speedTargetMinKph: newTask ? 0 : minSpeedTargetValue,
      speedTargetMaxKph: newTask ? 0 : maxSpeedTargetValue,
      archived: newTask ? false : taskData.archived,
    });
  }, [modalTask]);

  function validateInputChange(value, propertyName) {
    if (propertyName == 'rowsOrWidthValue') {
      if (value == '') {
        return true;
      }
      return typeof value === 'string' && /^(\d+(\.\d*)?|\.\d+)$/.test(value) && isFinite(Number(value));
    }
    return false;
  }

  function renderYesNo() {
    return [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ];
  }

  function renderRowApplicationTypeOptions() {
    const res = [];
    Object.keys(rowApplicationTypeMapping).forEach((typeNum) => {
      if (typeNum != 1) {
        res.push({label: rowApplicationTypeMapping[typeNum], value: typeNum});
      }
    });
    return res;
  }

  function renderArchivedOptions() {
    return [
      {label: 'Archived', value: true},
      {label: 'Active', value: false},
    ];
  }

  function handleBulkEditTextFields(entityIdName, entityId, propertyName, propertyValue) {
    const tasksBulkEditCopy = deepCopy(tasksBulkEditErrors);
    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = tableData.map((taskConfig) => {
        return taskConfig.taskId;
      });
    }

    if (propertyName == 'rowApplicationType') {
      propertyValue = parseFloat(propertyValue);
    }

    updateRowsId.forEach((taskId) => {
      const taskHasError =
        tasksBulkEditErrors.hasOwnProperty(taskId) && tasksBulkEditErrors[taskId].hasOwnProperty(propertyName);
      if (taskHasError) {
        delete tasksBulkEditCopy[taskId][propertyName];
        if (Object.keys(tasksBulkEditCopy[taskId]).length == 0) {
          delete tasksBulkEditCopy[taskId];
        }
      }
      dispatch(
        updateBulkEditTasks({
          [entityIdName]: taskId,
          data: {
            [propertyName]: propertyValue,
          },
        })
      );
    });

    dispatch(setTasksBulkEditErrors(tasksBulkEditCopy));
  }

  function handleToggleBulkEditPropagateColumn(propertyName) {
    setPropagateAllColumns((value) => {
      return {
        ...value,
        [propertyName]: value.hasOwnProperty(propertyName) ? !value[propertyName] : true,
      };
    });
  }

  const handleModalChange = (event) => {
    setModalError(''); // Clear any error message
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if ((name == 'defaultReiHours' || name == 'cycleDays') && !value.match(/^(\d*\.{0,0}$)/)) {
      // Truncate at 0 decimal places
      return;
    }
    if (event.target.type == 'number' && !value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
      // Truncate at 2 decimal places
      return;
    }

    // Clear default rei if untoggled
    if (name == 'defaultReiHoursEnabled' && value == false) {
      setModalFormValues((values) => {
        return {...values, defaultReiHours: ''};
      });
    }

    // Clear cycle if untoggled
    if (name == 'cycleDaysEnabled' && value == false) {
      setModalFormValues((values) => {
        return {...values, cycleDays: ''};
      });
    }

    // Default row application value to 1 if row application type is rows
    if (name == 'rowApplicationType' && value == 2) {
      setModalFormValues((values) => {
        return {...values, rowApplicationValue: 1};
      });
    }

    // Clear speed min / max if speed visualization is disabled
    if (name == 'enableSpeedVisualization' && value == false) {
      setModalFormValues((values) => {
        return {...values, speedTargetMinKph: 0, speedTargetMaxKph: 0};
      });
    }

    // Check for name changes to perform string matching for capping acreage
    if (name == 'name') {
      // Hard coded for now, make into a setting if more options are flexibility are needed
      // Default enableAcreageCapping to true if name contains "harv"
      const defaultValue = value.toLowerCase().includes('harv') ? true : false;

      setModalFormValues((values) => {
        return {...values, enableAcreageCapping: defaultValue};
      });
    }

    setModalFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  async function handleModalSubmit() {
    dispatch(updateTableLoading({table: 'task', status: true}));
    setSubmitting(true);
    setModalError('');

    // Ensure a task name is entered, keep modal open and display error if not
    if (modalFormValues.name == '') {
      setModalOpen(true);
      setSubmitting(false);
      dispatch(updateTableLoading({table: 'task', status: false}));
      setModalError('Please enter a task name');
      return;
    }

    // Determine values of width and row application
    let widthValue = 0;
    let rowValue = 0;
    if (modalFormValues.rowApplicationType == 2) {
      rowValue = parseFloat(modalFormValues.rowApplicationValue || 0);

      // If a row quantity less than 1 is entered, keep modal open and display error
      if ((rowValue != 0.5 && rowValue % 1 != 0) || rowValue <= 0) {
        setModalOpen(true);
        setSubmitting(false);
        dispatch(updateTableLoading({table: 'task', status: false}));
        setModalError('Please enter a row quantity of 0.5 or a postive whole number');
        return;
      }
    } else {
      widthValue = parseFloat(modalFormValues.rowApplicationValue || 0);
      if (unitsLengthSystem == 'imperial') {
        widthValue = unitsLengthSubmitConversion(widthValue, 'ft');
      }
    }

    // Check target values if speed visualization is enabled & provde error message if missing
    const minSpeedTargetMissing = (modalFormValues.speedTargetMinKph == 0 || modalFormValues.speedTargetMinKph == '' ||
      modalFormValues.speedTargetMinKph == null || modalFormValues.speedTargetMinKph == undefined)
    const maxSpeedTargetMissing = (modalFormValues.speedTargetMaxKph == 0 || modalFormValues.speedTargetMaxKph == '' ||
      modalFormValues.speedTargetMaxKph == null || modalFormValues.speedTargetMaxKph == undefined)
    const minLargerThanMax = (modalFormValues.speedTargetMinKph >= modalFormValues.speedTargetMaxKph)
    if (
      modalFormValues.enableSpeedVisualization == true && 
      (minSpeedTargetMissing || maxSpeedTargetMissing || minLargerThanMax)
    ) {
      setModalOpen(true);
      setSubmitting(false);
      dispatch(updateTableLoading({table: 'task', status: false}));

      let message = '';
      if (minSpeedTargetMissing || maxSpeedTargetMissing) {
        message = 'Please enter a value for the'
        if (minSpeedTargetMissing) {
          message += ' min speed target';
        }
        if (maxSpeedTargetMissing) {
          message += ', max speed target';
        }
      } else if (minLargerThanMax) {
        if (minLargerThanMax) {
          message += 'Please ensure the minimun target is less than the maximun target';
        }
      }

      setModalError(message);
      return;
    }

    // Determine rei value
    let reiValue = parseInt(modalFormValues.defaultReiHours || 0);
    if (!reiValue) {
      reiValue = '';
    }

    // Determine cycle value
    let cycleValue = parseInt(modalFormValues.cycleDays || 0);
    if (!cycleValue) {
      cycleValue = '';
    }

    // Round to 2 decimal places
    // Note: (avgSpeedTarget) Current not editable, but bulk upload & backend for this is in place
    let avgSpeedTargetValue = parseFloat(modalFormValues.avgSpeedTarget);
    let minSpeedTargetValue = parseFloat(modalFormValues.speedTargetMinKph);
    let maxSpeedTargetValue = parseFloat(modalFormValues.speedTargetMaxKph);
    if (unitsLengthSystem == 'imperial') {
      avgSpeedTargetValue = unitsLengthSubmitConversion(avgSpeedTargetValue, 'mph');
      minSpeedTargetValue = unitsLengthSubmitConversion(minSpeedTargetValue, 'mph');
      maxSpeedTargetValue = unitsLengthSubmitConversion(maxSpeedTargetValue, 'mph');
    }

    // If enable speed visualization is false, set all speed values to 0
    if (!modalFormValues.enableSpeedVisualization) {
      avgSpeedTargetValue = 0;
      minSpeedTargetValue = 0;
      maxSpeedTargetValue = 0;
    }

    // Create doc
    const updateDoc = {
      taskId: modalFormValues.id,
      name: modalFormValues.name,
      width: widthValue,
      rowApplicationType: modalFormValues.rowApplicationType,
      applicationRows: rowValue,
      enableAcreageCapping: modalFormValues.enableAcreageCapping,
      defaultReiHours: reiValue,
      cycleDays: cycleValue,
      avgSpeedTarget: avgSpeedTargetValue,
      enableSpeedVisualization: modalFormValues.enableSpeedVisualization,
      speedTargetMinKph: minSpeedTargetValue,
      speedTargetMaxKph: maxSpeedTargetValue,
      archived: modalFormValues.archived,
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateTaskConfig',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    refreshData();
    setModalOpen(false);
    setSubmitting(false);
    setModalTask('');
    setModalError(''); // Clear any error message
  }

  function refreshData() {
    dispatch(getCustomerAndUserInfo());
    dispatch(getTaskConfigsData());
  }

  function handleBulkUploadModalOpen(openState) {
    setBulkUploadModalOpen(openState);
  }

  // Determine distance units
  let distUnits = 'm';
  if (unitsLengthSystem == 'imperial') {
    distUnits = 'ft';
  }

  let tasksCsvColumns = [
    {
      key: 'taskName',
      required: true,
      description: 'Name of the Task',
    },
    {
      key: 'acreCalUnit',
      required: false,
      description: `Acreage Calculation Type, Accepted Values: 'row' or 'width'`,
    },
    {
      key: 'acreCalValue',
      required: false,
      description: `Acreage Calculation values, Accepted Values: Positive Numbers, 
        Rows has 0.5 or whole numbers (Width Units: ${unitsLengthSystem == 'imperial' ? 'ft' : 'm'})`,
    },
    {
      key: 'avgSpeedTarget',
      required: false,
      description: `Average Speed Target for the Task (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`,
    },
    {
      key: 'acPerHrTarget',
      required: false,
      description: 'Acreage per Hour Target for the Task',
    },
    {
      key: 'cycleDays',
      required: false,
      description: 'Cycle Days for the Task',
    },
    {
      key: 'defaultReiHours',
      required: false,
      description: 'Default REI Hours for the Task',
    },
    {
      key: 'enableTaskAcreageCapping',
      required: false,
      description: `Enable Task Acreage Capping for the Task, \b
        Accepted Values: 'y', 'yes', 'true' for Yes, 'n', 'no', 'false' for No, Default: No`,
    },
    {
      key: 'enableSpeedVisualization',
      required: false,
      description: `Enable Speed Visualization for the Task, \b
        Accepted Values: 'y', 'yes', 'true' for Yes, 'n', 'no', 'false' for No, Default: No`,
    },
    {
      key: 'speedTargetMinKph',
      required: false,
      description: `Min Speed Target for the Task (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`,
    },
    {
      key: 'speedTargetMaxKph',
      required: false,
      description: `Max Speed Target for the Task (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`,
    },
  ];

  tasksCsvColumns = tasksCsvColumns.filter((col) => {
    if (col.key == 'enableTaskAcreageCapping' && !customerSettings.general?.enableTaskAcreageCapping) {
      return false;
    } else if (col.key == 'defaultReiHours' && !customerSettings.general?.enableTaskRei) {
      return false;
    } else if (col.key == 'cycleDays' && !customerSettings.general?.enableTaskCycle) {
      return false;
    } else return true;
  });

  function bulkUploadDataPreview(rowData, index, totalCount) {
    return (
      <div className='card mb-2 text-left' key={index}>
        <div className='card-header row mx-0 px-0'>
          <div className='col-8'>
            Name: {rowData.name}{' '}
            {rowData.errors && rowData.errors.length > 0 ? (
              <FontAwesomeIcon className='text-danger' icon='fas fa-warning' />
            ) : rowData.warnings && rowData.warnings.length > 0 ? (
              <FontAwesomeIcon className='text-warning' icon='fas fa-warning' />
            ) : (
              <FontAwesomeIcon className='text-success' icon='fas fa-check-circle' />
            )}
          </div>
          <div className='col-4 text-right'>
            {index + 1}/{totalCount}
          </div>
        </div>
        <div className='row mx-0 px-0'>
          <div className='col-md-6'>Acre Calculation: {rowApplicationTypeMapping[rowData.rowApplicationType]}</div>
          <div className='col-md-6'>
            {rowData.rowApplicationType == 2
              // Visible preview need to display in users units 
              ? `Rows: ${rowData.applicationRows.toFixed(2)} Rows`
              : `Width: ${
                unitsLengthSystem == 'imperial'
                  ? unitsLengthDisplayConversion(rowData.width, 'ft').toFixed(2)
                  : rowData.width.toFixed(2)
              } 
              ${unitsLengthSystem == 'imperial' ? 'ft' : 'm'}`}
          </div>
          <div className='col-md-6'>
            Average Speed Target:{' '}
            {unitsLengthSystem == 'imperial'
              ? unitsLengthDisplayConversion(rowData.avgSpeedTarget, 'mi').toFixed(2)
              : rowData.avgSpeedTarget.toFixed(2)}
            {unitsLengthSystem == 'imperial' ? ' mph' : 'kph'}
          </div>
          <div className='col-md-6'>
            {`${unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'}`}
            Per Hour Target: {rowData.acPerHrTarget}
          </div>
          <div className='col-md-6'>
              {`Enable Speed Visualization for the Task`}:
              {rowData.enableSpeedVisualization ? 'Yes' : 'No'}
          </div>
          <div className='col-md-6'>
            Min Speed Target:{' '}
            {unitsLengthSystem == 'imperial'
              ? unitsLengthDisplayConversion(rowData.speedTargetMinKph, 'mi').toFixed(2)
              : rowData.speedTargetMinKph.toFixed(2)}
            {unitsLengthSystem == 'imperial' ? ' mph' : 'kph'}
          </div>
          <div className='col-md-6'>
            Max Speed Target:{' '}
            {unitsLengthSystem == 'imperial'
              ? unitsLengthDisplayConversion(rowData.speedTargetMaxKph, 'mi').toFixed(2)
              : rowData.speedTargetMaxKph.toFixed(2)}
            {unitsLengthSystem == 'imperial' ? ' mph' : 'kph'}
          </div>
          {customerSettings.general?.enableTaskAcreageCapping && (
            <div className='col-md-6'>
              {`Enable ${unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'} Capping`}:
              {rowData.enableTaskAcreageCapping ? 'Yes' : 'No'}
            </div>
          )}
          {customerSettings.general.enableTaskRei && (
            <div className='col-md-6'>Default REI Hours: {rowData.defaultReiHours}</div>
          )}
          {customerSettings.general.enableTaskCycle && <div className='col-md-6'>Cycle Days: {rowData.cycleDays}</div>}
        </div>
        {((rowData.errors && rowData.errors.length > 0) || (rowData.warnings && rowData.warnings.length > 0)) && (
          <div className='card-footer'>
            {rowData.errors.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
            {rowData.warnings.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  function validateAndMapBulkUploadedData(data, dupCheckObject) {
    const requiredColumns = tasksCsvColumns
      .filter((column) => {
        return column.required;
      })
      .map((column) => {
        return column.key;
      });

    const noDupColumns = ['taskName'];

    const defaultTasks = {
      'name': '',
      'taskId': '',
      'width': 0,
      'archived': false,
      'rowApplicationType': 0,
      'applicationRows': 0,
      'enableAcreageCapping': false,
      'avgSpeedTarget': 0,
      'acPerHrTarget': 0,
      'defaultReiHours': '',
      'cycleDays': '',
    };

    const tempDoc = {
      ...defaultTasks,
      ...(Object.hasOwnProperty.call(data, 'taskName') ? {name: data['taskName']} : {}),
    };

    const errors = [];
    const warnings = [];

    noDupColumns.forEach((col) => {
      if (!Object.keys(dupCheckObject).includes(col)) {
        dupCheckObject[col] = [];
        dupCheckObject[col].push(data[col]);
      } else if (dupCheckObject[col].includes(data[col])) {
        errors.push(`Duplicate ${col}`);
      } else {
        dupCheckObject[col].push(data[col]);
      }
    });

    if (Object.hasOwnProperty.call(data, 'acreCalUnit')) {
      if (data['acreCalUnit'].toLowerCase() == 'row') {
        tempDoc['rowApplicationType'] = 2;
        if (Object.hasOwnProperty.call(data, 'acreCalValue')) {
          try {
            const rowCount = parseFloat(data['acreCalValue']);
            if (isNaN(rowCount)) {
              warnings.push(`Row Count is not a number, Entered value: ${data['acreCalValue']}`);
            } else if (rowCount % 1 != 0 && rowCount != 0.5) {
              warnings.push(
                `Row Count is not an integer or 0.5 please enter a valid number, Entered value: ${data['acreCalValue']}`
              );
            } else if (rowCount < 0) {
              warnings.push(`Row Count cannot be negative, Entered value: ${rowdata['acreCalValue']}`);
            } else {
              tempDoc['applicationRows'] = rowCount;
            }
          } catch {
            warnings.push(`Row Count input is invalid, Entered value: ${data['acreCalValue']}`);
          }
        }
      } else if (data['acreCalUnit'].toLowerCase() == 'width') {
        tempDoc['rowApplicationType'] = 0;
        if (Object.hasOwnProperty.call(data, 'acreCalValue')) {
          try {
            const taskWidth = parseFloat(data['acreCalValue']);
            if (isNaN(taskWidth)) {
              warnings.push(`Task Width is not a number, Entered value: ${data['acreCalValue']}`);
            } else if (taskWidth < 0) {
              warnings.push(`Task Width cannot be negative, Entered value: ${data['acreCalValue']}`);
            } else {
              // Assume if user is entering units based on their system, convert to metric for storage
              const inputUnits = unitsLengthSystem == 'imperial' ? 'ft' : 'm';
              const widthMeters = unitsLengthSubmitConversion(taskWidth, inputUnits);
              tempDoc['width'] = widthMeters;
            }
          } catch {
            warnings.push(`Task Width input is invalid, Entered value: ${data['acreCalValue']}`);
          }
        }
      } else {
        warnings.push(`Task Acre Cal Unit is invalid, Entered unit: ${data['acreCalUnit']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'avgSpeedTarget')) {
      try {
        const avgSpeedTarget = parseFloat(data['avgSpeedTarget']);
        if (isNaN(avgSpeedTarget)) {
          warnings.push(`Task Average Speed Target is not a number, Entered value: ${data['avgSpeedTarget']}`);
        } else if (avgSpeedTarget < 0) {
          warnings.push(`Task Average Speed Target cannot be negative, Entered value: ${data['avgSpeedTarget']}`);
        } else {
          // Assume if user is entering units based on their system, convert to metric for storage
          const inputUnits = unitsLengthSystem == 'imperial' ? 'mph' : 'kph';
          const avgSpeedTargetKph = unitsLengthSubmitConversion(avgSpeedTarget, inputUnits);
          tempDoc['avgSpeedTarget'] = parseFloat(avgSpeedTargetKph.toFixed(2));
        }
      } catch {
        warnings.push(`Task Average Speed Target input is invalid, Entered value: ${data['avgSpeedTarget']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'acPerHrTarget')) {
      try {
        const acPerHrTarget = parseFloat(data['acPerHrTarget']);
        if (isNaN(acPerHrTarget)) {
          warnings.push(`Task Acreage Per Hour Target is not a number, Entered value: ${data['acPerHrTarget']}`);
        } else if (acPerHrTarget < 0) {
          warnings.push(`Task Acreage Per Hour Target cannot be negative, Entered value: ${data['acPerHrTarget']}`);
        } else {
          tempDoc['acPerHrTarget'] = acPerHrTarget;
        }
      } catch (e) {
        console.log(e);
        warnings.push(`Task Acreage Per Hour Target input is invalid, Entered value: ${data['acPerHrTarget']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'cycleDays')) {
      try {
        const cycleDays = parseInt(data['cycleDays']);
        if (isNaN(cycleDays)) {
          warnings.push(`Task Cycle Days is not a number, Entered value: ${data['cycleDays']}`);
        } else if (cycleDays < 0) {
          warnings.push(`Task Cycle Days cannot be negative, Entered value: ${data['cycleDays']}`);
        } else {
          tempDoc['cycleDays'] = cycleDays;
        }
      } catch {
        warnings.push(`Task Cycle Days input is invalid, Entered value: ${data['cycleDays']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'defaultReiHours')) {
      try {
        const defaultReiHours = parseInt(data['defaultReiHours']);
        if (isNaN(defaultReiHours)) {
          warnings.push(`Task default REI Hours is not a number, Entered value: ${data['defaultReiHours']}`);
        } else if (defaultReiHours < 0) {
          warnings.push(`Task default REI Hours cannot be negative, Entered value: ${data['defaultReiHours']}`);
        } else {
          tempDoc['defaultReiHours'] = defaultReiHours;
        }
      } catch {
        warnings.push(`Task default REI Hours input is invalid, Entered value: ${data['defaultReiHours']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'enableTaskAcreageCapping')) {
      try {
        const trueStrings = ['y', 'yes', 'true'];
        const falseStrings = ['n', 'no', 'false'];
        const enableTaskAcreageCappingInput = data['enableTaskAcreageCapping'].toLowerCase();
        if (
          trueStrings.includes(enableTaskAcreageCappingInput) ||
          falseStrings.includes(enableTaskAcreageCappingInput)
        ) {
          tempDoc['enableTaskAcreageCapping'] = trueStrings.includes(enableTaskAcreageCappingInput);
        } else {
          warnings.push(
            `Task enableTaskAcreageCapping is not Valid, Entered value: ${data['enableTaskAcreageCapping']}`
          );
        }
      } catch {
        warnings.push(`Task enableTaskAcreageCapping is not Valid, Entered value: ${data['enableTaskAcreageCapping']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'enableSpeedVisualization')) {
      try {
        const trueStrings = ['y', 'yes', 'true'];
        const falseStrings = ['n', 'no', 'false'];
        const enableSpeedVisualizationInput = data['enableSpeedVisualization'].toLowerCase();
        if (
          trueStrings.includes(enableSpeedVisualizationInput) ||
          falseStrings.includes(enableSpeedVisualizationInput)
        ) {
          tempDoc['enableSpeedVisualization'] = trueStrings.includes(enableSpeedVisualizationInput);
        } else {
          warnings.push(
            `Task enableSpeedVisualization is not Valid, Entered value: ${data['enableSpeedVisualization']}`
          );
        }
      } catch {
        warnings.push(`Task enableSpeedVisualization is not Valid, Entered value: ${data['enableSpeedVisualization']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'speedTargetMinKph')) {
      try {
        const speedTargetMin = parseFloat(data['speedTargetMinKph']);
        if (isNaN(speedTargetMin)) {
          warnings.push(`Task Min Speed Target is not a number, Entered value: ${data['speedTargetMinKph']}`);
        } else if (speedTargetMin < 0) {
          warnings.push(`Task Min Speed Target cannot be negative, Entered value: ${data['speedTargetMinKph']}`);
        } else {
          // Assume if user is entering units based on their system, convert to metric for storage
          const inputUnits = unitsLengthSystem == 'imperial' ? 'mph' : 'kph';
          const speedTargetMinKph = unitsLengthSubmitConversion(speedTargetMin, inputUnits);
          tempDoc['speedTargetMinKph'] = parseFloat(speedTargetMinKph.toFixed(2));
        }
      } catch {
        warnings.push(`Task Min Speed Target input is invalid, Entered value: ${data['speedTargetMinKph']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'speedTargetMaxKph')) {
      try {
        const speedTargetMax = parseFloat(data['speedTargetMaxKph']);
        if (isNaN(speedTargetMax)) {
          warnings.push(`Task Max Speed Target is not a number, Entered value: ${data['speedTargetMaxKph']}`);
        } else if (speedTargetMax < 0) {
          warnings.push(`Task Max Speed Target cannot be negative, Entered value: ${data['speedTargetMaxKph']}`);
        } else {
          // Assume if user is entering units based on their system, convert to metric for storage
          const inputUnits = unitsLengthSystem == 'imperial' ? 'mph' : 'kph';
          const speedTargetMaxKph = unitsLengthSubmitConversion(speedTargetMax, inputUnits);
          tempDoc['speedTargetMaxKph'] = parseFloat(speedTargetMaxKph.toFixed(2));
        }
      } catch {
        warnings.push(`Task Max Speed Target input is invalid, Entered value: ${data['speedTargetMaxKph']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'taskName') && taskNamesList.includes(data['taskName'])) {
      errors.push(`Task with the same name already Exist, Entered value: ${data['taskName']}`);
    }

    for (let i = 0; i < requiredColumns.length; i++) {
      if (!data[requiredColumns[i]] || data[requiredColumns[i]] == '') {
        errors.push(`Column ${requiredColumns[i]} from CSV cannot be empty`);
      }
    }

    tempDoc.errors = errors;
    tempDoc.warnings = warnings;

    return tempDoc;
  }

  async function postBulkTasks(newTaskDocs) {
    const postData = {docsList: newTaskDocs, uploadType: 'task'};
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/bulkUpload';
    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  function generateModal() {
    const viewOnly = !userSettings.roleAccess['taskManagement'];
    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalTask('');
          setModalError(''); // Clear any error message
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='overflow-auto'
      >
        <Box sx={modalBoxStyle}>
          {submitting ? (
            <ModalSubmitting />
          ) : (
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <h3>
                <strong>Task Settings</strong>
              </h3>
              {viewOnly && <ViewOnlyText />}
              <Table aria-label='simple table'>
                <TableBody>
                  <SettingsText
                    label='Name'
                    setting='name'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    placeholder='Enter task name*'
                  />

                  {/* Use no border row to group this & next item together (visually) */}
                  <NoBorderTableRow>
                    <NoBorderTableCell>{"Row Application Type"}</NoBorderTableCell>
                      <NoBorderTableCell>
                        <Select
                          disabled={viewOnly}
                          size='small'
                          fullWidth
                          name={"rowApplicationType"}
                          value={modalFormValues["rowApplicationType"]}
                          onChange={handleModalChange}
                        >
                          {Object.keys(rowApplicationTypeMapping).reduce((result, key) => {
                            if (
                              !(
                                rowApplicationTypeMapping[key] == 'Limited' &&
                                rowApplicationTypeMapping[modalFormValues.rowApplicationType] != 'Limited'
                              )
                            ) {
                              result.push({value: parseInt(key), label: rowApplicationTypeMapping[key]});
                            }
                            return result;
                          }, []).map((option, idx) => {
                            return (
                              <MenuItem key={idx} value={option.value}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </NoBorderTableCell>
                  </NoBorderTableRow>
                  
                  <SettingsText
                    label={modalFormValues.rowApplicationType == 2 ? 'Row Quantity' : `Width (${distUnits})`}
                    setting='rowApplicationValue'
                    type='number'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    inputProps={modalFormValues.rowApplicationType == 2 ? {step: 1} : {min: 0, step: 0.01}}
                  />
                  {customerSettings.general?.enableTaskAcreageCapping && (
                    <SettingsSwitch
                      label={`Enable ${unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'} Capping`}
                      setting='enableAcreageCapping'
                      formValues={modalFormValues}
                      handleChange={handleModalChange}
                      disabled={viewOnly}
                    />
                  )}
                  {customerSettings.general.enableTaskRei && (
                    <TableRow>
                      <NoBorderTableCell>{'Default REI Hours'}</NoBorderTableCell>
                      <NoBorderTableCell>
                        <Grid 
                          container 
                          spacing={2} 
                          justifyContent="space-between" 
                          alignItems="center"
                        >
                          <Grid item xs={1}>
                            <Switch
                              disabled={viewOnly}
                              name={'defaultReiHoursEnabled'}
                              checked={modalFormValues['defaultReiHoursEnabled']}
                              onChange={handleModalChange}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <TextField
                              disabled={viewOnly || !modalFormValues['defaultReiHoursEnabled']}
                              size='small'
                              fullWidth
                              type={'number'}
                              InputProps={{inputProps: {min: 0, step: 1}}}
                              label={'Hours'}
                              name={'defaultReiHours'}
                              value={modalFormValues['defaultReiHours']}
                              onChange={handleModalChange}
                              onKeyDown={handleAllowOnlyNumbers}
                            />
                          </Grid>
                          {/* Here for spacing */}
                          <Grid item xs={5.5}>
                          </Grid>
                        </Grid>
                      </NoBorderTableCell>
                    </TableRow>
                  )}
                  {customerSettings.general.enableTaskCycle && (
                    <SettingsTextAndToggle
                      label='Cycle Days'
                      setting='cycleDays'
                      toggleSetting='cycleDaysEnabled'
                      type='number'
                      inputProps={{step: 1}}
                      textBoxLabel='Days'
                      formValues={modalFormValues}
                      handleChange={handleModalChange}
                      disabled={viewOnly}
                    />
                  )}

                  <TableRow>
                    <NoBorderTableCell>{"Speed Visualization"}</NoBorderTableCell>
                    <NoBorderTableCell>
                      <Grid 
                        container 
                        spacing={2} 
                        justifyContent="space-between" 
                        alignItems="center"
                      >
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5.5}>
                          <Typography variant="subtitle2" gutterBottom>
                            Target Speed Range:
                          </Typography>
                        </Grid>
                        <Grid item xs={5.5}>
                        </Grid>

                        <Grid item xs={1}>
                          <Switch
                            disabled={viewOnly}
                            name={'enableSpeedVisualization'}
                            checked={modalFormValues['enableSpeedVisualization']}
                            onChange={handleModalChange}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <TextField
                            disabled={viewOnly || !modalFormValues['enableSpeedVisualization']}
                            size='small'
                            fullWidth
                            label={`Min (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`}
                            type={'number'}
                            InputProps={{inputProps: {min: 0, max: 200, step: 0.01}}}
                            name={'speedTargetMinKph'}
                            value={modalFormValues['speedTargetMinKph']}
                            onChange={handleModalChange}
                            onKeyDown={handleAllowOnlyNumbers}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <TextField
                            disabled={viewOnly || !modalFormValues['enableSpeedVisualization']}
                            size='small'
                            fullWidth
                            label={`Max (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`}
                            type={'number'}
                            InputProps={{inputProps: {min: 0, max: 200, step: 0.01}}}
                            name={'speedTargetMaxKph'}
                            value={modalFormValues['speedTargetMaxKph']}
                            onChange={handleModalChange}
                            onKeyDown={handleAllowOnlyNumbers}
                          />
                        </Grid>
                      </Grid>
                    </NoBorderTableCell>
                  </TableRow>

                  <SettingsSwitch
                    label='Archive Task'
                    setting='archived'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                  />
                </TableBody>
              </Table>

              {/* Div for displaying error messages */}
              <div style={{color: 'red', textAlign: 'center', marginTop: 8}} className='flex-row mb-3 col-12 px-1'>
                {(modalError != '' && modalError != undefined && modalError != null) &&
                <Alert severity="error">
                  {modalError}
                </Alert>
                }
              </div>

              <div className='flex-row my-3 col-12 px-1'>
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setModalOpen(false);
                    setModalTask('');
                    setModalError(''); // Clear any error message
                  }}
                >
                  Cancel
                </Button>
                {!viewOnly && (
                  <Button variant='ic-button' color='primary' className='mx-1' onClick={handleModalSubmit}>
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  function filterRow(taskConfig) {
    // Convert Properties to string for search
    const taskStatus = taskConfig.archived ? 'Archived' : 'Active';

    let applicationValue;
    if (taskConfig.rowApplicationType == 2) {
      applicationValue = taskConfig.applicationRows;
    } else {
      applicationValue = taskConfig.width;
      if (unitsLengthSystem == 'imperial') {
        applicationValue = unitsLengthDisplayConversion(applicationValue, 'ft').toFixed(2);
      } else {
        applcationValue = unitsLengthDisplayConversion(applicationValue, 'm').toFixed(2);
      }
    }
    const rowObj = {
      ...taskConfig,
      taskConfigRapType: rowApplicationTypeMapping[taskConfig.rowApplicationType],
      widthOrRow: applicationValue,
    };
    // remove 'archived' properties from rowObj so searching 'archived' does not show both status
    delete rowObj.archived;

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const taskIdInFilter = filters.taskConfigTaskId.includes(taskConfig.taskId) || filters.taskConfigTaskId.length == 0;
    const taskConfigRapTypeInFilter =
      filters.taskConfigRapType.includes(rowApplicationTypeMapping[taskConfig.rowApplicationType]) ||
      filters.taskConfigRapType.length == 0;
    const statusInFilter = filters.taskConfigStatus.includes(taskStatus) || filters.taskConfigStatus.length == 0;
    const taskInFilter = taskIdInFilter && taskConfigRapTypeInFilter && statusInFilter;

    return taskInFilter && search;
  }

  function generateTable() {
    let colorFlip = false;
    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              {/* Name */}
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Name'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#task-config-task-name-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['taskConfigTaskId'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='task-config-task-name-select'
                    name={'taskConfigTaskId'}
                    multiple={true}
                    search={true}
                    value={filters['taskConfigTaskId']}
                    options={filterOptions['taskConfigTaskId']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              {/* Acreage Calculation */}
              <TableCell>
                <TableHeadFilterButtonTitleWrapper
                  title={`${unitsAreaSystem == 'acre' ? 'Acre' : 'Hectare'}` + ` Calculation`}
                  bulkEditMode={tasksBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'rowApplicationType'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#task-config-rap-type-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['taskConfigRapType'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='task-config-rap-type-select'
                    name={'taskConfigRapType'}
                    multiple={true}
                    search={true}
                    value={filters['taskConfigRapType']}
                    options={filterOptions['taskConfigRapType']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              {/* Row Width */}
              <TableCell sx={hideCell}>
                <TableHeadFilterButtonTitleWrapper
                  title={`Width (${distUnits}) / Row Quantity`}
                  bulkEditMode={tasksBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'rowsOrWidthValue'}
                ></TableHeadFilterButtonTitleWrapper>
              </TableCell>

              {/* Acreage Capped */}
              {customerSettings.general?.enableTaskAcreageCapping && (
                <TableCell>
                  <TableHeadFilterButtonTitleWrapper
                    // Use Acreage or Hectares title based on customer settings
                    title={`${unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'}` + ` Capped`}
                    bulkEditMode={tasksBulkEditMode}
                    propagateAllColumns={propagateAllColumns}
                    handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                    bulkEditPropertyName={'enableAcreageCapping'}
                  ></TableHeadFilterButtonTitleWrapper>
                </TableCell>
              )}

              {/* Archived */}
              <TableCell>
                <TableHeadFilterButtonTitleWrapper
                  title={'Status'}
                  bulkEditMode={tasksBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'archived'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#task-config-status-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['taskConfigRapType'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='task-config-status-select'
                    name={'taskConfigStatus'}
                    multiple={true}
                    search={true}
                    value={filters['taskConfigStatus']}
                    options={filterOptions['taskConfigStatus']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              {!tasksBulkEditMode && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((task) => {
                  const rowBulkEditErrors = tasksBulkEditErrors.hasOwnProperty(task.taskId)
                    ? tasksBulkEditErrors[task.taskId]
                    : {};
                  let applicationValue = 0;
                  let applicationUnit = '';
                  if (task.rowApplicationType == 2) {
                    applicationValue = task.applicationRows;
                    applicationUnit = 'Rows';
                  } else {
                    applicationValue = task.width;
                    if (unitsLengthSystem == 'imperial') {
                      applicationValue = unitsLengthDisplayConversion(applicationValue, 'ft').toFixed(2);
                      applicationUnit = 'ft';
                    } else {
                      applicationValue = unitsLengthDisplayConversion(applicationValue, 'm').toFixed(2);
                      applicationUnit = 'm';
                    }
                  }
                  colorFlip = !colorFlip;
                  return (
                    <TableRow key={task.taskId} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                      {/* Task Name */}
                      {!tasksBulkEditMode ? (
                        <TableCell>{task.name}</TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='text'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditTasks'
                            entityId={task.taskId}
                            entityIdName='taskId'
                            defaultValue={task.name}
                            propertyName='name'
                            submissionErrors={rowBulkEditErrors['name']}
                          />
                        </TableCell>
                      )}

                      {/* Acreage Calculation */}
                      {!tasksBulkEditMode ? (
                        <TableCell>
                          <Box>{rowApplicationTypeMapping[task.rowApplicationType]}</Box>
                          <Box className='taskconfig-hide-item'>
                            <sub>
                              {applicationValue} {applicationUnit}
                            </sub>
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditTasks'
                            entityId={task.taskId}
                            entityIdName='taskId'
                            renderOptions={renderRowApplicationTypeOptions}
                            defaultValue={task.rowApplicationType}
                            propertyName='rowApplicationType'
                            submissionErrors={rowBulkEditErrors['rowApplicationType']}
                          />
                        </TableCell>
                      )}

                      {/* Row Width */}
                      {!tasksBulkEditMode ? (
                        <TableCell sx={hideCell}>
                          {applicationValue} {applicationUnit}
                        </TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='number'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditTasks'
                            entityId={task.taskId}
                            inputValidation={validateInputChange}
                            entityIdName='taskId'
                            defaultValue={task.rowsOrWidthValue}
                            propertyName={'rowsOrWidthValue'}
                            submissionErrors={rowBulkEditErrors['rowsOrWidthValue']}
                            InputProps={{inputProps: {step: 0.01, min: 0}}}
                          />
                        </TableCell>
                      )}

                      {/* Acreage Capping */}
                      {customerSettings.general?.enableTaskAcreageCapping &&
                        (!tasksBulkEditMode ? (
                          <TableCell>{task.enableAcreageCapping ? 'Yes' : 'No'}</TableCell>
                        ) : (
                          <TableCell>
                            <BulkEditInputs
                              type='select'
                              handleBulkEditUpdate={handleBulkEditTextFields}
                              bulkEditStateName='bulkEditTasks'
                              entityId={task.taskId}
                              entityIdName='taskId'
                              renderOptions={renderYesNo}
                              defaultValue={task.enableAcreageCapping}
                              propertyName='enableAcreageCapping'
                              submissionErrors={rowBulkEditErrors['enableAcreageCapping']}
                            />
                          </TableCell>
                        ))}

                      {/* Archived */}
                      {!tasksBulkEditMode ? (
                        <TableCell>
                          {task.archived ? (
                            <Chip label='Archived' color='error' />
                          ) : (
                            <Chip label='Active' color='success' />
                          )}
                        </TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditTasks'
                            entityId={task.taskId}
                            entityIdName='taskId'
                            renderOptions={renderArchivedOptions}
                            defaultValue={task.archived}
                            propertyName='archived'
                            submissionErrors={rowBulkEditErrors['archived']}
                          />
                        </TableCell>
                      )}

                      {/* Edit Button */}
                      {!tasksBulkEditMode && (
                        <TableCell>
                          <button
                            style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                            className='btn btn-light'
                            onClick={() => {
                              setModalOpen(true);
                              setModalTask(task.taskId);
                            }}
                          >
                            <FontAwesomeIcon icon='fa fa-edit' />
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <div className='pt-2 pb-0'>
          <h5 style={{color: 'black'}}>Tasks Management</h5>
          {userSettings.roleAccess['taskManagement'] && (
            <div className='row mx-0 px-0'>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Add task'
                onClick={() => {
                  setModalOpen(true);
                  setModalTask('');
                  setModalError(''); // Clear any error message
                }}
              >
                <FontAwesomeIcon icon='fas fa-plus' />
              </Button>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Bulk Upload'
                className='ml-1'
                onClick={() => {
                  handleBulkUploadModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fas fa-upload' />
              </Button>
              <Button
                sx={{...squareButton, marginLeft: '3px'}}
                variant='ic-button'
                color={tasksBulkEditMode ? 'danger' : 'secondary'}
                onClick={() => {
                  dispatch(setTasksBulkEditMode(!tasksBulkEditMode));
                }}
                title='Bulk Edit Mode'
              >
                {tasksBulkEditMode ? (
                  <FontAwesomeIcon icon='fas fa-xmark-circle' />
                ) : (
                  <FontAwesomeIcon icon='fas fa-edit' />
                )}
              </Button>
              {tasksBulkEditMode && (
                <React.Fragment>
                  <BulkEditSubmitButton />
                  <Button
                    sx={squareButton}
                    variant='ic-button'
                    color='secondary'
                    title='Bulk Edit Reset'
                    className='ml-1'
                    onClick={() => {
                      resetBulkEdits();
                      dispatch(setTasksBulkEditErrors({}));
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-trash-can' />
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {generateModal()}
      <TabMenuTableWrapper
        menu={generateMenu()}
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
      <BulkUploadModal
        entityName={'Tasks'}
        modalOpen={bulkUploadModalOpen}
        handleModalOpen={handleBulkUploadModalOpen}
        dataValidationAndMapping={validateAndMapBulkUploadedData}
        dataPreview={bulkUploadDataPreview}
        acceptedColumnHeaders={tasksCsvColumns}
        bulkUploadSubmit={postBulkTasks}
        refreshData={refreshData}
      />
    </React.Fragment>
  );
}

function BulkEditSubmitButton(props) {
  const dispatch = useDispatch();
  const bulkEditTasks = useSelector((state) => {
    return state.settings.bulkEditTasks;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });

  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  async function submitBulkEdits() {
    const validationErrors = {};
    const editSubmissionList = [];
    Object.keys(bulkEditTasks).forEach((taskId) => {
      const newTask = deepCopy(bulkEditTasks[taskId]);
      newTask.width = parseFloat(newTask.width);
      newTask.applicationRows = parseFloat(newTask.applicationRows);
      const oldTask = deepCopy(taskConfigDict[taskId]);
      const otherNames = Object.keys(taskConfigDict)
        .filter((id) => {
          return id != taskId;
        })
        .map((id) => {
          return bulkEditTasks[id].name;
        });
      const taskUpdateObj = {};
      // Check for name change
      if (oldTask.name != newTask.name) {
        // Validate imp name
        if (otherNames.includes(newTask.name)) {
          if (!validationErrors.hasOwnProperty(taskId)) {
            validationErrors[taskId] = {};
          }
          validationErrors[taskId]['name'] = 'Name exists on another task';
        } else {
          taskUpdateObj.name = newTask.name;
        }
      }

      if (oldTask.enableAcreageCapping != newTask.enableAcreageCapping) {
        taskUpdateObj.enableAcreageCapping = newTask.enableAcreageCapping;
      }

      if (oldTask.archived != newTask.archived) {
        taskUpdateObj.archived = newTask.archived;
      }

      // TODO IMPORTANT NEED TO PARSE THE UNITS OF THE WIDTH VALUE
      const parsedRowsOrWidthValue =
        newTask.rowsOrWidthValue != undefined ? parseFloat(newTask.rowsOrWidthValue) : undefined;
      if (oldTask.rowApplicationType != newTask.rowApplicationType) {
        if (newTask.rowApplicationType == 2 && parsedRowsOrWidthValue == 0) {
          if (!validationErrors.hasOwnProperty(taskId)) {
            validationErrors[taskId] = {};
          }
          validationErrors[taskId]['rowsOrWidthValue'] = 'Rows value must be 0.5 or a whole number';
        } else {
          taskUpdateObj.rowApplicationType = parseInt(newTask.rowApplicationType);
        }
      }

      if (newTask.rowApplicationType == 0) {
        // TODO NEED TO VALIDATE NEW VALUE
        if (parsedRowsOrWidthValue != oldTask.width) {
          taskUpdateObj.width =
            unitsLengthSystem == 'imperial'
              ? unitsLengthSubmitConversion(parsedRowsOrWidthValue, 'ft')
              : parsedRowsOrWidthValue;
        }
      } else if (newTask.rowApplicationType == 2) {
        if (oldTask.applicationRows != parsedRowsOrWidthValue) {
          if (Number.isInteger(parsedRowsOrWidthValue) || parsedRowsOrWidthValue == 0.5) {
            taskUpdateObj.applicationRows = parsedRowsOrWidthValue;
          } else {
            if (!validationErrors.hasOwnProperty(taskId)) {
              validationErrors[taskId] = {};
            }
            validationErrors[taskId]['rowsOrWidthValue'] = 'Rows value must be 0.5 or a whole number';
          }
        }
      }

      if (Object.keys(taskUpdateObj).length > 0) {
        taskUpdateObj.taskId = taskId;
        editSubmissionList.push(taskUpdateObj);
      }
    });
    dispatch(setTasksBulkEditErrors(validationErrors));

    if (Object.keys(validationErrors).length == 0 && editSubmissionList.length != 0) {
      dispatch(setTasksBulkEditMode(false));
      dispatch(updateTableLoading({table: 'task', status: true}));
      const editSubmissionListParsed = editSubmissionList.map((editObj) => {
        const diffDoc = deepCopy(editObj);
        return diffDoc;
      });
      const promises = editSubmissionListParsed.map(postBulkEditTaskUpdate);
      const results = await Promise.all(promises);
      dispatch(getTaskConfigsData());
      dispatch(updateTableLoading({table: 'task', status: false}));
    } else if (Object.keys(validationErrors).length == 0 && editSubmissionList.length == 0) {
      dispatch(setTasksBulkEditMode(false));
    } else {
      console.log(validationErrors);
    }
  }

  async function postBulkEditTaskUpdate(obj) {
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(obj),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateTaskConfig',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    return result;
  }

  return (
    <Button
      sx={squareButton}
      variant='ic-button'
      color='primary'
      title='Bulk Edit Submit'
      className='ml-1'
      onClick={() => {
        submitBulkEdits();
      }}
    >
      <FontAwesomeIcon icon='fas fa-floppy-disk' />
    </Button>
  );
}

export {TasksTab};
