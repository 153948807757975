import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, unionLabelsAndDataVehicles, deepCopy, sortVehicleNamesHelper} from '../../../app/utils';
import {genVehiclesWithoutTasks, genTotalVehiclesWithoutTasksByTime} from '../dashboardDataProcessing';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {CircularProgress, Grid, Box, Select, MenuItem, FormControl, InputLabel} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {setFilterOptions, setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';
ChartJS.register(annotationPlugin);

const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiMissingTasks(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });

  const [vehiclesWithoutTaskData, setVehiclesWithoutTaskData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Hrs');
  const [selectedSpecialView, setSelectedSpecialView] = useState(null);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [missingTaskFilters, setMissingTaskFilters] = useState({
    missingTaskVehicleSNs: [],
    missingTaskVehicleCount: '25',
    missingTaskVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, missingTaskFilters, selectedSpecialView, compareByToggle, compareByOperationsData]);

  useEffect(() => {
    if (compareByToggle && timeAxes.includes(selectedSpecialView)) {
      setSelectedSpecialView(null);
      setMissingTaskFilters((values) => {
        return {...values, 'missingTaskVehicleSort': 'Desc'};
      });
    }
  }, [compareByToggle]);

  useEffect(() => {
    const filterOptionsTemp = deepCopy(filterOptions);
    if (timeAxes.includes(selectedSpecialView)) {
      filterOptionsTemp.vehicles.efficiencyVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Chronological', text: 'Chronological'},
      ];
    } else {
      filterOptionsTemp.vehicles.efficiencyVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    }
    dispatch(setFilterOptions(filterOptionsTemp));
  }, [selectedSpecialView]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    if (props?.pageFilters) {
      setMissingTaskFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.selectedSpecialView) {
      setSelectedSpecialView(props.pageStates?.selectedSpecialView);
    } else {
      setSelectedSpecialView(null);
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageFilters]);

  function generateData() {
    if (operationsData) {
      if (timeAxes.includes(selectedSpecialView)) {
        const specialViewToOpsDictKey = {
          'Days': 'opsByDay',
          'Weeks': 'opsByWeek',
          'Months': 'opsByMonth',
          'Years': 'opsByYear',
        };

        const selectedTimeOpsData = operationsData[specialViewToOpsDictKey[selectedSpecialView]];

        // Generate data
        if (selectedTimeOpsData) {
          const vehiclesWithoutTaskDataTemp = genTotalVehiclesWithoutTasksByTime(
            selectedTimeOpsData,
            missingTaskFilters
          );
          setVehiclesWithoutTaskData(vehiclesWithoutTaskDataTemp);
          setDatasets(vehiclesWithoutTaskDataTemp.datasets);
        }
      } else {
        // Union keys between compare by and non compare by
        // for vehicles if compare by is toggled
        const opsDataCopy = deepCopy(operationsData);
        const compareByOpsDataCopy = deepCopy(compareByOperationsData);
        // Pre-Filter out vehicles with 0 missing task hours before Union
        const vehicleKeys = Object.keys(opsDataCopy.vehiclesObject);
        vehicleKeys.forEach((vehicleSN) => {
          let vehicleInFilter = true;
          if (missingTaskFilters.missingTaskVehicleSNs.length > 0) {
            vehicleInFilter = missingTaskFilters.missingTaskVehicleSNs.indexOf(vehicleSN) !== -1;
          }
          const vehicleHasMissingTaskHours = opsDataCopy.vehiclesObject[vehicleSN].missingTaskHours > 0;
          if (!(vehicleInFilter && vehicleHasMissingTaskHours)) {
            delete opsDataCopy.vehiclesObject[vehicleSN];
          }
        });

        if (compareByToggle && compareByOperationsData != null) {
          // Pre-Filter out vehicles with 0 missing task hours before Union
          const compareByVehicleKeys = Object.keys(compareByOpsDataCopy.vehiclesObject);
          compareByVehicleKeys.forEach((vehicleSN) => {
            let vehicleInFilter = true;
            if (missingTaskFilters.missingTaskVehicleSNs.length > 0) {
              vehicleInFilter = missingTaskFilters.missingTaskVehicleSNs.indexOf(vehicleSN) !== -1;
            }
            const vehicleHasMissingTaskHours = compareByOpsDataCopy.vehiclesObject[vehicleSN].missingTaskHours > 0;
            if (!(vehicleInFilter && vehicleHasMissingTaskHours)) {
              delete compareByOpsDataCopy.vehiclesObject[vehicleSN];
            }
          });
          const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
            opsDataCopy.vehiclesObject,
            compareByOpsDataCopy.vehiclesObject,
            ['type', 'vehicleName']
          );
          opsDataCopy.vehiclesObject = newOpsVehiclesObject;
          compareByOpsDataCopy.vehiclesObject = newCompareByOpsVehiclesObject;
        }

        // Generate data
        const vehiclesWithoutTaskDataTemp = genVehiclesWithoutTasks(
          opsDataCopy.vehiclesObject,
          missingTaskFilters,
          opsDataCopy.vehiclesObject
        );
        let compareByVehiclesWithoutTaskDataTemp = null;
        if (compareByToggle && compareByOpsDataCopy != null) {
          compareByVehiclesWithoutTaskDataTemp = genVehiclesWithoutTasks(
            compareByOpsDataCopy.vehiclesObject,
            missingTaskFilters,
            opsDataCopy.vehiclesObject,
            true
          );
          vehiclesWithoutTaskDataTemp.datasets = [
            ...vehiclesWithoutTaskDataTemp.datasets,
            ...compareByVehiclesWithoutTaskDataTemp.datasets,
          ];
        }

        if (Object.keys(operationsData.regionsObject).length > 0) {
          setVehiclesWithoutTaskData(vehiclesWithoutTaskDataTemp);
        } else {
          setVehiclesWithoutTaskData(null);
        }

        const datasets = [];
        vehiclesWithoutTaskDataTemp.datasets.forEach((dataset) => {
          if (dataset.id == displayedAxis) {
            datasets.push(dataset);
          }
        });
        setDatasets(datasets);
      }
    }
  }

  function handleTasksFilters(value, selectName) {
    setMissingTaskFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function missingTaskGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{labels: vehiclesWithoutTaskData.labels, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: 'Vehicles Without Tasks',
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                title: {
                  text: 'Hours Missing Task',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                display: displayedAxis == 'Hrs',
                type: 'linear',
                position: 'left',
              },
              rightAxis: {
                title: {text: 'Acreage Missing Task', display: true},
                display: displayedAxis == 'Ac',
                type: 'linear',
                position: 'left',
              },
              x: {
                ticks: {
                  autoSkip: vehiclesWithoutTaskData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 0,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: selectedSpecialView || 'Vehicles',
                  display: !!props?.graphOnly,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });

      if (compareByOperationsData && 'vehiclesObject' in compareByOperationsData) {
        const vehicleSNList = Object.keys(compareByOperationsData.vehiclesObject);
        const missingTaskVehicleSNList = [];
        vehicleSNList.forEach((vehicleSN) => {
          if (
            compareByOperationsData.vehiclesObject[vehicleSN].missingTaskHours > 0 &&
            !mainSetVehicleSN.includes(vehicleSN)
          ) {
            missingTaskVehicleSNList.push({
              value: vehicleSN,
              text: compareByOperationsData.vehiclesObject[vehicleSN].vehicleName,
            });
          }
        });

        combined = [...ogVehicles, ...missingTaskVehicleSNList];

        combined.sort((vehiclea, vehicleb) => {
          const aName = vehiclea.text;
          const bName = vehicleb.text;

          return sortVehicleNamesHelper(aName, bName);
        });
      }
    }
    return combined;
  }

  function filterControlComponents() {
    return [
      <IntelliSearchSelect
        label='Vehicles'
        allNoneEnabled
        id='dashboard-tasks-missingTaskVehicleSNs-filter'
        key='dashboard-tasks-missingTaskVehicleSNs-filter'
        name='missingTaskVehicleSNs'
        multiple={true}
        search={true}
        value={missingTaskFilters.missingTaskVehicleSNs}
        options={addCompareByVehicles(filterOptions.tasks.missingTaskVehicleSNs).map((opt) => {
          return {
            'label': opt.text,
            'value': opt.value,
          };
        })}
        onChange={(s) => {
          handleTasksFilters(s, 'missingTaskVehicleSNs');
        }}
        placeholder={'Vehicle Filter'}
      />,
    ];
  }

  function displayControlComponents() {
    return [
      /* Vehicle Sort Filter */
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-missing-tasks-vehicles-sort-filter'
        key='dashboard-missing-tasks-vehicles-sort-filter'
        name='missingTasksSort'
        multiple={false}
        value={missingTaskFilters.missingTaskVehicleSort}
        options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleTasksFilters(s, 'missingTaskVehicleSort');
        }}
        placeholder='Sorting method'
      />,
      /* Vehicle Count Filter */
      <IntelliSearchSelect
        label={`${selectedSpecialView || 'Vehicle'} Count`}
        id='dashboard-missing-tasks-vehicles-count-filter'
        key='dashboard-missing-tasks-vehicles-count-filter'
        name='missingTasksCount'
        multiple={false}
        value={missingTaskFilters.missingTaskVehicleCount}
        // Use efficiencyZoneCount for options so its just the generic count options
        options={filterOptions.efficiency.efficiencyZoneCount.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleTasksFilters(s, 'missingTaskVehicleCount');
        }}
        placeholder={`Show All ${selectedSpecialView || 'Vehicle'}`}
      />,
    ];
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!vehiclesWithoutTaskData ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            missingTaskGraph()
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Missing Tasks'}
        pageFilters={missingTaskFilters}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
        pageStates={{
          selectedSpecialView: selectedSpecialView,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
      />

      {!vehiclesWithoutTaskData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              <StatsViewReportFiltersWrapper key={`missingTaskFilter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              <StatsViewReportSortByWrapper>{displayControlComponents()}</StatsViewReportSortByWrapper>
            </React.Fragment>
          )}

          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Graph */}
                <Grid item xs={10} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {missingTaskGraph()}
                </Grid>
                {/* X-Axis Control */}
                <Grid
                  item
                  xs={10}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                >
                  <FormControl sx={{minWidth: '120px', m: 1}}>
                    <InputLabel>View by</InputLabel>
                    <Select
                      size='small'
                      label='View by'
                      value={selectedSpecialView ? selectedSpecialView : 'Vehicle'}
                      onChange={(e) => {
                        if (timeAxes.includes(e.target.value)) {
                          setSelectedSpecialView(e.target.value);
                          setMissingTaskFilters((values) => {
                            return {...values, 'missingTaskVehicleSort': 'Chronological'};
                          });
                        } else {
                          setSelectedSpecialView('Vehicle'); // Reset to default
                          setMissingTaskFilters((values) => {
                            return {...values, 'missingTaskVehicleSort': 'Desc'};
                          });
                        }
                      }}
                    >
                      <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Days'}>
                        {'Days'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Weeks'}>
                        {'Weeks'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Months'}>
                        {'Months'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Years'}>
                        {'Years'}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiMissingTasks};
