import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, deepCopy} from '../../../app/utils';
import {genMissingTaskData, genTasksLoggedByZone, genTasksByTime} from '../dashboardDataProcessing';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress, FormControl, InputLabel, Grid, Box} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';

ChartJS.register(annotationPlugin);
const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiTasksLogged(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });

  const [selectedZoneLevel, setSelectedZoneLevel] = useState('Region');
  const [selectedSpecialView, setSelectedSpecialView] = useState(null);
  const [missingTaskData, setMissingTaskData] = useState(null);
  const [taskLoggedData, setTaskLoggedData] = useState(null);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [taskFilters, setTaskFilters] = useState({
    fields: [],
    fieldsCount: '10',
    fieldsSort: 'Desc',
    regions: [],
    regionsCount: '10',
    regionsSort: 'Desc',
    specialSort: 'Chronological',
    specialCount: '10',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, taskFilters, selectedZoneLevel, selectedSpecialView, compareByToggle, compareByOperationsData]);

  useEffect(() => {
    if (compareByToggle && timeAxes.includes(selectedSpecialView)) {
      setSelectedZoneLevel('Region');
      setSelectedSpecialView(null);
    }
  }, [compareByToggle]);

  useEffect(() => {
    if (props.pageStates?.selectedZoneLevel) {
      setSelectedZoneLevel(props.pageStates?.selectedZoneLevel);
    } else {
      setSelectedZoneLevel('Region');
    }
    if (props.pageStates?.selectedSpecialView) {
      setSelectedSpecialView(props.pageStates?.selectedSpecialView);
    } else {
      setSelectedSpecialView(null);
    }
    if (props?.pageFilters) {
      setTaskFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  function unionLabelsAndDataZones(opsData, compareByOpsData, excludedKeys = []) {
    const opsDataCopy = deepCopy(opsData);
    const compareByOpsDataCopy = deepCopy(compareByOpsData);

    // Do for Vehicles
    const opsDataKeys = Object.keys(opsDataCopy);
    const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);

    opsDataKeys.forEach((opsDataKey) => {
      if (!compareByOpsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(opsDataCopy[opsDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          }
        });
        compareByOpsDataCopy[opsDataKey] = objZeros;
      }
    });
    compareByOpsDataKeys.forEach((opsDataKey) => {
      if (!opsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          }
        });
        opsDataCopy[opsDataKey] = objZeros;
      }
    });

    return [opsDataCopy, compareByOpsDataCopy];
  }

  function generateData() {
    const opsDataCopy = deepCopy(operationsData);
    const compareByOpsDataCopy = deepCopy(compareByOperationsData);

    if (compareByToggle && compareByOperationsData != null) {
      const zoneObjKeys = ['regionsObject', 'fieldsObject', 'blocksObject'];
      zoneObjKeys.forEach((key) => {
        const [newOpsZoneObject, newCompareByOpsZoneObject] = unionLabelsAndDataZones(
          operationsData[key],
          compareByOperationsData[key]
        );
        opsDataCopy[key] = newOpsZoneObject;
        compareByOpsDataCopy[key] = newCompareByOpsZoneObject;
      });
    }

    // Generate data
    const missingTaskZonesData = genMissingTaskData(
      opsDataCopy.regionsObject,
      opsDataCopy.fieldsObject,
      opsDataCopy.blocksObject,
      taskFilters
    );
    const missingTaskDataTemp = missingTaskZonesData[selectedZoneLevel];

    let compareByMissingTaskZonesData;
    let compareMissingTaskDataTemp;
    if (compareByToggle && compareByOpsDataCopy != null) {
      compareByMissingTaskZonesData = genMissingTaskData(
        compareByOpsDataCopy.regionsObject,
        compareByOpsDataCopy.fieldsObject,
        compareByOpsDataCopy.blocksObject,
        taskFilters,
        true
      );
      compareMissingTaskDataTemp = compareByMissingTaskZonesData[selectedZoneLevel];
      missingTaskDataTemp.datasets = [...missingTaskDataTemp.datasets, ...compareMissingTaskDataTemp.datasets];
    }

    if (Object.keys(opsDataCopy.regionsObject).length > 0) {
      setMissingTaskData(missingTaskDataTemp);
    } else {
      setMissingTaskData(null);
    }

    const taskZonesData = genTasksLoggedByZone(opsDataCopy.regionsObject, opsDataCopy.fieldsObject, taskFilters);
    let taskLoggedDataTemp = taskZonesData[selectedZoneLevel];

    let compareByTaskZonesData;
    let compareByTaskLoggedDataTemp;
    if (compareByToggle && compareByOpsDataCopy != null) {
      compareByTaskZonesData = genTasksLoggedByZone(
        compareByOpsDataCopy.regionsObject,
        compareByOpsDataCopy.fieldsObject,
        taskFilters,
        taskZonesData,
        true
      );
      compareByTaskLoggedDataTemp = compareByTaskZonesData[selectedZoneLevel];
      taskLoggedDataTemp.datasets = [...taskLoggedDataTemp.datasets, ...compareByTaskLoggedDataTemp.datasets];
    }
    if (selectedSpecialView != null) {
      taskLoggedDataTemp = genTasksByTime(opsDataCopy, selectedSpecialView, taskFilters, selectedZoneLevel);
    }

    setTaskLoggedData(taskLoggedDataTemp);
  }

  function handleTasksFilters(value, selectName) {
    setTaskFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function taskLoggedVsMissingBarGraph() {
    return (
      <div style={{height: '40vh', maxHeight: '400px'}}>
        {/* Chart */}
        <BarChart
          data={missingTaskData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: 'Tasks Logged vs. Missing Task',
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: '% Logged',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
                stacked: true,
                ticks: {
                  count: 5,
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  beginAtZero: true,
                  autoSkip: false,
                  stepSize: 20,
                  callback: function (val, index) {
                    return `${val} %`;
                  },
                },
              },
              x: {
                display: true,
              },
            },
          }}
        />
      </div>
    );
  }

  function taskLoggedBarGraph() {
    return (
      <div style={{height: '40vh', minHeight: '400px'}}>
        <BarChart
          data={taskLoggedData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: `Task Logged % vs. ${selectedZoneLevel}`,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: '% Task Logged',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                ticks: {
                  callback: function (val, index) {
                    return this.getLabelForValue(val) + ' %';
                  },
                },
              },
              x: {
                title: {
                  display: false,
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function filterControlComponents() {
    return [
      /* Zone Filter */
      <IntelliSearchSelect
        allNoneEnabled={true}
        label={selectedZoneLevel + 's'}
        id='dashboard-tasks-zone-filter'
        key='dashboard-tasks-zone-filter'
        name={selectedZoneLevel == 'Field' ? 'fields' : 'regions'}
        multiple={true}
        search={true}
        value={taskFilters[selectedZoneLevel == 'Field' ? 'fields' : 'regions']}
        options={addCompareByTaskBaseZone(filterOptions.tasks[selectedZoneLevel == 'Field' ? 'fields' : 'regions']).map(
          (opt) => {
            return {'label': opt, 'value': opt};
          }
        )}
        onChange={(s) => {
          handleTasksFilters(s, selectedZoneLevel == 'Field' ? 'fields' : 'regions');
        }}
        placeholder={`${selectedZoneLevel} Filter`}
      />,
    ];
  }

  function displayControlComponents() {
    return [
      /* Zone Sort Filter */
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-vehicles-tasksZonesSort-filter'
        key='dashboard-vehicles-tasksZonesSort-filter'
        name={selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort'}
        multiple={false}
        value={
          taskFilters[
            selectedSpecialView == null ? (selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort') : 'specialSort'
          ]
        }
        options={filterOptions.tasks[
          selectedSpecialView == null ? (selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort') : 'specialSort'
        ].map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleTasksFilters(
            s,
            selectedSpecialView == null ? (selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort') : 'specialSort'
          );
        }}
        placeholder='Sorting method'
      />,
      /* Zone Count Filter */
      <IntelliSearchSelect
        label={`${selectedSpecialView || selectedZoneLevel} Count`}
        id='dashboard-vehicles-tasksZonesCount-filter'
        key='dashboard-vehicles-tasksZonesCount-filter'
        name={
          selectedSpecialView == null ? (selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount') : 'specialCount'
        }
        multiple={false}
        value={
          taskFilters[
            selectedSpecialView == null
              ? selectedZoneLevel == 'Field'
                ? 'fieldsCount'
                : 'regionsCount'
              : 'specialCount'
          ]
        }
        options={filterOptions.tasks[
          selectedSpecialView == null ? (selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount') : 'specialCount'
        ].map((opt) => {
          return {
            'label': opt.text + ` ${selectedSpecialView != null ? selectedSpecialView : ''}`,
            'value': opt.value,
          };
        })}
        onChange={(s) => {
          handleTasksFilters(
            s,
            selectedSpecialView == null
              ? selectedZoneLevel == 'Field'
                ? 'fieldsCount'
                : 'regionsCount'
              : 'specialCount'
          );
        }}
        placeholder={`Show All ${selectedSpecialView || selectedZoneLevel}`}
      />,
    ];
  }

  function addCompareByTaskBaseZone(mainZoneNames) {
    let zoneNames = [...mainZoneNames];
    if (compareByToggle && compareByOperationsData != null) {
      let compareByZoneNames = [];
      if (selectedZoneLevel == 'Field') {
        if (compareByOperationsData && 'fieldsObject' in compareByOperationsData) {
          compareByZoneNames = Object.keys(compareByOperationsData.fieldsObject);
        }
      } else {
        if (compareByOperationsData && 'regionsObject' in compareByOperationsData) {
          compareByZoneNames = Object.keys(compareByOperationsData.regionsObject);
        }
      }
      zoneNames = [...new Set(zoneNames.concat(compareByZoneNames))];
    }
    zoneNames.sort((a, b) => {
      return a.localeCompare(b);
    });

    return zoneNames;
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        {!missingTaskData || !taskLoggedData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {taskLoggedVsMissingBarGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto'>{taskLoggedBarGraph()}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Farming Tasks Logged'}
        pageFilters={taskFilters}
        pageStates={{
          selectedZoneLevel: selectedZoneLevel,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
          selectedSpecialView: selectedSpecialView,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />
      {!missingTaskData || !taskLoggedData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              {/* Filters */}
              <StatsViewReportFiltersWrapper key={`${selectedZoneLevel}Filter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              {/* Sort By */}
              <Grid container>
                <Grid item xs={12}>
                  <StatsViewReportSortByWrapper key={`${selectedZoneLevel}Sort`}>
                    {displayControlComponents()}
                  </StatsViewReportSortByWrapper>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Graph */}
                <Grid item xs={12} sm={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {taskLoggedVsMissingBarGraph()}
                </Grid>
                <Grid item xs={12} sm={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {taskLoggedBarGraph()}
                </Grid>
                {/* X-Axis Control */}
                <Grid item xs={6}>
                  {/* Empty Grid to Pad X-Axis Control */}
                </Grid>
                <Grid item xs={6} sx={{alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                  <FormControl sx={{minWidth: '120px', m: 1}}>
                    <InputLabel>View By</InputLabel>
                    <Select
                      size='small'
                      value={selectedSpecialView == null ? selectedZoneLevel : selectedSpecialView}
                      label='View by'
                      onChange={(e) => {
                        if (timeAxes.includes(e.target.value)) {
                          setSelectedSpecialView(e.target.value);
                          setTaskFilters;
                        } else {
                          setSelectedSpecialView(null);
                          setSelectedZoneLevel(e.target.value);
                        }
                      }}
                    >
                      <MenuItem value={'Field'}>{'Field'}</MenuItem>
                      <MenuItem value={'Region'}>{'Region'}</MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Days'}>
                        {'Days'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Weeks'}>
                        {'Weeks'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Months'}>
                        {'Months'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Years'}>
                        {'Years'}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiTasksLogged};
