import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import * as turf from '@turf/turf';

import {polygonArea, unitsAreaConversion, unitsLengthDisplayConversion} from '../../app/utils';
import {updateZoneZoom} from './zoneInfoSlice';

// Label for adding onto map
const LabelComponent = ({text}) => {
  return (
    <label
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'center',
        minWidth: '25px',
      }}
    >
      {text}
    </label>
  );
};

// Control button div defined here so that it can be populated using jsx and then appended to the map once initialized
const controlButtonDiv = document.createElement('div');
// Global variables
let map = null; // Reference to google 'map' object
let maps = null; // Reference to google 'maps' object

const zoneColorsList = ['#af4a4d', '#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];
let zoneColorIndex = 0;
let zoneInfoWindow = null; // Single zone info window so that only one is open at a time

function Map(props) {
  const dispatch = useDispatch();

  const zonesDicts = useSelector((state) => {
    return state.zoneinfo.zonesDicts;
  });
  const geofencesDict = useSelector((state) => {
    return state.zoneinfo.geofencesDict;
  });
  const statusFilter = useSelector((state) => {
    return state.zoneinfo.statusFilter;
  });
  const zoneZoom = useSelector((state) => {
    return state.zoneinfo.zoneZoom;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [zoneExtremes, setZoneExtremes] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayZones, setDisplayZones] = useState(true);
  const [displayRowBearing, setDisplayRowBearing] = useState(false);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [geofencePolygons, setGeofencePolygons] = useState([]);
  const [rowBearings, setRowBearings] = useState([]);
  const [rowBearingsText, setRowBearingsText] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([]);

  useEffect(() => {
    if (map != null && maps != null) {
      updateZoneExtremes();
    }
  }, [zonesDicts]);

  useEffect(() => {
    if (map != null && maps != null) {
      plotGeofences();
    }
  }, [geofencesDict, zonesDicts]);

  useEffect(() => {
    if (map != null && maps != null) {
      showGeofences();
    }
  }, [displayZones]);

  useEffect(() => {
    // This updates the map to show row bearing
    // If the map zoom changes
    if (map != null && maps != null) {
      showRowBearing();
      map.addListener('bounds_changed', () => {
        showRowBearing();
      });
    }
  }, [displayZones, displayRowBearing, mapZoomLevel]);

  useEffect(() => {
    if (map != null && maps != null) {
      zoomToZone();
    }
  }, [zoneZoom]);

  useEffect(() => {
    if (map != null && maps != null) {
      setZoneLabels(generateZoneLabels());
    }
  }, [zoneLabelsIndex, geofencesDict, zonesDicts]);

  // Options for google map appearance and functionality
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    rotateControl: false,
    tilt: 0,
  };

  function handleApiLoaded(gmap, gmaps) {
    // Set global 'map' and 'maps' objects to those received from the google api
    map = gmap;
    maps = gmaps;

    // Append custom control buttons to map
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);

    // Create infowindow object
    zoneInfoWindow = new maps.InfoWindow();
    map.addListener('click', () => {
      zoneInfoWindow.close();
    });

    map.addListener('zoom_changed', () => {
      setMapZoomLevel(map.getZoom());
    });

    // Update map with markers once initialized
    setMapZoomLevel(map.getZoom());
    plotGeofences();
  }

  function updateZoneExtremes() {
    // Track extremes of zones
    const extremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    Object.values(zonesDicts.regions).forEach((region) => {
      if (extremes.latMax == null || region.latMax > extremes.latMax) {
        extremes.latMax = region.latMax;
      }
      if (extremes.latMin == null || region.latMin < extremes.latMin) {
        extremes.latMin = region.latMin;
      }
      if (extremes.lngMax == null || region.lngMax > extremes.lngMax) {
        extremes.lngMax = region.lngMax;
      }
      if (extremes.lngMin == null || region.lngMin < extremes.lngMin) {
        extremes.lngMin = region.lngMin;
      }
    });
    setZoneExtremes(extremes);
  }

  function plotGeofences() {
    // Clear previous geofences
    for (let i = 0; i < geofencePolygons.length; i++) {
      geofencePolygons[i].setMap(null);
    }

    // SHOULD REALLY COMBINE ALL THE MAP COMPONENTS INTO ONE AND CLEAN UP
    // Check if blocks within zoneData
    if (!Object.prototype.hasOwnProperty.call(zonesDicts, 'blocks') || Object.keys(geofencesDict).length < 1) {
      return;
    }

    const polygonsList = [];
    const rowBearingsList = [];
    const rowBearingsTextList = [];
    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      // // Check if should display based on active filter
      // if ((statusFilter == 'active' && !block.active) || (statusFilter == 'archived' && block.active)) {
      //   return;
      // }

      const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name : '';

      const blockIntelliBlockNums = block.intelliblockNums;

      const lineSymbol1 = {
        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const lineSymbol2 = {
        path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
      };

      // Iterate through each multipolygon for plotting
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geofence = geofencesDict[blockIntelliBlockNums[j]];
        const latLngList = [];
        // Iterate through each coordinate set in the polygon.
        // Sets after the first one correspond to holes inside the polygon
        for (let k = 0; k < geofence.geometry.coordinates.length; k++) {
          const coordinates = geofence.geometry.coordinates[k];
          const latLngMap = coordinates.map((coordPair) => {
            return {lat: coordPair[1], lng: coordPair[0]};
          });
          latLngList.push(latLngMap);
        }
        const polygon = new maps.Polygon({
          paths: latLngList,
          strokeColor: zoneColorsList[zoneColorIndex],
          strokeWeight: 0.5,
          fillColor: zoneColorsList[zoneColorIndex],
          fillOpacity: 0.3,
          zIndex: -1,
        });
        polygon.setMap(map);
        polygonsList.push(polygon);

        if (polygon != undefined) {
          // Add info window
          let rowSpacingWithUnit = 'N/A';
          if (geofence.rowSpacingMeters > 0) {
            rowSpacingWithUnit =
              unitsLengthSystem == 'imperial'
                ? `${unitsLengthDisplayConversion(geofence.rowSpacingMeters, 'ft').toFixed(2)} ft`
                : `${geofence.rowSpacingMeters.toFixed(2)} m`;
          }

          // Convert acreage area to desired units
          let unitsArea = 'ac';
          if (unitsAreaSystem == 'hectare') {
            unitsArea = 'ha';
          }

          const convertedAcreage = unitsAreaConversion(block.areaMeters2, unitsArea, 'meters2');
          const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';

          const infoWindowContent =
            `<div><b>${block.name}</b></div>` +
            `<div style="font-size:10px"><b>Field:</b> ${fieldName}</div>` +
            `<div style="font-size:10px"><b>Region:</b> ${regionName}</div>` +
            `<div style="font-size:10px"><b>Row Spacing:</b> ${rowSpacingWithUnit}</div>` +
            `<div style="font-size:10px"><b>${areaLabel}:</b> ${acreageWithUnit}</div>`;

          polygon.addListener('click', (event) => {
            zoneInfoWindow.setContent(infoWindowContent);
            zoneInfoWindow.setPosition(event.latLng);
            zoneInfoWindow.open({
              map,
              shouldFocus: false,
            });
          });

          // Add row bearing
          if (geofence.rowBearing != -1 && geofence.geometry.coordinates.length > 0) {
            // Get bearing in degree
            const bearing = geofence.rowBearing;
            const bearingDir1 = bearing > 180 ? bearing - 180 : bearing; // Range 0 to 180
            const bearingDir2 = bearing - 180; // Range -180 to 0

            // Create polygon object and find center
            const outerCoords = geofence.geometry.coordinates[0];
            const polygonObj = turf.polygon([outerCoords]);
            const polygonCenter = turf.center(polygonObj);

            // Create long line extending from center
            const point1 = turf.destination(polygonCenter, 5, bearingDir1, {units: 'kilometers'});
            const point2 = turf.destination(polygonCenter, 5, bearingDir2, {units: 'kilometers'});
            const lineString = turf.lineString([point1.geometry.coordinates, point2.geometry.coordinates]);

            // Get the midpoint of the LineString
            const midpoint = turf.midpoint(point1.geometry.coordinates, point2.geometry.coordinates);

            // Get intersections between line and polygon
            const intersections = turf.lineIntersect(lineString, polygonObj);

            // Create line points to plot
            const linePoints = [];
            intersections.features.forEach((feature) => {
              linePoints.push({
                lng: feature.geometry.coordinates[0],
                lat: feature.geometry.coordinates[1],
              });
            });

            // Create row bearing line
            const line = new maps.Polyline({
              path: linePoints,
              geodesic: true,
              strokeColor: '#66ff66',
              strokeOpacity: 0.75,
              strokeWeight: 2,
              icons: [
                {
                  icon: lineSymbol1,
                  offset: '90%',
                },
                {
                  icon: lineSymbol2,
                  offset: '10%',
                },
              ],
            });

            // Create a marker with a custom label
            // Displays the bearing value
            const marker = new maps.Marker({
              position: {lat: midpoint.geometry.coordinates[1], lng: midpoint.geometry.coordinates[0]},
              label: {
                text: `${Math.round(bearing % 90)}°`,
                color: 'black', // Set label text color
                fontWeight: 'bold', // Set label text weight
                fontSize: '12px',
              },
              icon: {
                path: maps.SymbolPath.CIRCLE,
                fillColor: '#ffffff', // Set the fill color
                fillOpacity: 1, // Set the fill opacity
                strokeColor: '#66ff66', // Set the outline color
                strokeWeight: 2, // Set the outline thickness
                scale: 12,
              },
            });

            marker.setMap(null);
            line.setMap(null);
            rowBearingsList.push(line);
            rowBearingsTextList.push(marker);
          }
        }
      }
    });

    setGeofencePolygons(polygonsList);
    setRowBearings(rowBearingsList);
    setRowBearingsText(rowBearingsTextList);
  }

  function generateZoneLabels() {
    let allZoneLabels = [];
    if (maps != null) {
      // Check if blocks within zoneData
      if (!Object.prototype.hasOwnProperty.call(zonesDicts, 'blocks') || Object.keys(geofencesDict).length < 1) {
        return;
      }

      allZoneLabels = Object.values(zonesDicts.blocks).map((block, index) => {
        // // Check if should display based on active filter
        // if ((statusFilter == 'active' && !block.active) || (statusFilter == 'archived' && block.active)) {
        //   return;
        // }

        const blockIntelliBlockNums = block.intelliblockNums;

        const latCenter = (block.latMax + block.latMin) / 2;
        const lngCenter = (block.lngMax + block.lngMin) / 2;

        // Get one geofence of block
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[0])) {
          return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={''} />;
        }
        const geofence = geofencesDict[blockIntelliBlockNums[0]];

        let zoneName = block.name;
        if (zoneLabelsIndex == 1) {
          zoneName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
        } else if (zoneLabelsIndex == 2) {
          zoneName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name : '';
        } else if (zoneLabelsIndex == 3) {
          let rowSpacingWithUnit = 'N/A';
          if (geofence.rowSpacingMeters > 0) {
            rowSpacingWithUnit =
              unitsLengthSystem == 'imperial'
                ? `${unitsLengthDisplayConversion(geofence.rowSpacingMeters, 'ft').toFixed(2)} ft`
                : `${geofence.rowSpacingMeters.toFixed(2)} m`;
          }
          zoneName = `Row spacing: ${rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          // Convert acreage area to desired units
          let unitsArea = 'ac';
          if (unitsAreaSystem == 'hectare') {
            unitsArea = 'ha';
          }

          const convertedAcreage = unitsAreaConversion(block.areaMeters2, unitsArea, 'meters2');
          const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          zoneName = `${areaLabel}: ${acreageWithUnit}`;
        }
        return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={zoneName} />;
      });
    }
    return allZoneLabels;
  }

  function showAll() {
    if (zoneExtremes.latMin == null) {
      return;
    }
    const sw = {lat: zoneExtremes.latMin, lng: zoneExtremes.lngMin};
    const ne = {lat: zoneExtremes.latMax, lng: zoneExtremes.lngMax};

    const bounds = new maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function showGeofences() {
    if (displayZones) {
      for (let i = 0; i < geofencePolygons.length; i++) {
        geofencePolygons[i].setMap(map);
      }
    } else {
      for (let i = 0; i < geofencePolygons.length; i++) {
        geofencePolygons[i].setMap(null);
      }
    }
  }

  /**
   * Displays row bearing lines on the map.
   */
  function showRowBearing() {
    if (displayZones && displayRowBearing && mapZoomLevel > 13) {
      for (let i = 0; i < rowBearings.length; i++) {
        rowBearings[i].setMap(map);
        // Only display in the current view
        // if (map.getBounds().contains(rowBearingsText[i].getPosition())) {
        //   rowBearings[i].setMap(map);
        //   rowBearingsText[i].setMap(map);
        // }
      }
    } else {
      for (let i = 0; i < rowBearings.length; i++) {
        rowBearings[i].setMap(null);
        // rowBearingsText[i].setMap(null);
      }
    }
  }

  function zoomToZone() {
    Object.keys(zonesDicts).forEach((zoneLevel) => {
      Object.values(zonesDicts[zoneLevel]).forEach((zoneObj) => {
        if (zoneObj.name == zoneZoom) {
          const latMax = parseFloat(zoneObj.latMax);
          const latMin = parseFloat(zoneObj.latMin);
          const lngMax = parseFloat(zoneObj.lngMax);
          const lngMin = parseFloat(zoneObj.lngMin);

          const sw = {lat: latMin, lng: lngMin};
          const ne = {lat: latMax, lng: lngMax};

          const bounds = new maps.LatLngBounds();
          bounds.extend(sw);
          bounds.extend(ne);

          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 0.0);

          dispatch(updateZoneZoom(''));
        }
      });
    });
  }

  function changeZonesColor() {
    // Update color index
    zoneColorIndex = (zoneColorIndex + 1) % zoneColorsList.length;
    const currPolygons = geofencePolygons;
    for (let i = 0; i < currPolygons.length; i++) {
      currPolygons[i].setOptions({
        strokeColor: zoneColorsList[zoneColorIndex],
        fillColor: zoneColorsList[zoneColorIndex],
      });
    }
    setGeofencePolygons(currPolygons);
  }

  function changeZoneLabels() {
    setZoneLabelsIndex((zoneLabelsIndex + 1) % 6);
  }

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyANXSBQLKbCPDicQsIvWsMvUWaTinztW6Q'}}
        defaultCenter={[0, 0]}
        defaultZoom={5}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          return handleApiLoaded(map, maps);
        }}
      >
        {displayZones && zoneLabelsIndex < 5 && mapZoomLevel > 13 && zoneLabels}
      </GoogleMapReact>
      {createPortal(
        <React.Fragment>
          <div>
            <button className='btn-lg bg-light mt-2 mr-2' onClick={showAll}>
              <FontAwesomeIcon icon='fas fa-eye' fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2'
              onClick={() => {
                return setDisplayZones(!displayZones);
              }}
            >
              <FontAwesomeIcon icon={displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
            </button>
          </div>
          {displayZones && (
            <React.Fragment>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  onClick={() => {
                    return setDisplayRowBearing(!displayRowBearing);
                  }}
                >
                  <FontAwesomeIcon icon={displayRowBearing ? 'fas fa-compass' : 'far fa-compass'} fixedWidth />
                </button>
              </div>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZoneLabels();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
                </button>
              </div>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZonesColor();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
                </button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>,
        controlButtonDiv
      )}
    </React.Fragment>
  );
}

export {Map};
