import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table} from '../../components/Table';
import {setInspectionReport, setStoreInspectionItems} from './inspectionFormSlice';
import {TextField} from '@mui/material';

function InspectionFormTable(props) {
  const dispatch = useDispatch();

  // Initialize the needed parameters
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const selectedVeh = useSelector((state) => {
    return state.inspectionForm.inspectionSelectedVehicle;
  });

  const inspectionItems = useSelector((state) => {
    return state.inspectionForm.inspectionItems;
  });

  const storeInspectionItems = useSelector((state) => {
    return state.inspectionForm.storeInspectionItems;
  });

  const inspectionReport = useSelector((state) => {
    return state.inspectionForm.inspectionReport;
  });

  const [filteredInspectionItems, setFilteredInspectionItemList] = useState([]);
  const [commentVisible, setCommentVisible] = useState(false);
  const [reportComment, setReportComment] = useState('');

  useEffect(() => {
    // Trigger a filter of inspection items based on the selected vehicle
    filterInspectionItems();
  }, [selectedVeh]);

  useEffect(() => {
    // Trigger the generation of the inspection report
    constructInspectionReport();
  }, [filteredInspectionItems, reportComment]);

  // filters the above inspection items. Should be set in the inspectionFormSlice if possible
  function filterInspectionItems() {
    const tempFilteredInspectionItems = [];
    for (let i = 0; i < inspectionItems.length; i++) {
      // Filters inspectionItems based on machine/vehicle Type
      let machineType;
      if (typeof selectedVeh.machineType == 'undefined') {
        machineType = 0;
      } else {
        machineType = selectedVeh.machineType;
      }

      if (
        (inspectionItems[i].vehicleType.includes(machineType) ||
          inspectionItems[i].vehicleType.includes(machineType.toString())) &&
        inspectionItems[i].archived == false
      ) {
        // Check if inspection item already checked off in store
        const itemInStore = storeInspectionItems.find((item) => {
          return item.id == inspectionItems[i].id;
        });

        if (itemInStore) {
          tempFilteredInspectionItems.push(itemInStore);
        } else {
          tempFilteredInspectionItems.push({
            id: inspectionItems[i].id,
            label: inspectionItems[i].name,
            isPassChecked: false,
            isFailChecked: false,
            comments: '',
          });
        }
      }
    }

    const tempFilteredInspectionItemsSorted = tempFilteredInspectionItems.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    const updatedCommentVisibleState = tempFilteredInspectionItemsSorted.map((item, index) => {
      return item.isFailChecked;
    });

    setFilteredInspectionItemList(tempFilteredInspectionItemsSorted);
    setCommentVisible(updatedCommentVisibleState);

    if (typeof inspectionReport.comment !== 'undefined') {
      setReportComment(inspectionReport.comment);
    }
  }

  // Class names for columns of table
  const columnClass = [
    'inspection-item-col',
    'inspection-pass-col',
    'inspection-fail-col',
    'inspection-fail-comment-box',
  ];

  function constructInspectionReport() {
    // Create the inspection report object
    const currentInspectionReport = {
      items: [],
      comment: reportComment,
    };
    for (let i = 0; i < filteredInspectionItems.length; i++) {
      // Determine the report status
      let passed = 'unknown';
      if (filteredInspectionItems[i].isFailChecked) {
        passed = false;
      } else if (filteredInspectionItems[i].isPassChecked) {
        passed = true;
      }

      currentInspectionReport['items'].push({
        inspectionItemId: filteredInspectionItems[i].id,
        passed: passed,
        comments: filteredInspectionItems[i].comments,
      });
    }
    dispatch(setStoreInspectionItems(filteredInspectionItems));
    dispatch(setInspectionReport(currentInspectionReport));
  }

  // Initializing pass and fail states for all check list items
  function handlePassChange(position, value) {
    // Update the inspectionItemList with the new state
    const updatedCheckedState = filteredInspectionItems.map((item, index) => {
      const itemCopy = {...item};
      if (index === position) {
        itemCopy.isPassChecked = value;
        if (itemCopy.isFailChecked) {
          itemCopy.isFailChecked = false;
        }
      }
      return itemCopy;
    });

    const updatedCommentVisibleState = updatedCheckedState.map((item, index) => {
      return item.isFailChecked;
    });

    setFilteredInspectionItemList(updatedCheckedState);
    setCommentVisible(updatedCommentVisibleState);
  }

  function handleFailChange(position, value) {
    // Update the inspectionItemList with the new state
    const updatedCheckedState = filteredInspectionItems.map((item, index) => {
      const itemCopy = {...item};
      if (index === position) {
        itemCopy.isFailChecked = value;
        if (itemCopy.isPassChecked) {
          itemCopy.isPassChecked = false;
        }
      }
      return itemCopy;
    });

    const updatedCommentVisibleState = updatedCheckedState.map((item, index) => {
      return item.isFailChecked;
    });

    setFilteredInspectionItemList(updatedCheckedState);
    setCommentVisible(updatedCommentVisibleState);
  }

  function commentBoxChange(boxId, comment) {
    const positionIndex = parseInt(boxId);

    const updatedComments = filteredInspectionItems.map((item, index) => {
      const itemCopy = {...item};
      if (index === positionIndex) {
        itemCopy.comments = comment;
      }
      return itemCopy;
    });

    setFilteredInspectionItemList(updatedComments);
  }

  function reportCommentBoxChange(comment) {
    setReportComment(comment);
  }

  function generateTableData() {
    return (
      <tbody id={'inspection-form-table-body'}>
        {filteredInspectionItems.map((filteredInspectionItem, index) => {
          const contentRow = (
            <tr key={index} className={''}>
              <td className={columnClass[0]} width='60%'>
                <div className='mb-2'>
                  <div className='font-weight-bold'>
                    {filteredInspectionItem.label}
                    {customerSettings.inspection.inspectionReportAllItemsComplete && (
                      <span style={{color: 'red'}}> *</span>
                    )}
                  </div>
                  {commentVisible[index] ? (
                    <TextField
                      size='small'
                      placeholder='Comment *'
                      fullWidth
                      multiline
                      rows={2}
                      sx={{backgroundColor: 'white'}}
                      value={filteredInspectionItem.comments}
                      onChange={(event) => {
                        return commentBoxChange(index, event.target.value);
                      }}
                    />
                  ) : null}
                </div>
              </td>
              <td className={columnClass[1]} width='20%'>
                <div>
                  <Checkbox
                    id={filteredInspectionItem.id + '-pass'}
                    name={filteredInspectionItem.name}
                    checked={filteredInspectionItems[index].isPassChecked}
                    onChange={(event) => {
                      return handlePassChange(index, event.target.checked);
                    }}
                  />
                </div>
              </td>
              <td className={columnClass[2]} width='20%'>
                <div>
                  <Checkbox
                    id={filteredInspectionItem.id + '-fail'}
                    name={filteredInspectionItem.name}
                    checked={filteredInspectionItems[index].isFailChecked}
                    onChange={(event) => {
                      return handleFailChange(index, event.target.checked);
                    }}
                  />
                </div>
              </td>
            </tr>
          );

          return contentRow;
        })}
      </tbody>
    );
  }

  function generateTableHeaders() {
    return (
      <tr>
        <th className={columnClass[0]} width='60%'>
          Inspection Item
        </th>
        <th className={columnClass[1]} width='20%'>
          Pass (Aprobar)
        </th>
        <th className={columnClass[2]} width='20%'>
          Fail (Fallar)
        </th>
      </tr>
    );
  }
  const Checkbox = ({id, name, value, checked, onChange}) => {
    return (
      <div className={id}>
        <input name={name} value={value} type='checkbox' checked={checked} onChange={onChange} />
      </div>
    );
  };

  return (
    <div>
      <Table headers={generateTableHeaders()} body={generateTableData()} loading={props.loading} />
      <TextField
        size='small'
        placeholder='Comment'
        fullWidth
        multiline
        rows={2}
        sx={{backgroundColor: 'white'}}
        value={reportComment}
        onChange={(event) => {
          return reportCommentBoxChange(event.target.value);
        }}
      />
    </div>
  );
}

export {InspectionFormTable};
