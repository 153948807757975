import {createSlice} from '@reduxjs/toolkit';

const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState: {
    searchText: '',
    vehiclesGroupFilteredId: [],
  },
  reducers: {
    updateSearchText(state, action) {
      state.searchText = action.payload;
    },
    updateVehicleGroupFilteredId(state, action) {
      state.vehiclesGroupFilteredId = action.payload;
    },
  },
});

export const {updateSearchText, updateVehicleGroupFilteredId} = searchBarSlice.actions;
export default searchBarSlice.reducer;
