import React, {useState, useEffect, useMemo, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Alert,
  Box,
  Switch,
  TextField,
  Divider,
  Grid,
  Paper, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText ,
  Typography
} from '@mui/material';
import {
    unitsLengthDisplayConversion,
    unitsLengthSubmitConversion,
    handleAllowOnlyNumbers,
} from '../../app/utils';

// Global Constants
const SPRAY_SPEED_MAX_KPH = 12;
const SPRAY_SPEED_MIN_KPH = 0;

// Plot lines display box style and functionality
const plotLinesDisplayBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 525,
  borderRadius: '16px', // Added rounded corners
  p: 3,
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency
  border: 'none',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.04)', // Layered shadow
};

function getActiveOption(options, defaultValue = 'showInZonePaths') {
  // Get all keys that are true
  const trueKeys = Object.entries(options)
    .filter(([_, value]) => {
      return value === true;
    })
    .map(([key]) => {
      return key;
    });

  // If exactly one key is true, return it
  // Otherwise return default value
  return trueKeys.length === 1 ? trueKeys[0] : defaultValue;
}

function plasmaColorMap(value, targetMax = 100) {
  // Ensure value is within bounds
  const cappedValue = Math.max(0, Math.min(value, targetMax));

  // Normalize value to 0-1 range
  const normalizedValue = cappedValue / targetMax;

  // Plasma colormap coefficients
  // These are approximated values from the matplotlib plasma colormap
  const red = Math.round(
    255 * (0.03 + 2.76 * normalizedValue - 3.45 * Math.pow(normalizedValue, 2) + 1.65 * Math.pow(normalizedValue, 3))
  );
  const green = Math.round(
    255 * (-0.31 + 0.96 * normalizedValue + 1.37 * Math.pow(normalizedValue, 2) - 1.99 * Math.pow(normalizedValue, 3))
  );
  const blue = Math.round(
    255 * (1.11 - 3.0 * normalizedValue + 4.49 * Math.pow(normalizedValue, 2) - 2.59 * Math.pow(normalizedValue, 3))
  );

  // Calculate RGB values
  const r = Math.max(0, Math.min(255, red));
  const g = Math.max(0, Math.min(255, green));
  const b = Math.max(0, Math.min(255, blue));

  return [r, g, b];
}

// Maps a value to a red gradient
// Used for gradient visualization of speed
function mapToRedGradient(value, minValue, maxValue) {
    // Ensure value is within bounds
    const cappedValue = Math.min(value, maxValue);
    
    // Normalize value to 0-1 range
    // Needs to be normalized to within its bounds
    const normalizedValue = (cappedValue - minValue) / (maxValue - minValue);
    
    // Calculate red intensity
    // At 0: light red (255, 200, 200)
    // At 1: bright red (255, 0, 0)
    const red = 255;
    const green = Math.round(200 * (1 - normalizedValue));
    const blue = Math.round(200 * (1 - normalizedValue));
    
    return [red, green, blue];
}

// Maps a value to a blue gradient
// Used for gradient visualization of speed
function mapToBlueGradient(value, minValue, maxValue) {
    // Ensure value is within bounds
    const cappedValue = Math.max(0, value);
    
    // Normalize value to 0-1 range
    // Needs to be normalized to within its bounds
    const normalizedValue = (maxValue - cappedValue) / (maxValue - minValue);
    
    // Calculate red intensity
    // At 0: bright blue (0, 0, 255)
    // At 1: light blue (200, 200, 255)
    const red = Math.round(200 * (1 - normalizedValue));
    const green = Math.round(200 * (1- normalizedValue));
    const blue = 255;
    
    return [red, green, blue];
}

// Map speed values to the desired color display
function speedColorMap(value, taskConfigDocument, unitsSystem, pathViewOptions) {
    let red = 0;
    let green = 0;
    let blue = 0;

    // Check task status
    const speedVisualizationEnabled = taskConfigDocument ? taskConfigDocument.enableSpeedVisualization : false;
    let speedingMinKph = taskConfigDocument ? taskConfigDocument.speedTargetMinKph : 0;
    let speedingMaxKph = taskConfigDocument ? taskConfigDocument.speedTargetMaxKph : 0;

    // Override targets if custom target speed is used
    if (pathViewOptions.useCustomTaskSpeedTarget) {
      speedingMinKph = pathViewOptions.customTargets.minKph;
      speedingMaxKph = pathViewOptions.customTargets.maxKph
    }

    // If speed visualization is disabled or targets are 0, return a grey color
    const taskSpeedVisualizationNotSet = (!speedVisualizationEnabled || speedingMaxKph == 0 || speedingMinKph == 0);
    if ( taskSpeedVisualizationNotSet && !pathViewOptions.useCustomTaskSpeedTarget) {
      return [128, 128, 128];
    }

    // Convert units
    const units = unitsSystem === 'imperial' ? 'mph' : 'kph';
    const valueConverted = unitsLengthDisplayConversion(value, units);
    const speedingLowerBound = unitsLengthDisplayConversion(speedingMinKph, units);
    const speedingUpperBound = unitsLengthDisplayConversion(speedingMaxKph, units);

    // If in the range show as greeen
    if (valueConverted < speedingUpperBound && valueConverted > speedingLowerBound) {
      red = 0;
      green = 255;
      blue = 0;

    // If above the range show as red
    } else if (valueConverted >= speedingUpperBound) {

      red = 255;
      green = 0;
      blue = 0;
      // If we want to revert back to using a gradient
      // [red, green, blue] = mapToRedGradient(valueConverted, speedingUpperBound, speedingMax);

    // If below the range show as blue
    } else if (valueConverted <= speedingLowerBound) {

      red = 0;
      green = 0;
      blue = 255;
      // If we want to revert back to using a gradient
      // [red, green, blue] = mapToBlueGradient(valueConverted, speedingMin, speedingLowerBound); // Inverted, so need to use lower bound as max
    }

  return [red, green, blue];
}

function MapPathSelectGroup(props) {

  // Find path view option that is selected
  const [showSpeedPathsMode, setShowSpeedPathsMode] = useState(props.pathViewOptions.showSpeedPathsMode);
  const [showTargetsMode, setShowTargetsMode] = useState(props.pathViewOptions.showTargetsMode);
  const [showWarning, setShowWarning] = useState(false);

  const units = props.unitsLengthSystem === 'imperial' ? 'mph' : 'kph';
  const minCustomTargetKphConverted = unitsLengthDisplayConversion(props.pathViewOptions.customTargets.minKph, units).toFixed(2);
  const maxCustomTargetKphConverted = unitsLengthDisplayConversion(props.pathViewOptions.customTargets.maxKph, units).toFixed(2);
  const [useCustomTargetSpeedInputMin, setUseCustomTargetSpeedInputMin] = useState(minCustomTargetKphConverted);
  const [useCustomTargetSpeedInputMax, setUseCustomTargetSpeedInputMax] = useState(maxCustomTargetKphConverted);

  // Set gps view option
  const handleSetGps = (event) => {
    props.setPathViewOptions({
      ...props.pathViewOptions,
      showGpsTime: event.target.checked,
    });
  };

  // Handle speed / machine paths toggle
  const handleSpeedPathsToggle = (event) => {
    setShowSpeedPathsMode(event.target.value);
    
    // Set the selected option to true
    const showSpeedPathsToggle = event.target.value == 'showPathsByVehicle' ? false : true;
    props.setPathViewOptions({
      ...props.pathViewOptions,
      showSpeedPathsToggle: showSpeedPathsToggle,
      showSpeedPathsMode: event.target.value,
    });
  };

  // Handle target selection
  const handleTargetsSelection = (event) => {
    setShowTargetsMode(event.target.value);
    
    // Set the selected option to true
    const useCustomTaskSpeedTarget = event.target.value == 'useTaskAssignedTargetSpeed' ? false : true;
    props.setPathViewOptions({
      ...props.pathViewOptions,
      useCustomTaskSpeedTarget: useCustomTaskSpeedTarget,
      showTargetsMode: event.target.value,
    });
  };

  // Handle setting of custom target values
  const handleTargetsValues = (event) => {

    if (event.target.name === 'useCustomTargetSpeedInputMin') {

      // Ensure the value is less than the max value
      // If not, set the value to the max value - 0.01
      // and show a warning
      let updateValue = parseFloat(event.target.value);
      if (updateValue >= useCustomTargetSpeedInputMax){
        updateValue = useCustomTargetSpeedInputMax - 0.01;
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
      setUseCustomTargetSpeedInputMin(updateValue);

      // Convert the value to the correct units for storage
      // event.target.value is a string, so we need to convert it to a float
      const targetValueConverted = unitsLengthSubmitConversion(parseFloat(updateValue), units);
      props.setPathViewOptions({
        ...props.pathViewOptions,
        customTargets: {
          minKph: targetValueConverted,
          maxKph: props.pathViewOptions.customTargets.maxKph,
        },
      });
    }
    else if (event.target.name === 'useCustomTargetSpeedInputMax') {

      // Ensure the value is less than the max value
      // If not, set the value to the min value + 0.01
      // and show a warning
      let updateValue = parseFloat(event.target.value);
      if (updateValue <= useCustomTargetSpeedInputMin){
        updateValue = useCustomTargetSpeedInputMin + 0.01;
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
      setUseCustomTargetSpeedInputMax(updateValue);

      // Convert the value to the correct units for storage
      // event.target.value is a string, so we need to convert it to a float
      const targetValueConverted = unitsLengthSubmitConversion(parseFloat(updateValue), units);
      props.setPathViewOptions({
        ...props.pathViewOptions,
        customTargets: {
          minKph: props.pathViewOptions.customTargets.minKph,
          maxKph: targetValueConverted,
        },
      });
    }
  };

  return (
    <FormControl>
      
      <Typography variant="h5" gutterBottom sx={{ color: '#2979ff' }}>
        Coverage Settings
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ color: '#232324' }}>
        Display coverage color by:
      </Typography>

      <Grid container columnSpacing={1} rowSpacing={0}>

        {/* First Radio Group */}
        <Grid item xs={11}>
          <FormControl>
            <RadioGroup
              aria-labelledby="path-main-display-radio-group-label"
              name="path-main-display-radio-group"
              value={showSpeedPathsMode}
              onChange={handleSpeedPathsToggle}
            >
              <FormControlLabel
                value="showPathsByVehicle"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FontAwesomeIcon icon="fa-solid fa-tractor" /> Machine
                  </Box>
                }
              />
              <FormControlLabel
                value="showPathsByTaskSpeed"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FontAwesomeIcon icon="fa-solid fa-gauge-high" /> Target Speed
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
        </Grid>

        {/* Second Radio Group */}
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={11}>
            <FormControl>
              <RadioGroup
                aria-labelledby="path-speed-display-radio-group-label"
                name="path-speed-display-radio-group"
                value={showTargetsMode}
                onChange={handleTargetsSelection}
              >
                <FormControlLabel
                  value="useTaskAssignedTargetSpeed"
                  control={
                    <Radio
                      disabled={!props.pathViewOptions.showSpeedPathsToggle} // Disable if speed paths are not selected
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Default Task Targets
                    </Box>
                  }
                />
                <FormControlLabel
                  value="useCustomTargetSpeed"
                  control={
                    <Radio
                      disabled={!props.pathViewOptions.showSpeedPathsToggle} // Disable if speed paths are not selected
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Custom Target:
                      <TextField
                        size='small'
                        disabled={!props.pathViewOptions.useCustomTaskSpeedTarget} // Disable if speed paths are not selected
                        InputProps={{inputProps: {min: 0, step: 0.01}}}
                        name={'useCustomTargetSpeedInputMin'}
                        value={useCustomTargetSpeedInputMin}
                        onChange={handleTargetsValues}
                        onKeyDown={handleAllowOnlyNumbers}
                        sx={{ width: 125 }}
                        label={`Min (${units == 'imperial' ? 'kph' : 'mph'})`}
                        type={'number'}
                      />
                      <TextField
                        size='small'
                        disabled={!props.pathViewOptions.useCustomTaskSpeedTarget} // Disable if speed paths are not selected
                        InputProps={{inputProps: {min: 0, step: 0.01}}}
                        name={'useCustomTargetSpeedInputMax'}
                        value={useCustomTargetSpeedInputMax}
                        onChange={handleTargetsValues}
                        onKeyDown={handleAllowOnlyNumbers}
                        sx={{ width: 125 }}
                        label={`Max (${units == 'imperial' ? 'kph' : 'mph'})`}
                        type={'number'}
                      />
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
        </Grid>

      </Grid>

      {/* Error Alert */}
      {
        showWarning && 
        props.pathViewOptions.useCustomTaskSpeedTarget &&
        props.pathViewOptions.showSpeedPathsToggle && (
      <Alert severity="warning" variant='outlined'>
        Please ensure the minimun target is less than the maximun target for accurate data visualization.
      </Alert>
      )}

      {/* Gps timestamp selection */}
      <Divider sx={{ my: 1, borderBottomWidth: 2 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FontAwesomeIcon icon="fa-solid fa-stopwatch" size="xl" /> Show GPS Timestamp
        </Box>
        <Switch 
          checked={props.pathViewOptions.showGpsTime}
          onChange={handleSetGps}
          disabled={props.pathViewOptions.showSpeedPathsToggle}
        />
      </Box>

    </FormControl>
  );
}



// Returns a gradient color in the input color
// Used for the legend if we do a gradient display
const createGradientColor = (color) => {
  // Convert hex to RGB
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Create gradient from light to bright
  return `linear-gradient(to right, 
      rgba(${r}, ${g}, ${b}, 0.2), 
      rgba(${r}, ${g}, ${b}, 0.85)
    )`;
  };

const SpeedPathLegend = () => {

    const green = '#12f50a'; // Bright green
    const red = '#f50a0a'; // Bright red
    const blue = '#0a0ef5'; // Bright blue
    const grey = '#808080'; // Grey
    const legendItems = [
        { label: 'Over', color: red }, 
        { label: 'Within', color: green },
        { label: 'Under', color: blue },
        { label: 'Not Assigned', color: grey }
    ];

    return (
        <Box m={1}>
        <Paper
            elevation={3}
            sx={{
                zIndex: 10,
                backgroundColor: 'rgba(255, 255, 255, 1)',
                top: 2,
                right: 8,
                bottom: 2,
                left: 8,
                borderRadius: 2,
                boxShadow: 1,
                padding: 1, 
                paddingTop: 0, 
                paddingBottom: 0, 
            }}
        >
        <Typography 
            variant="body2" 
            sx={{ mb: -1, pb: -0, pt: 1, fontWeight: 'medium', fontSize: '0.75rem'}}
        >
            Target Speed: 
        </Typography>
        <List dense>  {/* 'dense' prop reduces vertical spacing */}
            {legendItems.map((item, index) => {return (
            <ListItem key={index} disableGutters sx={{ py: 0 }}>
                <ListItemIcon sx={{ minWidth: 20 }}>
                <Box
                  sx={{
                    width: 16,
                    height: 12,
                    // If we want to use a gradient
                    // background: (item.color !== green && item.color !== grey) ? createGradientColor(item.color) : item.color,
                    background: item.color,
                    borderRadius: 1
                  }}
                />
                </ListItemIcon>

                <ListItemText 
                    primary={item.label} 
                    primaryTypographyProps={{ 
                        variant: 'body2',  // Smaller text
                        sx: { fontSize: '0.75rem' } 
                    }}
                />
            </ListItem>
            )
            
            } )}
        </List>
    </Paper>
    </Box>
    );
};

function MapPathSelectModal(props) {
  return (
    <Modal
      open={props.pathSelectOpen}
      onClose={() => {
        return props.setPathSelectOpen(false);
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={plotLinesDisplayBoxStyle}>
        <MapPathSelectGroup
          {...props}
        />
      </Box>
    </Modal>
  );
}

export {MapPathSelectModal, speedColorMap, SpeedPathLegend};
