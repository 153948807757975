import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';

function DownStatusIcon(props) {
  const location = useLocation();

  const lastCropviewTimeStamp = useSelector((state) => {
    return state.cropview.lastTripTaskEndTime;
  });
  const processingInProgress = useSelector((state) => {
    return state.cropview.processingInProgress;
  });
  const refreshRequired = useSelector((state) => {
    return state.cropview.refreshRequired;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const defaultStatusToolTip = `Some services are not available, 
    you may experience some interruptions using the platform`;
  const [serviceStatusActive, setServiceStatusActive] = useState(false);
  const [serviceStatusTooltip, setServiceStatusTooltip] = useState(defaultStatusToolTip);
  const [downStatusUpdateToggle, setdownStatusUpdateToggle] = useState(false);

  useEffect(async () => {
    let newToolTip;

    if (location.pathname == '/cropview') {
      if (processingInProgress) {
        newToolTip =
          'Data reprocessing in progress for your selected time range.\nDisplayed data may be subject to change.';
        setServiceStatusActive(true);
      } else {
        if (refreshRequired) {
          newToolTip = 'Cropview has not been refreshed in 1 hour\n Please refresh for latest updates\n';
          setServiceStatusActive(true);
        } else {
          newToolTip = '';
          setServiceStatusActive(false);
        }

        let displayTimeString = 'Unavailable';
        if (lastCropviewTimeStamp) {
          displayTimeString = DateTime.fromISO(lastCropviewTimeStamp)
            .setZone(customerSettings.general.timeZone)
            .toFormat('MM/dd/yyyy, hh:mm:ss a');
        }
        newToolTip += `Last data timestamp: ${displayTimeString}`;
      }
    } else {
      const downStatusProcessed = await getDownStatus();
      if (downStatusProcessed.geotabDownStatus === true || downStatusProcessed.faunaDownStatus === true) {
        newToolTip = defaultStatusToolTip + `\n`;
        setServiceStatusActive(true);
      } else {
        // reset the tooltip display and string
        newToolTip = ``;
        setServiceStatusActive(false);
      }

      if (!props.login || props.login !== true) {
        // Append Last data timestamp to Tooltip message
        const displayTimeString = await getLastDataTimeStampString();
        newToolTip += `Last data timestamp: ${displayTimeString}`;
      }
    }

    // Update Tooltip Message in state
    setServiceStatusTooltip(newToolTip);

    // Check if Tooltip is opened
    const downStatusButton = document.getElementById('downStatusButton');
    if (typeof downStatusButton !== 'undefined') {
      const tooltipBodyId = downStatusButton.getAttribute('aria-describedby');
      if (tooltipBodyId) {
        // Update Opened Tooltip with Jquery
        $('#' + tooltipBodyId + ' .tooltip-inner')[0].innerHTML = newToolTip;
      }
    }
    // Init BootStrap tooltips on first load
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }, [downStatusUpdateToggle, refreshRequired, lastCropviewTimeStamp, processingInProgress]);

  async function getLastDataTimeStampString() {
    const serviceStatus = await getServiceStatus();
    // Default Latest Data timestamp string for display
    let displayTimeString = 'None';

    if (serviceStatus.geotabStatus.lastDataTime != null && serviceStatus.faunaStatus.lastDataTime != null) {
      // If Both services last data time is available
      // Parse Last data times
      const geotabLastDataLocalTime = new Date(serviceStatus.geotabStatus.lastDataTime);
      const faunaLastDataLocalTime = new Date(serviceStatus.faunaStatus.lastDataTime);

      // Compare time and use latest time for display
      if (geotabLastDataLocalTime > faunaLastDataLocalTime) {
        displayTimeString = geotabLastDataLocalTime.toLocaleString();
      } else {
        displayTimeString = faunaLastDataLocalTime.toLocaleString();
      }
    } else if (serviceStatus.geotabStatus.lastDataTime != null) {
      // If only geotab last data time is available
      displayTimeString = new Date(serviceStatus.geotabStatus.lastDataTime).toLocaleString();
    } else if (serviceStatus.faunaStatus.lastDataTime != null) {
      // If only fauna last data time is available
      displayTimeString = new Date(serviceStatus.faunaStatus.lastDataTime).toLocaleString();
    }

    return displayTimeString;
  }

  async function getServiceStatus() {
    const serviceStatusResponse = await fetch('/getServiceStatus');
    const serviceStatus = await serviceStatusResponse.json();
    return serviceStatus;
  }

  async function getDownStatus() {
    const downStatusResponse = await fetch('/getDownStatus');
    const downStatusArray = await downStatusResponse.json();
    // default down Status, if return empty array means fauna is down
    const downStatusProcessed = {
      geotabDownStatus: false,
      faunaDownStatus: downStatusArray.length > 0 ? false : true,
    };
    // Loop through downStatus in order of response (customer specific -> all)
    for (let i = 0; i < downStatusArray.length; i++) {
      if (downStatusArray[i].geotabDownStatus === true) {
        downStatusProcessed.geotabDownStatus = true;
      }
      if (downStatusArray[i].faunaDownStatus === true) {
        downStatusProcessed.faunaDownStatus = true;
      }
    }
    return downStatusProcessed;
  }

  async function buttonClick() {
    setdownStatusUpdateToggle(!downStatusUpdateToggle);
  }

  return (
    <button
      data-toggle='tooltip'
      title={serviceStatusTooltip}
      data-original-title={serviceStatusTooltip}
      style={{
        display: props.login && props.login === true && !serviceStatusActive ? 'none' : '',
        border: 'none',
        backgroundColor: props.login && props.login === true ? '#fff' : '#f8f9fc',
      }}
      id='downStatusButton'
      onClick={buttonClick}
    >
      {serviceStatusActive ? (
        <FontAwesomeIcon
          icon='fas fa-triangle-exclamation'
          size={props.small === true ? '1x' : '2x'}
          style={{color: '#f4c526'}}
        />
      ) : (
        <FontAwesomeIcon
          icon='fas fa-info-circle '
          size={props.small === true ? '1x' : '2x'}
          style={{color: '#858796'}}
        />
      )}
    </button>
  );
}

export {DownStatusIcon};
