import React, {useState, useEffect, useRef} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {
  customChartProperties,
  unitsLengthDisplayConversion,
  deepCopy,
  isNumeric,
  unitsAreaConversion,
  unionLabelsAndDataZones,
  unionLabelsAndDataTasks,
  unionLabelsAndDataVehicles,
} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genEfficiencyData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportSideControlWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Tooltip,
  Box,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {setFilterOptions, setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';

ChartJS.register(annotationPlugin);

const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiEfficiency(props) {
  const dispatch = useDispatch();

  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });

  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });

  const [selectedInnerSubset, setSelectedInnerSubset] = useState('Total');
  const [selectedZoneLevel, setSelectedZoneLevel] = useState('Region');
  const [selectedSpecialView, setSelectedSpecialView] = useState(null);
  const [targetsObject, setTargetsObject] = useState({});
  const [efficiencyData, setEfficiencyData] = useState(null);
  const [datasets, setDatasets] = useState(null);

  const [displayedAxis, setDisplayedAxis] = useState('Ac/Hr');
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');
  const [scaleId, setScaleId] = useState('AcHr');

  const [hourDollarRate, setHourDollarRate] = useState('');
  const [areaDollarRate, setAreaDollarRate] = useState('');
  const [innerSubsetOptions, setInnerSubsetOptions] = useState([]);
  const [efficiencyFilters, setEfficiencyFilters] = useState({
    efficiencyZoneNames: {
      'Block': [],
      'Field': [],
      'Region': [],
    },
    efficiencyZoneSort: 'Desc',
    efficiencyZoneCount: '10',
    efficiencyZones: [],
    efficiencyRowSpacing: [],
    efficiencyVehicles: [],
  });
  const [zoneFilterOptions, setZoneFilterOptions] = useState([]);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);

  const additionalInputRef = useRef();

  useEffect(() => {
    if (compareByToggle && timeAxes.includes(selectedSpecialView)) {
      setSelectedZoneLevel('Region');
      setSelectedSpecialView(null);
    }
  }, [compareByToggle]);

  useEffect(() => {
    if (selectedZoneLevel == 'Block' && operationsData != undefined && operationsData.hasOwnProperty('blocksObject')) {
      let efficiencyBlocks = Object.keys(deepCopy(operationsData.blocksObject));
      efficiencyBlocks.sort();
      if (compareByToggle && compareByOperationsData != null) {
        efficiencyBlocks = new Set([
          ...Object.keys(operationsData.blocksObject),
          ...Object.keys(compareByOperationsData.blocksObject),
        ]);
        efficiencyBlocks = [...efficiencyBlocks];
        efficiencyBlocks.sort();
      }

      setZoneFilterOptions(efficiencyBlocks);
    }
    if (selectedZoneLevel == 'Field' && operationsData != undefined && operationsData.hasOwnProperty('fieldsObject')) {
      let efficiencyFields = Object.keys(deepCopy(operationsData.fieldsObject));
      efficiencyFields.sort();
      if (compareByToggle && compareByOperationsData != null) {
        efficiencyFields = new Set([
          ...Object.keys(operationsData.fieldsObject),
          ...Object.keys(compareByOperationsData.fieldsObject),
        ]);
        efficiencyFields = [...efficiencyFields];
        efficiencyFields.sort();
      }

      setZoneFilterOptions(efficiencyFields);
    }
    if (
      selectedZoneLevel == 'Region' &&
      operationsData != undefined &&
      operationsData.hasOwnProperty('regionsObject')
    ) {
      let efficiencyRegions = Object.keys(deepCopy(operationsData.regionsObject));
      efficiencyRegions.sort();
      if (compareByToggle && compareByOperationsData != null) {
        efficiencyRegions = new Set([
          ...Object.keys(operationsData.regionsObject),
          ...Object.keys(compareByOperationsData.regionsObject),
        ]);
        efficiencyRegions = [...efficiencyRegions];
        efficiencyRegions.sort();
      }
      setZoneFilterOptions(efficiencyRegions);
    }
  }, [selectedZoneLevel, operationsData, selectedSpecialView]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    compareByOperationsData,
    taskConfigs,
    selectedZoneLevel,
    selectedInnerSubset,
    efficiencyFilters,
    displayedAxis,
    areaDollarRate,
    hourDollarRate,
    selectedSpecialView,
    unitsLengthSystem,
    unitsAreaSystem,
    compareByToggle,
  ]);

  useEffect(() => {
    if (displayedAxis == 'Total $' && !areaDollarRate && !hourDollarRate && additionalInputRef?.current) {
      additionalInputRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [displayedAxis]);

  useEffect(() => {
    const filterOptionsTemp = deepCopy(filterOptions);
    if (selectedSpecialView == null) {
      filterOptionsTemp.efficiency.efficiencyZoneSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    } else if (['Task', 'Vehicle'].includes(selectedSpecialView)) {
      filterOptionsTemp.efficiency.efficiencyZoneSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    } else {
      filterOptionsTemp.efficiency.efficiencyZoneSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Chronological', text: 'Chronological'},
      ];
    }
    dispatch(setFilterOptions(filterOptionsTemp));
  }, [selectedSpecialView]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    let distUnits = 'km';
    if (unitsLengthSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsLengthSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsLengthSystem]);

  useEffect(() => {
    if (props.pageStates?.selectedInnerSubset) {
      setSelectedInnerSubset(props.pageStates?.selectedInnerSubset);
    } else {
      setSelectedInnerSubset('Total');
    }
    if (props.pageStates?.selectedZoneLevel) {
      setSelectedZoneLevel(props.pageStates?.selectedZoneLevel);
    } else {
      setSelectedZoneLevel('Region');
    }
    if (props.pageStates?.displayedAxis) {
      let displayedAxis = props.pageStates?.displayedAxis;
      if (['Ha/Hr', 'Ac/Hr'].includes(props.pageStates?.displayedAxis)) {
        displayedAxis = `${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}/Hr`;
      }
      if (['Hr/Ha', 'Hr/Ac'].includes(props.pageStates?.displayedAxis)) {
        displayedAxis = `Hr/${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}`;
      }
      if (['Hectares', 'Acres'].includes(props.pageStates?.displayedAxis)) {
        displayedAxis = `${unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acres'}`;
      }
      setDisplayedAxis(displayedAxis);
    } else {
      setDisplayedAxis(`${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}/Hr`);
    }
    if (props.pageStates?.scaleId) {
      setScaleId(props.pageStates?.scaleId);
    } else {
      setScaleId(`${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}Hr`);
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(props.pageStates?.hourDollarRate);
    } else {
      setHourDollarRate('');
    }
    if (props.pageStates?.acreDollarRate) {
      const dollarPerAreaConverted = `${unitsAreaConversion(
        parseFloat(props.pageStates?.acreDollarRate),
        'ac',
        unitsAreaSystem == 'hectare' ? 'ha' : 'ac'
      )}`;
      setAreaDollarRate(parseFloat(dollarPerAreaConverted));
    } else {
      setAreaDollarRate('');
    }
    if (props.pageStates?.selectedSpecialView) {
      setSelectedSpecialView(props.pageStates?.selectedSpecialView);
    } else {
      setSelectedSpecialView(null);
    }

    if (props?.pageFilters) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }

    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setAreaDollarRate('');
      setHourDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && areaDollarRate == '') {
      setDisplayedAxis(`${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}/Hr`);
    }
  }
  function handleAreaDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setHourDollarRate('');
      setAreaDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && hourDollarRate == '') {
      setDisplayedAxis(`${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'}/Hr`);
    }
  }

  function applyFiltersToOpsData(efficiencyTasksObject, efficiencyBlocksObject) {
    // Add Total to tasks objects
    const totalObj = {
      'Block': {},
      'Field': {},
      'Region': {},
    };

    Object.keys(efficiencyTasksObject).forEach((task) => {
      Object.keys(efficiencyTasksObject[task]).forEach((zoneType) => {
        Object.keys(efficiencyTasksObject[task][zoneType]).forEach((zone) => {
          // Add zone to total
          if (typeof totalObj[zoneType][zone] === 'undefined') {
            totalObj[zoneType][zone] = {
              vehiclesObject: {}
            };
          }

          Object.keys(efficiencyTasksObject[task][zoneType][zone].vehiclesObject).forEach((vehicleSN) => {
            // Add vehicle total
            if (typeof totalObj[zoneType][zone].vehiclesObject[vehicleSN] === 'undefined') {
              totalObj[zoneType][zone].vehiclesObject[vehicleSN] = {
                acPerHr: 0,
                acreage: 0,
                avgSpeed: 0,
                distance: 0,
                duration: 0,
                enteredTaskAcreage: 0,
                missingTaskAcreage: 0
              };
            }

            // Add data to total
            totalObj[zoneType][zone].vehiclesObject[vehicleSN].acreage +=
              efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].acreage;
            totalObj[zoneType][zone].vehiclesObject[vehicleSN].distance +=
              efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].distance;
            totalObj[zoneType][zone].vehiclesObject[vehicleSN].duration +=
              efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].duration;
          });
        });
      });
    });

    efficiencyTasksObject['Total'] = totalObj;

    // Apply filtering
    Object.keys(efficiencyTasksObject).forEach((task) => {
      Object.keys(efficiencyTasksObject[task]).forEach((zoneType) => {
        Object.keys(efficiencyTasksObject[task][zoneType]).forEach((zone) => {
          // Zone in filter or the filters for this zonetype are length 0
          let zoneRowSpacingInFilter = true;

          if (zoneType == 'Block' && efficiencyFilters.efficiencyRowSpacing.length > 0) {
            const blockRowSpacing = efficiencyBlocksObject[zone].rowSpacingMeters;
            const blockRowSpacingNumeric = isNumeric(blockRowSpacing) ? Math.round(blockRowSpacing * 100) / 100 : 0;
            const filteredRowSpacing = efficiencyFilters.efficiencyRowSpacing.map((rowSpacing) => {
              return parseFloat(rowSpacing);
            });
            zoneRowSpacingInFilter = filteredRowSpacing.includes(blockRowSpacingNumeric);
          }

          const zoneNameInFilter =
            efficiencyFilters.efficiencyZoneNames[zoneType].includes(zone) ||
            efficiencyFilters.efficiencyZoneNames[zoneType].length == 0;

          const zoneInFilter = zoneRowSpacingInFilter && zoneNameInFilter;

          if ((efficiencyTasksObject[task][zoneType][zone]['acreage'] == 0 && !compareByToggle) || !zoneInFilter) {
            delete efficiencyTasksObject[task][zoneType][zone];
          }

          // Apply vehicle filtering to task object dict
          if (typeof efficiencyTasksObject[task][zoneType][zone] !== 'undefined') {
            const updatedValsDict = {
              acPerHr: 0,
              acreage: 0,
              avgSpeed: 0,
              distance: 0,
              duration: 0,
              enteredTaskAcreage: 0,
              missingTaskAcreage: 0
            };
            Object.keys(efficiencyTasksObject[task][zoneType][zone].vehiclesObject).forEach((vehicleSN) => {
              if (efficiencyFilters.efficiencyVehicles.length == 0 || efficiencyFilters.efficiencyVehicles.includes(vehicleSN)) {
                updatedValsDict.acreage += efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].acreage;
                updatedValsDict.distance += efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].distance;
                updatedValsDict.duration += efficiencyTasksObject[task][zoneType][zone].vehiclesObject[vehicleSN].duration;
              }
            });
            updatedValsDict.acPerHr = updatedValsDict.duration == 0 ? 0 : updatedValsDict.acreage / updatedValsDict.duration;
            updatedValsDict.avgSpeed = updatedValsDict.duration == 0 ? 0 : updatedValsDict.distance / updatedValsDict.duration;
  
            efficiencyTasksObject[task][zoneType][zone] = Object.assign(efficiencyTasksObject[task][zoneType][zone], updatedValsDict);  
          }
        });
      });
    });

    const combined = deepCopy(selectedInnerSubset);
    const combinedObj = {};
    if (combined.constructor === Array && combined.length > 1) {
      combined.forEach((id) => {
        if (efficiencyTasksObject.hasOwnProperty(id)) {
          const curItem = deepCopy(efficiencyTasksObject[id]);
          Object.keys(curItem).forEach((zoneType) => {
            if (!combinedObj.hasOwnProperty(zoneType)) {
              combinedObj[zoneType] = {};
            }
            Object.keys(curItem[zoneType]).forEach((zone) => {
              if (!combinedObj[zoneType].hasOwnProperty(zone)) {
                combinedObj[zoneType][zone] = deepCopy(curItem[zoneType][zone]);
              } else {
                Object.keys(curItem[zoneType][zone]).forEach((key) => {
                  combinedObj[zoneType][zone][key] += deepCopy(curItem[zoneType][zone][key]);
                });
              }
              combinedObj[zoneType][zone]['acPerHr'] =
                combinedObj[zoneType][zone]['acreage'] / combinedObj[zoneType][zone]['duration'];
              combinedObj[zoneType][zone]['avgSpeed'] =
                combinedObj[zoneType][zone]['distance'] / combinedObj[zoneType][zone]['duration'];
            });
          });
        }
      });
      if (Object.keys(combinedObj).length > 0) {
        efficiencyTasksObject['multiSelectedCombined'] = combinedObj;
      }
    }

    return efficiencyTasksObject;
  }

  function generateFilteredOpsData(opsData) {
    const efficiencyTasksObjectPreFilter = deepCopy(opsData.tasksObject);
    const efficiencyBlocksObjectPreFilter = deepCopy(opsData.blocksObject);
    const efficiencyVehicleObjectPreFilter = deepCopy(opsData.vehiclesObject);
    const opsByDayPreFilter = deepCopy(opsData.opsByDay);
    const opsByWeekPreFilter = deepCopy(opsData.opsByWeek);
    const opsByMonthPreFilter = deepCopy(opsData.opsByMonth);
    const opsByYearPreFilter = deepCopy(opsData.opsByYear);

    // Filter data
    const efficiencyTasksObject = applyFiltersToOpsData(efficiencyTasksObjectPreFilter, efficiencyBlocksObjectPreFilter);

    // Filter vehicle data
    Object.keys(efficiencyVehicleObjectPreFilter).forEach((vehiclesn) => {
      efficiencyVehicleObjectPreFilter[vehiclesn] = Object.assign(efficiencyVehicleObjectPreFilter[vehiclesn], {
        acPerHr: 0,
        acreage: 0,
        avgSpeed: 0,
        distance: 0,
        duration: 0,
      });
    });

    Object.keys(efficiencyTasksObject).forEach((task) => {
      if (
        task !== 'Total' &&
        (selectedInnerSubset == 'Total' || selectedInnerSubset[0] == 'Total' ||
          selectedInnerSubset == task || selectedInnerSubset.includes(task))
      ) {
        Object.keys(efficiencyTasksObject[task]['Region']).forEach((zone) => {
          Object.keys(efficiencyTasksObject[task]['Region'][zone].vehiclesObject).forEach((vehicleSN) => {
            if (!efficiencyVehicleObjectPreFilter.hasOwnProperty(vehicleSN)) {
              efficiencyVehicleObjectPreFilter[vehicleSN] = {
                acPerHr: 0,
                acreage: 0,
                avgSpeed: 0,
                distance: 0,
                duration: 0,
              }
            }
            efficiencyVehicleObjectPreFilter[vehicleSN].acreage +=
              efficiencyTasksObject[task]['Region'][zone].vehiclesObject[vehicleSN].acreage;
            efficiencyVehicleObjectPreFilter[vehicleSN].distance +=
              efficiencyTasksObject[task]['Region'][zone].vehiclesObject[vehicleSN].distance;
            efficiencyVehicleObjectPreFilter[vehicleSN].duration +=
              efficiencyTasksObject[task]['Region'][zone].vehiclesObject[vehicleSN].duration;
          });
        });
      }
    });

    Object.keys(efficiencyVehicleObjectPreFilter).forEach((vehiclesn) => {
      efficiencyVehicleObjectPreFilter[vehiclesn].acPerHr =
        efficiencyVehicleObjectPreFilter[vehiclesn].duration == 0 ?
        0 :
        efficiencyVehicleObjectPreFilter[vehiclesn].acreage / efficiencyVehicleObjectPreFilter[vehiclesn].duration;
      efficiencyVehicleObjectPreFilter[vehiclesn].avgSpeed =
        efficiencyVehicleObjectPreFilter[vehiclesn].duration == 0 ?
        0 :
        efficiencyVehicleObjectPreFilter[vehiclesn].distance / efficiencyVehicleObjectPreFilter[vehiclesn].duration;
    });

    
    // Filter vehicles
    Object.keys(efficiencyVehicleObjectPreFilter).forEach((vehiclesn) => {
      const vehicleInFilter =
        efficiencyFilters.efficiencyVehicles.includes(vehiclesn) || efficiencyFilters.efficiencyVehicles.length == 0;
      if ((efficiencyVehicleObjectPreFilter[vehiclesn]['acreage'] == 0 && !compareByToggle) || !vehicleInFilter) {
        delete efficiencyVehicleObjectPreFilter[vehiclesn];
      }
    });
    
    const efficiencyVehiclesObject = efficiencyVehicleObjectPreFilter;
    const opsByDay = {};
    const opsByWeek = {};
    const opsByMonth = {};
    const opsByYear = {};

    Object.keys(opsByDayPreFilter).forEach((dayKey) => {
      const rep = opsByDayPreFilter[dayKey];
      const tasksObject = applyFiltersToOpsData(rep.operationsReport.tasksObject, rep.operationsReport.blocksObject);
      const dayOps = {
        'tasksObject': tasksObject,
        'start': rep.start,
      };
      opsByDay[dayKey] = dayOps;
    });

    Object.keys(opsByWeekPreFilter).forEach((weekKey) => {
      const weekRep = opsByWeekPreFilter[weekKey];
      const tasksObject = applyFiltersToOpsData(
        weekRep.operationsReport.tasksObject,
        weekRep.operationsReport.blocksObject
      );
      const weekOps = {
        'tasksObject': tasksObject,
        'start': weekRep.start,
      };
      opsByWeek[weekKey] = weekOps;
    });

    Object.keys(opsByMonthPreFilter).forEach((monthKey) => {
      const monthRep = opsByMonthPreFilter[monthKey];
      const tasksObject = applyFiltersToOpsData(
        monthRep.operationsReport.tasksObject,
        monthRep.operationsReport.blocksObject
      );
      const monthOps = {
        'tasksObject': tasksObject,
        'start': monthRep.start,
      };
      opsByMonth[monthKey] = monthOps;
    });

    Object.keys(opsByYearPreFilter).forEach((yearKey) => {
      const yearRep = opsByYearPreFilter[yearKey];
      const tasksObject = applyFiltersToOpsData(
        yearRep.operationsReport.tasksObject,
        yearRep.operationsReport.blocksObject
      );
      const yearOps = {
        'tasksObject': tasksObject,
        'start': yearRep.start,
      };
      opsByYear[yearKey] = yearOps;
    });
    return [
      efficiencyTasksObject,
      efficiencyBlocksObjectPreFilter,
      opsByDay,
      opsByWeek,
      opsByMonth,
      opsByYear,
      efficiencyVehiclesObject,
    ];
  }

  function generateData() {
    // Get targets

    const targetsObject = {};
    Object.keys(taskConfigs).forEach((taskId) => {
      if (taskConfigs[taskId].hasOwnProperty('acPerHrTarget') || taskConfigs[taskId].hasOwnProperty('avgSpeedTarget')) {
        if (!targetsObject.hasOwnProperty(taskId)) {
          targetsObject[taskId] = {};
        }

        // Calculate targets in hectares & acres to have both units available
        const hectaresPerHr = unitsAreaConversion(taskConfigs[taskId].acPerHrTarget, 'ha', 'ac');
        targetsObject[taskId]['Ac/Hr'] = taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Hr/Ac'] =
          taskConfigs[taskId].acPerHrTarget != 0 && 1 / taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Ha/Hr'] = hectaresPerHr;
        targetsObject[taskId]['Hr/Ha'] = hectaresPerHr != 0 && 1 / hectaresPerHr;
        targetsObject[taskId]['Speed'] =
          unitsLengthDisplayConversion(taskConfigs[taskId].avgSpeedTarget * 100, distanceUnits) / 100;
      }
    });
    setTargetsObject(targetsObject);
    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      setTargetsObject(props.pageStates.pageTargets);
    }

    const operationsDataCopy = deepCopy(operationsData);
    const compareByOperationsDataCopy = deepCopy(compareByOperationsData);
    let compareByEfficiencyDataTemp;
    let compareByEfficiencyTasksObject = null;
    let compareByEfficiencyBlocksObject = null;
    let compareByOpsByDay = null;
    let compareByOpsByWeek = null;
    let compareByOpsByMonth = null;
    let compareByOpsByYear = null;
    let compareByEfficiencyVehiclesObject = null;

    if (compareByToggle && compareByOperationsData != null) {
      const [newOpsBlocksObject, newCompareByOpsBlocksObject] = unionLabelsAndDataZones(
        operationsData.blocksObject,
        compareByOperationsData.blocksObject,
        ['rowSpacingMeters']
      );
      const [newOpsFieldsObject, newCompareByOpsFieldsObject] = unionLabelsAndDataZones(
        operationsData.fieldsObject,
        compareByOperationsData.fieldsObject
      );
      const [newOpsRegionsObject, newCompareByOpsRegionsObject] = unionLabelsAndDataZones(
        operationsData.regionsObject,
        compareByOperationsData.regionsObject
      );
      const [newOpsTasksObject, newCompareByOpsTasksObject] = unionLabelsAndDataTasks(
        operationsData.tasksObject,
        compareByOperationsData.tasksObject
      );
      const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
        operationsData.vehiclesObject,
        compareByOperationsData.vehiclesObject,
        ['type', 'vehicleName']
      );

      compareByOperationsDataCopy.blocksObject = newCompareByOpsBlocksObject;
      compareByOperationsDataCopy.fieldsObject = newCompareByOpsFieldsObject;
      compareByOperationsDataCopy.regionsObject = newCompareByOpsRegionsObject;
      compareByOperationsDataCopy.tasksObject = newCompareByOpsTasksObject;
      compareByOperationsDataCopy.vehiclesObject = newCompareByOpsVehiclesObject;

      operationsDataCopy.blocksObject = newOpsBlocksObject;
      operationsDataCopy.fieldsObject = newOpsFieldsObject;
      operationsDataCopy.regionsObject = newOpsRegionsObject;
      operationsDataCopy.tasksObject = newOpsTasksObject;
      operationsDataCopy.vehiclesObject = newOpsVehiclesObject;

      const [
        compareByEfficiencyTasksObjectTemp,
        compareByEfficiencyBlocksObjectTemp,
        compareByOpsByDayTemp,
        compareByOpsByWeekTemp,
        compareByOpsByMonthTemp,
        compareByOpsByYearTemp,
        compareByEfficiencyVehiclesObjectTemp,
      ] = generateFilteredOpsData(compareByOperationsDataCopy);

      compareByEfficiencyTasksObject = compareByEfficiencyTasksObjectTemp;
      compareByEfficiencyBlocksObject = compareByEfficiencyBlocksObjectTemp;
      compareByOpsByDay = compareByOpsByDayTemp;
      compareByOpsByWeek = compareByOpsByWeekTemp;
      compareByOpsByMonth = compareByOpsByMonthTemp;
      compareByOpsByYear = compareByOpsByYearTemp;
      compareByEfficiencyVehiclesObject = compareByEfficiencyVehiclesObjectTemp;
    }

    // This has to be done after our unionization so that the operationsDataCopy has the unionized labels
    const [
      efficiencyTasksObject,
      efficiencyBlockObject,
      opsByDay,
      opsByWeek,
      opsByMonth,
      opsByYear,
      efficiencyVehiclesObject,
    ] = generateFilteredOpsData(operationsDataCopy);
    
    const sortingObject = {
      'efficiencyTasksObject': efficiencyTasksObject,
      'efficiencyVehiclesObject': efficiencyVehiclesObject,
    };

    // This has to be done after the sorting object has been instantiated
    if (compareByToggle && compareByOperationsData != null) {
      compareByEfficiencyDataTemp = genEfficiencyData(
        compareByEfficiencyTasksObject,
        compareByEfficiencyBlocksObject,
        compareByEfficiencyVehiclesObject,
        efficiencyFilters,
        displayedAxis,
        unitsLengthSystem,
        unitsAreaSystem,
        customerSettings.dashboard.kpiEfficiencyHoverDetailsEnabled,
        hourDollarRate,
        areaDollarRate,
        taskConfigs,
        compareByOpsByDay,
        compareByOpsByWeek,
        compareByOpsByMonth,
        compareByOpsByYear,
        selectedZoneLevel,
        selectedInnerSubset,
        sortingObject,
        true
      );
    }

    const efficiencyDataTemp = genEfficiencyData(
      efficiencyTasksObject,
      efficiencyBlockObject,
      efficiencyVehiclesObject,
      efficiencyFilters,
      displayedAxis,
      unitsLengthSystem,
      unitsAreaSystem,
      customerSettings.dashboard.kpiEfficiencyHoverDetailsEnabled,
      hourDollarRate,
      areaDollarRate,
      taskConfigs,
      opsByDay,
      opsByWeek,
      opsByMonth,
      opsByYear,
      selectedZoneLevel,
      selectedInnerSubset,
      sortingObject
    );

    if (
      selectedSpecialView == null &&
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length > 1 &&
      efficiencyDataTemp[selectedZoneLevel].hasOwnProperty('multiSelectedCombined')
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined']);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined'].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.displayedAxis ||
          (dataset.displayedAxis == 'Target' &&
            targetsObject.hasOwnProperty('multiSelectedCombined') &&
            displayedAxis != 'Total $' &&
            targetsObject['multiSelectedCombined'][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
          // Also add the corresponding compare by dataset
          if (
            compareByToggle &&
            compareByEfficiencyDataTemp != null &&
            compareByEfficiencyDataTemp.hasOwnProperty(selectedZoneLevel) &&
            compareByEfficiencyDataTemp[selectedZoneLevel].hasOwnProperty('multiSelectedCombined')
          ) {
            compareByEfficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined'].datasets.forEach(
              (compareByDataset) => {
                if (
                  displayedAxis == compareByDataset.displayedAxis ||
                  (compareByDataset.displayedAxis == 'Target' &&
                    targetsObject.hasOwnProperty('multiSelectedCombined') &&
                    displayedAxis != 'Total $' &&
                    targetsObject['multiSelectedCombined'][[displayedAxis]] != 0)
                ) {
                  datasetsIncluded.push(compareByDataset);
                }
              }
            );
          }
        }
      });

      setDatasets(datasetsIncluded);
    } else if (
      selectedSpecialView == null &&
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp, selectedZoneLevel) &&
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp[selectedZoneLevel], selectedInnerSubset)
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset]);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset].datasets.forEach((dataset, idx) => {
        if (
          displayedAxis == dataset.displayedAxis ||
          (dataset.displayedAxis == 'Target' &&
            targetsObject.hasOwnProperty(selectedInnerSubset) &&
            displayedAxis != 'Total $' &&
            targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
          // Also add compare by dataset
          if (
            compareByToggle &&
            compareByEfficiencyDataTemp != null &&
            compareByEfficiencyDataTemp.hasOwnProperty(selectedZoneLevel) &&
            compareByEfficiencyDataTemp[selectedZoneLevel].hasOwnProperty(selectedInnerSubset)
          ) {
            compareByEfficiencyDataTemp[selectedZoneLevel][selectedInnerSubset].datasets.forEach((compareByDataset) => {
              if (
                displayedAxis == compareByDataset.displayedAxis ||
                (compareByDataset.displayedAxis == 'Target' &&
                  targetsObject.hasOwnProperty(selectedInnerSubset) &&
                  displayedAxis != 'Total $' &&
                  targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
              ) {
                datasetsIncluded.push(compareByDataset);
              }
            });
          }
        }
      });
      setDatasets(datasetsIncluded);
    } else if (selectedSpecialView != null) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedSpecialView]);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedSpecialView].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.displayedAxis ||
          (dataset.displayedAxis == 'Target' &&
            targetsObject.hasOwnProperty(selectedInnerSubset) &&
            displayedAxis != 'Total $' &&
            targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
          // Also add corresponding compare by dataset
          if (
            compareByToggle &&
            compareByEfficiencyDataTemp != null &&
            compareByEfficiencyDataTemp.hasOwnProperty(selectedSpecialView)
          ) {
            compareByEfficiencyDataTemp[selectedSpecialView].datasets.forEach((compareByDataset) => {
              if (
                displayedAxis == compareByDataset.displayedAxis ||
                (compareByDataset.displayedAxis == 'Target' &&
                  targetsObject.hasOwnProperty(selectedInnerSubset) &&
                  displayedAxis != 'Total $' &&
                  targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
              ) {
                datasetsIncluded.push(compareByDataset);
              }
            });
          }
        }
      });

      setDatasets(datasetsIncluded);
    } else {
      setEfficiencyData(null);
      setDatasets(null);
    }

    // Populate filters
    const tasksListStart = [{value: 'Total', label: 'Total'}];
    const tasks = [];
    let taskIds = operationsData ? Object.keys(operationsData.tasksObject) : [''];
    if (compareByToggle && compareByOperationsData != null) {
      taskIds = [...new Set([...taskIds, ...Object.keys(compareByOperationsData.tasksObject)])];
    }
    taskIds.forEach((taskId) => {
      if (taskId == '') {
        tasksListStart.push({value: taskId, label: 'No Task Found'});
      } else {
        let taskName = '';
        if (typeof taskConfigs[taskId] !== 'undefined') {
          taskName = taskConfigs[taskId].name;
        }
        tasks.push({value: taskId, label: taskName});
      }
    });
    tasks.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    setInnerSubsetOptions(tasksListStart.concat(tasks));
  }

  function handleEfficiencyFilters(selectName, value, selectedZoneLevel = null) {
    if (selectName.includes('efficiencyZoneNames')) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          efficiencyZoneNames: {
            ...values.efficiencyZoneNames,
            [selectedZoneLevel]: value,
          },
        };
      });
    } else {
      setEfficiencyFilters((values) => {
        return {...values, [selectName]: value};
      });
    }
  }

  function efficiencyGraph(dollarRateText = null) {
    const dollarRateTextShow = dollarRateText != null;
    let taskHoverDisplay;
    let taskName = selectedInnerSubset;
    if (typeof taskConfigs[selectedInnerSubset] !== 'undefined') {
      taskName = taskConfigs[selectedInnerSubset].name;
    } else if (
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length == 1 &&
      typeof taskConfigs[selectedInnerSubset[0]] !== 'undefined'
    ) {
      taskName = taskConfigs[selectedInnerSubset[0]].name;
    } else if (selectedInnerSubset.constructor === Array && selectedInnerSubset.length > 1) {
      taskHoverDisplay = selectedInnerSubset
        .map((taskId) => {
          if (taskConfigs.hasOwnProperty(taskId)) {
            return ' ' + taskConfigs[taskId].name + ' ';
          } else {
            return ' No Task Found';
          }
        })
        .toString();
      taskName = 'Multiple Tasks Selected' + ` (${selectedInnerSubset.length})`;
    }

    return (
      <div style={{height: '50vh', minHeight: '400px'}}>
        {/* Chart */}
        {props.pageStates && Array.isArray(selectedInnerSubset) && (
          <Tooltip title={taskHoverDisplay}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '20%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
                opacity: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // Ensure the chart is still interactable
              }}
            ></div>
          </Tooltip>
        )}
        <div style={{width: '100%', height: '100%'}}>
          <BarChart
            data={{
              labels: efficiencyData.labels,
              datasets: datasets,
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      scaleID: scaleId,
                      value:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],
                      enValue:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],

                      borderColor: 'rgb(255, 99, 132)',
                      borderWidth: 2,
                      display:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        typeof targetsObject[selectedInnerSubset][displayedAxis] != 'undefined' &&
                        targetsObject[selectedInnerSubset][displayedAxis] != 0,
                    },
                  },
                },
                subtitle: {
                  display: dollarRateTextShow,
                  text: dollarRateText,
                },
                title: {
                  display: true,
                  text: `Efficiency Baseline${taskName != 'Total' && taskName != '' ? ' By Task' : ''} - ${
                    taskName != '' ? taskName : 'No Task Found'
                  }`,
                  font: {
                    size: customChartProperties.titleFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                legend: {
                  onClick: (e) => {
                    return false;
                  },
                  labels: {
                    font: {
                      size: customChartProperties.legendFontSize,
                    },
                  },
                },
              },
              scales: {
                // Acres scale
                AcHr: {
                  title: {text: 'Ac/Hr', display: !!props?.graphOnly},
                  display: displayedAxis == 'Ac/Hr',
                  type: 'linear',
                  position: 'left',
                },
                HrAc: {
                  title: {text: 'Hr/Ac', display: !!props?.graphOnly},
                  display: displayedAxis == 'Hr/Ac',
                  type: 'linear',
                  position: 'left',
                },
                Acres: {
                  title: {text: 'Total Acres', display: !!props?.graphOnly},
                  display: displayedAxis == 'Acres',
                  type: 'linear',
                  position: 'left',
                },
                // Hectares scale
                HaHr: {
                  title: {text: 'Ha/Hr', display: !!props?.graphOnly},
                  display: displayedAxis == 'Ha/Hr',
                  type: 'linear',
                  position: 'left',
                },
                HrHa: {
                  title: {text: 'Hr/Ha', display: !!props?.graphOnly},
                  display: displayedAxis == 'Hr/Ha',
                  type: 'linear',
                  position: 'left',
                },
                Hectares: {
                  title: {text: 'Total Hectares', display: !!props?.graphOnly},
                  display: displayedAxis == 'Hectares',
                  type: 'linear',
                  position: 'left',
                },
                // Other scales
                Speed: {
                  title: {text: `Avg Speed (${speedUnits})`, display: !!props?.graphOnly},
                  display: displayedAxis == 'Speed',
                  type: 'linear',
                  position: 'left',
                },
                'Total $': {
                  title: {text: 'Total $', display: true},
                  display: displayedAxis == 'Total $',
                  type: 'linear',
                  position: 'left',
                },
                TargetAxis: {
                  title: {text: 'Target', display: false},
                  display: false,
                  type: 'linear',
                  position: 'left',
                },
                x: {
                  title: {text: String(selectedZoneLevel), display: !!props?.graphOnly},
                  ticks: {
                    maxRotation: 90,
                    minRotation: 0,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });
      combined = [
        ...ogVehicles,
        ...Object.keys(compareByOperationsData.vehiclesObject)
          .filter((key) => {
            return !mainSetVehicleSN.includes(key);
          })
          .map((opt) => {
            return {'text': vehicleSNDict[opt].name, 'value': opt};
          }),
      ];
      combined = [...combined].sort((optA, optB) => {
        return optA.text.localeCompare(optB.text);
      });
    }
    return combined;
  }

  function filterControlComponents() {
    // if vehicles are selected, just return a vehicle filter
    if (selectedSpecialView === 'Vehicle') {
      return [
        /* Vehicle Filter */
      <IntelliSearchSelect
        label='Vehicles'
        allNoneEnabled={true}
        key={'dashboard-efficiency-efficiency-vehicle-filter'}
        name='efficiencyVehicles'
        multiple={true}
        search={true}
        value={efficiencyFilters.efficiencyVehicles}
        options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleEfficiencyFilters('efficiencyVehicles', s);
        }}
        placeholder={`Filter by Vehicle Name`}
      />,

      /* Task Filter */
      <IntelliSearchSelect
        key={'dashboard-efficiency-task-filter'}
        size='small'
        label='Tasks'
        displayEmpty
        multiple
        allNoneEnabled={true}
        search
        sx={{borderRadius: '3px'}}
        // Custom styling for selected item
        // Making the selected highlight contrast higher to make it more visible
        MenuProps={{
          sx: {
            '&& .Mui-selected': {
              backgroundColor: '#4e73df',
            },
          },
        }}
        value={selectedInnerSubset.constructor === Array ? selectedInnerSubset : [selectedInnerSubset]}
        onChange={(e) => {
          if (e.length > 1 && e.includes('Total')) {
            // If we did have total selected before and selected something else, remove total
            if (selectedInnerSubset.includes('Total')) {
              const totalIndex = e.indexOf('Total');
              e.splice(totalIndex, 1);
            }
            // If we didn't have total selected before,
            // then we're trying to select total so make the array just total as it cannot be combined with others
            else {
              e = ['Total'];
            }
          }
          if (e.constructor !== Array || e.length > 0) {
            setSelectedInnerSubset(e);
          } else if (e.length == 0) {
            setSelectedInnerSubset(['Total']);
          }
        }}
        options={innerSubsetOptions}
      ></IntelliSearchSelect>,
      ];
    }

    return [
      /* Vehicle Filter */
      <IntelliSearchSelect
        label='Vehicles'
        allNoneEnabled={true}
        key={'dashboard-efficiency-efficiency-vehicle-filter'}
        name='efficiencyVehicles'
        multiple={true}
        search={true}
        value={efficiencyFilters.efficiencyVehicles}
        options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleEfficiencyFilters('efficiencyVehicles', s);
        }}
        placeholder={`Filter by Vehicle Name`}
      />,

      /* Task Filter */
      <IntelliSearchSelect
        key={'dashboard-efficiency-task-filter'}
        size='small'
        label='Tasks'
        displayEmpty
        multiple
        allNoneEnabled={true}
        search
        sx={{borderRadius: '3px'}}
        // Custom styling for selected item
        // Making the selected highlight contrast higher to make it more visible
        MenuProps={{
          sx: {
            '&& .Mui-selected': {
              backgroundColor: '#4e73df',
            },
          },
        }}
        value={selectedInnerSubset.constructor === Array ? selectedInnerSubset : [selectedInnerSubset]}
        onChange={(e) => {
          if (e.length > 1 && e.includes('Total')) {
            // If we did have total selected before and selected something else, remove total
            if (selectedInnerSubset.includes('Total')) {
              const totalIndex = e.indexOf('Total');
              e.splice(totalIndex, 1);
            }
            // If we didn't have total selected before,
            // then we're trying to select total so make the array just total as it cannot be combined with others
            else {
              e = ['Total'];
            }
          }
          if (e.constructor !== Array || e.length > 0) {
            setSelectedInnerSubset(e);
          } else if (e.length == 0) {
            setSelectedInnerSubset(['Total']);
          }
        }}
        options={innerSubsetOptions}
      ></IntelliSearchSelect>,

      /* Zone Name Filter */
      <IntelliSearchSelect
        label={`${selectedZoneLevel}s`}
        allNoneEnabled={true}
        key={'dashboard-efficiency-efficiency-zone-filter'}
        name='efficiencyZoneNames'
        multiple={true}
        search={true}
        // disabled={taskActive || customerSettings.landing.scanAutoStartEnabled}
        value={efficiencyFilters.efficiencyZoneNames[selectedZoneLevel]}
        options={zoneFilterOptions.map((opt) => {
          return {'label': opt, 'value': opt};
        })}
        onChange={(s) => {
          handleEfficiencyFilters('efficiencyZoneNames', s, selectedZoneLevel);
        }}
        placeholder={`Filter by ${selectedZoneLevel} Name`}
      />,

      /* Row Spacing Filter */
      ...(selectedZoneLevel == 'Block'
        ? [
            <IntelliSearchSelect
              label={'Row Spacing'}
              inputLabel={'Row Spacing'}
              allNoneEnabled={true}
              id='dashboard-efficiency-efficiencyZoneRowSpacing-filter'
              key={'dashboard-efficiency-efficiencyZoneRowSpacing-filter' + selectedZoneLevel}
              name='efficiencyRowSpacing'
              multiple={true}
              value={efficiencyFilters.efficiencyRowSpacing}
              options={filterOptions.efficiency.efficiencyRowSpacing.map((opt) => {
                return {'label': opt.text, 'value': opt.value};
              })}
              onChange={(s) => {
                handleEfficiencyFilters('efficiencyRowSpacing', s);
              }}
              placeholder='Filter by Block Row Spacing'
            />,
          ]
        : []),
    ];
  }

  function displayControlComponents() {
    return [
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-efficiency-efficiencyZoneSort-filter'
        key={'dashboard-efficiency-efficiencyZoneSort-filter' + selectedZoneLevel}
        name='efficiencyZoneSort'
        multiple={false}
        value={efficiencyFilters.efficiencyZoneSort}
        options={filterOptions.efficiency.efficiencyZoneSort.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleEfficiencyFilters('efficiencyZoneSort', s);
        }}
        placeholder='Sorting method'
      />,
      /* Zone Count */
      <IntelliSearchSelect
        label={`${selectedSpecialView || selectedZoneLevel} Count`}
        id='dashboard-efficiency-efficiencyZoneCount-filter'
        key={'dashboard-efficiency-efficiencyZoneCount-filter' + selectedZoneLevel}
        name='efficiencyZoneCount'
        multiple={false}
        value={efficiencyFilters.efficiencyZoneCount}
        options={filterOptions.efficiency.efficiencyZoneCount.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleEfficiencyFilters('efficiencyZoneCount', s);
        }}
        placeholder={`Show All ${selectedSpecialView || selectedZoneLevel}`}
      />,
    ];
  }

  function additionalInputComponents() {
    if (!customerSettings.dashboard.efficiencyDollarsCalculatorEnabled) {
      return null;
    }
    return (
      <React.Fragment>
        <FormControl sx={{width: '100%'}}>
          <InputLabel shrink>{unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}</InputLabel>
          <OutlinedInput
            endAdornment={
              <InputAdornment position='end'>{`${unitsAreaSystem == 'acre' ? '$/Ac' : '$/Ha'}`}</InputAdornment>
            }
            notched
            label={`${unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}`}
            size='small'
            onChange={handleAreaDollarRateChange}
            value={areaDollarRate}
          />
        </FormControl>
        <Divider orientation='horizontal'>or</Divider>
        <FormControl sx={{width: '100%'}}>
          <InputLabel shrink>Hour Rate</InputLabel>
          <OutlinedInput
            endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
            notched
            label='Hour Rate'
            size='small'
            onChange={handleHourDollarRateChange}
            value={hourDollarRate}
          />
        </FormControl>
      </React.Fragment>
    );
  }

  function xAxisControlButton() {
    return (
      <FormControl sx={{minWidth: '120px', m: 1}}>
        <InputLabel>View by</InputLabel>
        <Select
          size='small'
          label='View by'
          value={selectedSpecialView == null ? selectedZoneLevel : selectedSpecialView}
          onChange={(e) => {
            if (timeAxes.includes(e.target.value)) {
              setSelectedSpecialView(e.target.value);
              setEfficiencyFilters((values) => {
                return {...values, 'efficiencyZoneSort': 'Chronological'};
              });

              // Other, task, vehicles
            } else if (['Task', 'Vehicle'].includes(e.target.value)) {
              setSelectedSpecialView(e.target.value);
              setEfficiencyFilters((values) => {
                return {...values, 'efficiencyZoneSort': 'Desc'};
              });

              // Region, Field, Block
            } else {
              setSelectedZoneLevel(e.target.value);
              setSelectedSpecialView(null);
              setEfficiencyFilters((values) => {
                return {...values, 'efficiencyZoneSort': 'Desc'};
              });
            }
          }}
        >
          <MenuItem value={'Region'}>{'Region'}</MenuItem>
          <MenuItem value={'Field'}>{'Field'}</MenuItem>
          <MenuItem value={'Block'}>{'Block'}</MenuItem>
          <MenuItem disabled={compareByToggle} value={'Days'}>
            {'Days'}
          </MenuItem>
          <MenuItem disabled={compareByToggle} value={'Weeks'}>
            {'Weeks'}
          </MenuItem>
          <MenuItem disabled={compareByToggle} value={'Months'}>
            {'Months'}
          </MenuItem>
          <MenuItem disabled={compareByToggle} value={'Years'}>
            {'Years'}
          </MenuItem>
          <MenuItem value={'Task'}>{'Task'}</MenuItem>
          <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }
  if (props.graphOnly) {
    const pageStates = props.pageStates;
    let dollarRateText;
    if (pageStates != undefined && pageStates.hasOwnProperty('acreDollarRate') && pageStates.acreDollarRate != '') {
      const dollarPerAreaConverted = `${unitsAreaConversion(
        parseFloat(pageStates.acreDollarRate),
        'ac',
        unitsAreaSystem == 'hectare' ? 'ha' : 'ac'
      ).toFixed(2)}`;
      dollarRateText = `(${dollarPerAreaConverted} per ${unitsAreaSystem == 'hectare' ? 'Hectare' : 'Acre'})`;
    } else if (
      pageStates != undefined &&
      pageStates.hasOwnProperty('hourDollarRate') &&
      pageStates.hourDollarRate != ''
    ) {
      dollarRateText = `($${pageStates.hourDollarRate} per Hour)`;
    }
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!efficiencyData || !datasets ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            efficiencyGraph(dollarRateText)
          )}
        </div>
      </React.Fragment>
    );
  }
  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Farming Efficiency'}
        pageFilters={efficiencyFilters}
        pageStates={{
          selectedInnerSubset: selectedInnerSubset,
          selectedZoneLevel: selectedZoneLevel,
          displayedAxis: displayedAxis,
          scaleId: scaleId,
          hourDollarRate: hourDollarRate,
          acreDollarRate: areaDollarRate
            ? `${unitsAreaConversion(parseFloat(areaDollarRate), unitsAreaSystem == 'hectare' ? 'ha' : 'ac', 'ac')}`
            : '',
          pageTargets: targetsObject,
          selectedSpecialView: selectedSpecialView,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {!efficiencyData || !datasets ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              <StatsViewReportFiltersWrapper key={`EfficiencyFilter${selectedZoneLevel}`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              <StatsViewReportSortByWrapper key={`EfficiencySort${selectedZoneLevel}`}>
                {displayControlComponents()}
              </StatsViewReportSortByWrapper>
            </React.Fragment>
          )}
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
                  {/* Y-Axis Controls */}
                  <Grid
                    item
                    xs={2}
                    lg={1}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        '.MuiInputBase-input': {
                          padding: '5px',
                        },
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        transformOrigin: 'center',
                        transform: 'rotate(270deg)',
                        margin: 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <FormControl sx={{m: 1, display: 'flex'}}>
                        <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                        <Select
                          sx={{padding: '3px'}}
                          size='small'
                          label='Measure by'
                          value={displayedAxis}
                          onChange={(e) => {
                            setDisplayedAxis(e.target.value);
                            setScaleId(e.target.value.replace('/', ''));
                            setAreaDollarRate('');
                            setHourDollarRate('');
                          }}
                        >
                          <MenuItem hidden={unitsAreaSystem == 'hectare'} value={'Ac/Hr'}>{`Ac / Hr`}</MenuItem>
                          <MenuItem hidden={unitsAreaSystem == 'hectare'} value={`Hr/Ac`}>{`Hr / Ac`}</MenuItem>
                          <MenuItem hidden={unitsAreaSystem == 'acre'} value={'Ha/Hr'}>{`Ha / Hr`}</MenuItem>
                          <MenuItem hidden={unitsAreaSystem == 'acre'} value={`Hr/Ha`}>{`Hr / Ha`}</MenuItem>
                          <MenuItem hidden={unitsAreaSystem == 'hectare'} value={'Acres'}>{'In Field Acres'}</MenuItem>
                          <MenuItem hidden={unitsAreaSystem == 'acre'} value={'Hectares'}>{'In Field Hectares'}</MenuItem>
                          <MenuItem value={'Hours'}>{'In Field Hours'}</MenuItem>
                          <MenuItem value={'Speed'}>{`Avg Speed (${speedUnits})`}</MenuItem>
                          <MenuItem value={'Total $'}>{'Cost'}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* Graph */}
                  <Grid item xs={10} lg={9} sx={{textAlign: 'center', marginTop: 'auto'}}>
                    {efficiencyGraph()}
                    {/* X-Axis Control */}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: smallScreen ? 'none' : 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {xAxisControlButton()}
                    </Grid>
                  </Grid>
                  {/* Y-Axis Additional Input IN SMALL SCREEN MODE */}
                  {smallScreen && customerSettings.dashboard.efficiencyDollarsCalculatorEnabled && (
                    <React.Fragment>
                      <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}} ref={additionalInputRef}>
                        <Box
                          sx={{
                            '.MuiInputBase-input': {
                              padding: '5px',
                            },
                            display: 'flex',
                            padding: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: {xs: 'column', md: 'row'},
                            maxWidth: '160px',
                            boxShadow:
                              displayedAxis == 'Total $' && !areaDollarRate && !hourDollarRate
                                ? '0px 3px 10px 0px #ff9966'
                                : '0px 3px 10px 0px #aaaaaa',
                            borderRadius: '4px',
                          }}
                        >
                          {additionalInputComponents()}
                        </Box>
                      </Grid>
                      {/* X-Axis Control IN SMALL SCREEN MODE */}
                      <Grid
                        item
                        xs={6}
                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
                      >
                        {xAxisControlButton()}
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                  additionalInputs={additionalInputComponents()}
                  additionalInputWarningBorder={displayedAxis == 'Total $' && !areaDollarRate && !hourDollarRate}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiEfficiency};
