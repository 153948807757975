import {createSlice} from '@reduxjs/toolkit';

const alertviewSlice = createSlice({
  name: 'alertview',
  initialState: {
    loading: true,
    vehiclesUnfiltered: [],
    vehicleSNDict: {},
    zonesDicts: {
      blocks: {},
      fields: {},
      regions: {},
    },
    geofencesDict: {},
    taskConfigDict: {},
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateVehiclesUnfilteredData(state, action) {
      const vehiclesUnfiltered = action.payload;

      const vehicleSNDict = {};
      vehiclesUnfiltered.forEach((vehicle) => {
        vehicleSNDict[vehicle.serialNumber] = vehicle;
      });

      state.vehiclesUnfiltered = vehiclesUnfiltered;
      state.vehicleSNDict = vehicleSNDict;
    },
    updateZonesData(state, action) {
      const allZoneData = action.payload;

      state.zonesDicts = {
        blocks: allZoneData.blocks,
        fields: allZoneData.fields,
        regions: allZoneData.regions,
      };
    },
    updateGeofences(state, action) {
      state.geofencesDict = action.payload;
    },
    updateTaskConfigDict(state, action) {
      state.taskConfigDict = action.payload;
    },
  },
});

function getAlertsData() {
  return async (dispatch, getState) => {
    dispatch(alertviewSlice.actions.updateLoading(true));

    // const getAlertsDataRequest = fetch('/shopview/getAlertsData', {cache: 'no-store'});

    const getVehiclesUnfilteredRequest = fetch('/getVehiclesUnfiltered', {cache: 'no-store'});
    const getZonesRequest = fetch('/getZonesData?unfiltered=true', {cache: 'no-store'});
    const getTaskConfigsRequest = fetch('/getTaskConfigs', {cache: 'no-store'});

    // Make all calls
    const [getVehiclesUnfilteredResponse, getZonesResponse, getTaskConfigsResponse] = await Promise.all([
      getVehiclesUnfilteredRequest,
      getZonesRequest,
      getTaskConfigsRequest,
    ]);

    const vehiclesUnfiltered = await getVehiclesUnfilteredResponse.json();
    const zones = await getZonesResponse.json();
    const taskConfigIdDict = await getTaskConfigsResponse.json();

    dispatch(alertviewSlice.actions.updateVehiclesUnfilteredData(vehiclesUnfiltered));
    dispatch(alertviewSlice.actions.updateZonesData(zones));
    dispatch(alertviewSlice.actions.updateGeofences(zones.geofences));
    dispatch(alertviewSlice.actions.updateTaskConfigDict(taskConfigIdDict));

    dispatch(alertviewSlice.actions.updateLoading(false));
  };
}

export {getAlertsData};
export default alertviewSlice.reducer;
