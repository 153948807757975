import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {updateSearchText, updateVehicleGroupFilteredId} from './searchBarSlice';
import {DownStatusIcon} from '../DownStatusIcon';
import {IntelliHiddenFilterSelect} from '../IntelliHiddenFilterSelect';

function Topbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const vehiclesGroupFilteredId = useSelector((state) => {
    return state.searchBar.vehiclesGroupFilteredId;
  });
  const vehiclesGroupsDict = useSelector((state) => {
    return state.settings.vehiclesGroups;
  });

  useEffect(() => {
    // Focus on dropdown input when opened
    $('.dropdown').on('shown.bs.dropdown', function (e) {
      $('.dropdown-menu input').focus();
    });
  }, []);

  function renderVehiclesGroupsFilter() {
    return (
      <IntelliHiddenFilterSelect
        id='top-vehiclesgroups-filter'
        name={'top-vehiclesgroups-filter'}
        multiple={true}
        large
        value={vehiclesGroupFilteredId}
        options={Object.keys(vehiclesGroupsDict).map((groupId) => {
          return {text: vehiclesGroupsDict[groupId].name, value: groupId};
        })}
        showFilter={true}
        onChange={(e) => {
          return dispatch(updateVehicleGroupFilteredId(e.target.value));
        }}
      />
    );
  }

  return (
    <nav className='navbar navbar-expand topbar static-top shadow'>
      <button id='sidebarToggleTop' className='btn btn-link d-md-none ml-n2 mr-1' onClick={openSidebar}>
        <FontAwesomeIcon icon='fa fa-bars' style={{color: 'black'}} />
      </button>
      <div className='d-sm-flex align-items-center justify-content-between mr-auto ml-md-2 my-2 my-md-0 mw-100'>
        <h1 className='h3 mb-0 text-gray-800'>{props.text}</h1>
      </div>
      <DownStatusIcon login={false} small={false} />
      {/* Uncomment to enable global vehicles filter */}
      {/* {renderVehiclesGroupsFilter()} */}
      {props.search && (
        <React.Fragment>
          <div
            className='dropdown dropdown-fw dropdown-fw-backdrop'
            style={{display: !displaySettings.smallScreen && 'none'}}
          >
            <button className='btn' data-toggle='dropdown' aria-expanded='false'>
              <FontAwesomeIcon icon='fas fa-search' size='2x' style={{color: searchText && '#4e73df'}} />
            </button>
            <div className='dropdown-menu dropdown-menu-right'>
              <input
                type='search'
                className='dropdown-item border form-control'
                placeholder='Search..'
                value={searchText}
                onChange={(e) => {
                  return dispatch(updateSearchText(e.target.value));
                }}
              />
            </div>
          </div>

          <input
            type='search'
            className='form-control w-25'
            placeholder='Search..'
            value={searchText}
            onChange={(e) => {
              return dispatch(updateSearchText(e.target.value));
            }}
            style={{display: displaySettings.smallScreen && 'none'}}
          />
        </React.Fragment>
      )}

      <div className='dropdown dropdown-backdrop'>
        <button className='btn' id='dropdownMenuButton' data-toggle='dropdown' aria-expanded='false'>
          <FontAwesomeIcon icon='fas fa-user-circle' size='2x' />
        </button>
        <div className='dropdown-menu dropdown-menu-right' aria-labelledby='dropdownMenuButton'>
          <div className='dropdown-header'>
            <div>
              <FontAwesomeIcon icon='fas fa-user-circle' /> {userSettings.username}
            </div>
            <div>
              <FontAwesomeIcon icon='fas fa-database' /> {customerSettings.general.organizationName}
            </div>
          </div>
          <div className='dropdown-divider'></div>
          <Link className='dropdown-item' to='/personal_settings'>
            Personal Settings
          </Link>
          {checkOrganizationSettingsAccess() && (
            <Link className='dropdown-item' to='/organization_settings'>
              Organization Settings
            </Link>
          )}
          <Link className='dropdown-item' to='' style={{color: 'red'}} onClick={logoutClick} id='main-logout-button'>
            Logout
          </Link>
        </div>
      </div>
    </nav>
  );

  // Handle click on logout button
  function logoutClick() {
    dispatch({type: 'RESET'}); // Need to reset the states to clear current cache
    navigate('/login');
    fetch('/logout')
      .then((response) => {
        window.location.href = '/login'; // Use window.location to cause a new page to load
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function openSidebar() {
    props.handleSetSideMenuOpened(true);
  }

  function checkOrganizationSettingsAccess() {
    const organizationSettingsAccess = Object.keys(userSettings.roleViewAccess).some((key) => {
      return userSettings.roleViewAccess[key];
    });

    // Organization settings are only available for accounts with geotab access
    return organizationSettingsAccess;
  }
}

export {Topbar};
