import {createSlice} from '@reduxjs/toolkit';

const vehicleInfoSlice = createSlice({
  name: 'vehicleinfo',
  initialState: {
    loading: true,
    sortMethod: 'vehiclename',
    vehicleData: [],
    displayedTable: 'attributes',
    onlyShowVehiclesWithoutManualEntry: false,
    showShopviewOnlyVehicle: false,
    showArchivedVehicles: false,
    deviceStatuses: {},
    groupModalOpened: false
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateSortMethod(state, action) {
      state.sortMethod = action.payload;
    },
    updateVehicleData(state, action) {
      state.vehicleData = action.payload;
    },
    updateDisplayedTable(state, action) {
      state.displayedTable = action.payload;
    },
    updateOnlyShowVehiclesWithoutManualEntry(state, action) {
      state.onlyShowVehiclesWithoutManualEntry = action.payload;
    },
    updateShowShopviewOnlyVehicle(state, action) {
      state.showShopviewOnlyVehicle = action.payload;
    },
    updateShowArchivedVehicles(state, action) {
      state.showArchivedVehicles = action.payload;
    },
    updateDeviceStatuses(state, action) {
      state.deviceStatuses = action.payload;
    },
    setGroupModalOpened(state, action) {
      state.groupModalOpened = action.payload;
    },
  },
});

export const {
  updateLoading,
  updateSortMethod,
  updateVehicleData,
  updateDisplayedTable,
  updateOnlyShowVehiclesWithoutManualEntry,
  updateShowShopviewOnlyVehicle,
  updateShowArchivedVehicles,
  updateDeviceStatuses,
  setGroupModalOpened,
} = vehicleInfoSlice.actions;
export default vehicleInfoSlice.reducer;
