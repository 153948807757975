import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../../components/Table';
import {CustomTablePagination} from '../../../../components/CustomTablePagination';
import {Tailselect} from '../../../../components/Tailselect';
import {
  searchFind,
  numberWithCommas,
  unitsLengthDisplayConversion,
  machineTypeMapping,
  accessLevels,
  deepCopy,
} from '../../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import {VehicleEditModal} from './VehicleEditModal';
import {VehiclesGroupsModal} from './VehiclesGroupsModal';
import {NewEquipmentModal} from '../../../../components/NewEquipmentModal';
import {TabMenuTableWrapper} from '../../../../components/TabMenuTableWrapper';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  LinearProgress,
  Chip,
  Tooltip,
  IconButton,
} from '@mui/material';
import {updateBulkEditVehicles, setVehiclesBulkEditErrors} from '../../settingsSlice';
import {setGroupModalOpened} from './vehicleInfoSlice';
import {BulkEditInputs} from '../../BulkEditInputs';

function VehicleInfoTable(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.vehicleinfo.loading;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const displayedTable = useSelector((state) => {
    return state.vehicleinfo.displayedTable;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const activeDevices = useSelector((state) => {
    return state.settings.activeDevices;
  });
  const newEquipmentEnabled = useSelector((state) => {
    return state.app.customerSettings.shopview.nonGoDeviceVehiclesEnabled;
  });
  const deviceStatuses = useSelector((state) => {
    return state.vehicleinfo.deviceStatuses;
  });
  const vehiclesBulkEditErrors = useSelector((state) => {
    return state.settings.vehiclesBulkEditErrors;
  });
  const vehiclesGroupsDict = useSelector((state) => {
    return state.settings.vehiclesGroups;
  });
  const vehiclesGroupsByVehicleSN = useSelector((state) => {
    return state.settings.vehiclesGroupsByVehicleSN;
  });
  const groupModalOpened = useSelector((state) => {
    return state.vehicleinfo.groupModalOpened;
  });

  const MAX_ITEMS_PER_PAGE = 100;
  const [useVehWidth, setUseVehWidth] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [unitsLength, setUnitsLength] = useState('km');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [entryVehicle, setEntryVehicle] = useState({
    vehicleName: '',
    vehicleSN: '',
    machineType: 0,
    engineHours: 0,
    odometer: 0,
    dateTime: DateTime.now().setZone(customerSettings.general.timeZone).toISODate(),
    deviceStatus: {},
  });
  const [editVehicle, setEditVehicle] = useState({});
  const [propagateAllColumns, setPropagateAllColumns] = useState({});

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    if (
        typeof editVehicle.vehicleSN !== 'undefined' &&
        typeof vehiclesGroupsByVehicleSN[editVehicle.vehicleSN] !== 'undefined'
    ) {
      const editVehicleCopy = deepCopy(editVehicle);
      editVehicleCopy.vehiclesGroups = vehiclesGroupsByVehicleSN[editVehicleCopy.vehicleSN];
      setEditVehicle(editVehicleCopy);
    }
  }, [vehiclesGroupsByVehicleSN]);

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [
    props.tableData,
    props.filters,
    searchText,
    activeDevices,
    implementSNDict,
    taskConfigDict,
    displayedTable,
    deviceStatuses,
  ]);

  useEffect(() => {
    // Update if the vehWidth column should be displayed
    const result = tableData.filter((rowData) => {
      return rowData.width && rowData.width > 0;
    });

    if (result.length > 0) {
      setUseVehWidth(true);
    } else {
      setUseVehWidth(false);
    }
  }, [tableData]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    props.setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function handleEditModalOpen(openState) {
    setEditModalOpen(openState);
  }

  function handleUpdateData() {
    props.getVehicleDataForSettings();
  }

  function handleUpdateAsyncData() {
    props.getVehicleAsyncData();
  }

  function toggleGroupModalOpened() {
    dispatch(setGroupModalOpened(!groupModalOpened));
  }

  function handleBulkEditTextFields(entityIdName, entityId, propertyName, propertyValue) {
    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = tableData.map((vehicle) => {
        return vehicle.serialNumber;
      });
    }
    updateRowsId.forEach((vehicleSN) => {
      dispatch(
        updateBulkEditVehicles({
          [entityIdName]: vehicleSN,
          data: {
            [propertyName]: propertyValue,
          },
        })
      );
    });
  }

  function handleBulkEditImplements(entityIdName, entityId, propertyName, propertyValue) {
    const vehiclesBulkEditErrorsCopy = deepCopy(vehiclesBulkEditErrors);

    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = tableData.map((vehicle) => {
        return vehicle.serialNumber;
      });
    }

    const newData = {
      [propertyName]: propertyValue,
      permanentTaskImpExpiryDate: '',
    };
    let errorCase = false;
    let errorMsg;

    if (
      propertyValue != '' &&
      (!implementSNDict[propertyValue]?.linkedTaskId || implementSNDict[propertyValue].linkedTaskId == '')
    ) {
      newData.linkedTaskId = '';
      errorCase = true;
      errorMsg =
        'Implements Assigned Does not have a Task Configured, please Select another Implements or assign a Task to the Implement.';
    } else {
      newData.linkedTaskId = implementSNDict[propertyValue]?.linkedTaskId || '';
    }

    updateRowsId.forEach((vehicleSN) => {
      if (errorCase) {
        if (!vehiclesBulkEditErrorsCopy.hasOwnProperty(vehicleSN)) {
          vehiclesBulkEditErrorsCopy[vehicleSN] = {};
        }
        vehiclesBulkEditErrorsCopy[vehicleSN][propertyName] = errorMsg;
      } else {
        if (vehiclesBulkEditErrorsCopy.hasOwnProperty(vehicleSN)) {
          delete vehiclesBulkEditErrorsCopy[vehicleSN][propertyName];
          if (Object.keys(vehiclesBulkEditErrorsCopy[vehicleSN]).length == 0) {
            delete vehiclesBulkEditErrorsCopy[vehicleSN];
          }
        }
      }
      dispatch(
        updateBulkEditVehicles({
          [entityIdName]: vehicleSN,
          data: newData,
        })
      );
    });

    dispatch(setVehiclesBulkEditErrors(vehiclesBulkEditErrorsCopy));
  }

  function handleBulkEditTasks(entityIdName, entityId, propertyName, propertyValue) {
    const vehiclesBulkEditErrorsCopy = deepCopy(vehiclesBulkEditErrors);

    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = tableData.map((vehicle) => {
        return vehicle.serialNumber;
      });
    }
    updateRowsId.forEach((vehicleSN) => {
      const vehicleHasError =
        vehiclesBulkEditErrorsCopy.hasOwnProperty(vehicleSN) &&
        vehiclesBulkEditErrorsCopy[vehicleSN].hasOwnProperty('linkedImplementSN');
      if (vehicleHasError) {
        delete vehiclesBulkEditErrorsCopy[vehicleSN]['linkedImplementSN'];
        if (Object.keys(vehiclesBulkEditErrorsCopy[vehicleSN]).length == 0) {
          delete vehiclesBulkEditErrorsCopy[vehicleSN];
        }
      }
      dispatch(
        updateBulkEditVehicles({
          [entityIdName]: vehicleSN,
          data: {
            [propertyName]: propertyValue,
            linkedImplementSN: '',
            permanentTaskImpExpiryDate: '',
          },
        })
      );
    });

    dispatch(setVehiclesBulkEditErrors(vehiclesBulkEditErrorsCopy));
  }

  function handleToggleBulkEditPropagateColumn(propertyName) {
    setPropagateAllColumns((value) => {
      return {
        ...value,
        [propertyName]: value.hasOwnProperty(propertyName) ? !value[propertyName] : true,
      };
    });
  }

  function renderTaskOptions(assignedTaskId) {
    const options = [];

    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        options.push({
          value: taskId,
          label: taskConfigDict[taskId].name,
        });
      } else if (taskId == assignedTaskId) {
        options.push({
          value: taskId,
          label: `${taskConfigDict[taskId].name} - Archived`,
        });
      }
    });
    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    options.unshift({
      value: '',
      label: '----',
    });
    options.sort;

    return options;
  }

  function renderImplementOptions(assignedImplementSN) {
    const options = [];

    Object.keys(implementSNDict).forEach((implementSN) => {
      if (
        !implementSNDict[implementSN].archived &&
        implementSNDict[implementSN].hasOwnProperty('linkedTaskId') &&
        implementSNDict[implementSN].linkedTaskId != ''
      ) {
        options.push({
          value: implementSN,
          label: implementSNDict[implementSN].name,
        });
      } else if (implementSN == assignedImplementSN) {
        options.push({
          value: implementSN,
          label: `${implementSNDict[implementSN].name} - Archived`,
        });
      }
    });

    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    options.unshift({
      value: '',
      label: '----',
    });
    return options;
  }

  function renderMachineTypeOptions() {
    const options = [];

    Object.keys(machineTypeMapping).forEach((machineType) => {
      options.push({
        value: parseInt(machineType),
        label: machineTypeMapping[machineType],
      });
    });

    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    return options;
  }

  function renderEditButton(editVehicleData, entryVehicleData) {
    return (
      <IconButton
        size='small'
        sx={{color: '#3a3b45'}}
        onClick={() => {
          // CLICK TO EDIT
          setEditModalOpen(true);
          setEntryVehicle(entryVehicleData);
          setEditVehicle(editVehicleData);
        }}
      >
        <FontAwesomeIcon icon='fa fa-edit' />
      </IconButton>
    );
  }

  async function filterTableRows(tableData) {
    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Promise.all is used to avoid desync between states update and rerendering due to slow filter on large array

    // Generate an array based on rowFilter function (return true or false) base on filters
    const result = tableData.filter((rowData) => {
      return rowFilter(rowData);
    });

    // Update tableData State to render partial rows of data from pagination
    if (page > parseInt(result.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(result);
  }

  function rowFilter(rowData) {
    const rowApplicationNotSet = typeof rowData?.rowApplicationType == 'undefined';
    let vehicleInFilter = true;
    let row = {};
    const machineType = rowData?.machineType ? rowData?.machineType : 0;
    const gpsDevice =
      rowData?.geotabDevice?.serialNumber && rowData?.geotabDevice?.serialNumber != '000-000-0000'
        ? rowData?.geotabDevice?.serialNumber
        : '';
    row = Object.assign(
      {},
      {
        name: rowData.name,
        machineType: machineTypeMapping[machineType],
        linkedImplement: implementSNDict[rowData?.linkedImplementSN]?.name,
        linkedTask: taskConfigDict[rowData?.linkedTaskId]?.name,
        gpsDevice: gpsDevice,
      }
    );
    const search = searchFind(<div>{JSON.stringify(row)}</div>, searchText.toLowerCase().trim());

    const vehicleNameInFilter =
      props.filters.vehicleName.includes(rowData.name) || props.filters.vehicleName.length == 0;
    const vinInFilter = props.filters.vin.includes(rowData.vin) || props.filters.vin.length == 0;
    const makeInFilter = props.filters.make.includes(rowData.make) || props.filters.make.length == 0;
    const modelInFilter = props.filters.model.includes(rowData.model) || props.filters.model.length == 0;
    const notesInFilter = props.filters.notes.includes(rowData.notes) || props.filters.notes.length == 0;
    const vehicleMachineTypeInFilter =
      props.filters.machineType.includes(machineTypeMapping[machineType]) || props.filters.machineType.length == 0;
    const vehicleGpsDeviceInFilter = props.filters.gpsDevice.includes(gpsDevice) || props.filters.gpsDevice.length == 0;
    const linkedImplementInFilter =
      props.filters.linkedImplement.includes(rowData.linkedImplementSN) ||
      props.filters.linkedImplement.length == 0 ||
      (props.filters.linkedImplement.includes('') && !rowData?.linkedImplementSN);
    // if vehicle does not have linkedImplementSN property, it also show up when filter for None

    const linkedTaskInFilter =
      props.filters.linkedTask.includes(rowData.linkedTaskId) ||
      props.filters.linkedTask.length == 0 ||
      (props.filters.linkedTask.includes('') && !rowData?.linkedTaskId);
    // if vehicle does not have linkedTaskId property, it also show up when filter for None

    // if vehicle does not have the corresponding 'xxxxActive' property, it also show up when filter for No
    const vehicleActiveStatuses = {
      'ic': rowData?.icActive == true ? 'Yes' : 'No',
      'cabview': rowData?.cabviewActive == true ? 'Yes' : 'No',
      'shop': rowData?.shopActive == true ? 'Yes' : 'No',
      'coverage': rowData?.coverageActive == true ? 'Yes' : 'No',
      'inspection': rowData?.inspectionActive == true ? 'Yes' : 'No',
    };
    const icActiveInFilter =
      props.filters.vehicleInfoIcActive.includes(vehicleActiveStatuses['ic']) ||
      props.filters.vehicleInfoIcActive.length == 0;

    const cabviewActiveInFilter =
      props.filters.vehicleInfoCabviewActive.includes(vehicleActiveStatuses['cabview']) ||
      props.filters.vehicleInfoCabviewActive.length == 0;

    const shopActiveInFilter =
      props.filters.vehicleInfoShopActive.includes(vehicleActiveStatuses['shop']) ||
      props.filters.vehicleInfoShopActive.length == 0;

    const coverageInFilter =
      props.filters.vehicleInfoCoverageActive.includes(vehicleActiveStatuses['coverage']) ||
      props.filters.vehicleInfoCoverageActive.length == 0;

    const inspectionInFilter =
      props.filters.vehicleInfoInspectionActive.includes(vehicleActiveStatuses['inspection']) ||
      props.filters.vehicleInfoInspectionActive.length == 0;

    const vehicleIncludedGroups = vehiclesGroupsByVehicleSN[rowData.serialNumber] || [];
    const vehicleGroupInFilter =
      vehicleIncludedGroups.some((groupId) => {
        return props.filters.vehiclesGroups.includes(groupId);
      }) || props.filters.vehiclesGroups.length == 0;

    // Use Filters from all Tabs to improve User experience navigating different tabs
    vehicleInFilter =
      vehicleNameInFilter &&
      vehicleMachineTypeInFilter &&
      vehicleGpsDeviceInFilter &&
      linkedImplementInFilter &&
      linkedTaskInFilter &&
      vinInFilter &&
      makeInFilter &&
      modelInFilter &&
      notesInFilter &&
      icActiveInFilter &&
      cabviewActiveInFilter &&
      shopActiveInFilter &&
      coverageInFilter &&
      vehicleGroupInFilter &&
      inspectionInFilter;

    return vehicleInFilter && search;
  }

  function generateTableHeaders() {
    if (displayedTable == 'status') {
      if (smallScreen) {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '90%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '10%'}}>{/* Table Column for Edit Button */}</TableCell>
            </TableRow>
          </TableHead>
        );
      } else {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper title={'GPS Device'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-gpsDevice-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.gpsDevice.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-gpsDevice-select'
                    name={'gpsDevice'}
                    multiple={true}
                    search={true}
                    value={props.filters['gpsDevice']}
                    options={props.filterOptions['gpsDevice']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={smallScreen ? 'Eng Hrs' : 'Engine Hours'}
                ></TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-enginehours-select'
                    name={'engineHours'}
                    multiple={true}
                    search={true}
                    value={props.filters['engineHours']}
                    options={props.filterOptions['engineHours']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={smallScreen ? 'Odo' : 'Odometer'}
                ></TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-odometer-select'
                    name={'odometer'}
                    multiple={true}
                    search={true}
                    value={props.filters['odometer']}
                    options={props.filterOptions['odometer']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '20%'}}>Last Communication</TableCell>
              {!props.bulkEditMode && <TableCell sx={{width: '10%'}}></TableCell>}
            </TableRow>
          </TableHead>
        );
      }
    }
    if (displayedTable == 'attributes') {
      if (smallScreen) {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '90%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '10%'}}>{/* Table Column for Edit Button */}</TableCell>
            </TableRow>
          </TableHead>
        );
      } else {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              {!props.bulkEditMode && (
                <TableCell sx={{width: '20%'}}>
                  <TableHeadFilterButtonTitleWrapper title={'GPS Device'}>
                    <button
                      className='btn btn-transparent btn-sm'
                      onClick={() => {
                        return tail.select('#vehicleinfo-gpsDevice-select').toggle();
                      }}
                    >
                      <FontAwesomeIcon
                        icon='fas fa-filter'
                        style={{color: props.filters.gpsDevice.length > 0 && '#4e73df'}}
                      />
                    </button>
                  </TableHeadFilterButtonTitleWrapper>
                  <TableHeadHiddenDropdownWrapper>
                    <Tailselect
                      id='vehicleinfo-gpsDevice-select'
                      name={'gpsDevice'}
                      multiple={true}
                      search={true}
                      value={props.filters['gpsDevice']}
                      options={props.filterOptions['gpsDevice']}
                      onChange={handleFilters}
                    />
                  </TableHeadHiddenDropdownWrapper>
                </TableCell>
              )}

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'VIN'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'vin'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vin-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-filter' style={{color: props.filters.vin.length > 0 && '#4e73df'}} />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vin-select'
                    name={'vin'}
                    multiple={true}
                    search={true}
                    value={props.filters['vin']}
                    options={props.filterOptions['vin']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Make'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'make'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-make-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-filter' style={{color: props.filters.make.length > 0 && '#4e73df'}} />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-make-select'
                    name={'make'}
                    multiple={true}
                    search={true}
                    value={props.filters['make']}
                    options={props.filterOptions['make']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Model'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'model'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-model-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.model.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-model-select'
                    name={'model'}
                    multiple={true}
                    search={true}
                    value={props.filters['model']}
                    options={props.filterOptions['model']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              {useVehWidth && <TableCell sx={{width: '14%'}}>Width</TableCell>}

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Type'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'machineType'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-type-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.machineType.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-type-select'
                    name={'machineType'}
                    multiple={true}
                    search={true}
                    value={props.filters['machineType']}
                    options={props.filterOptions['machineType']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Permanent Task'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'linkedTaskId'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-linkedtaskid-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.linkedTask.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-linkedtaskid-select'
                    name={'linkedTask'}
                    multiple={true}
                    search={true}
                    value={props.filters['linkedTask']}
                    options={props.filterOptions['linkedTask']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Permanent Implement'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'linkedImplementSN'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-linkedimplementid-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.linkedImplement.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-linkedimplementid-select'
                    name={'linkedImplement'}
                    multiple={true}
                    search={true}
                    value={props.filters['linkedImplement']}
                    options={props.filterOptions['linkedImplement']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              {props.bulkEditMode && (
                <TableCell>
                  <TableHeadFilterButtonTitleWrapper
                    title={'Expiry Date'}
                    bulkEditMode={props.bulkEditMode}
                    propagateAllColumns={propagateAllColumns}
                    handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                    bulkEditPropertyName={'permanentTaskImpExpiryDate'}
                  ></TableHeadFilterButtonTitleWrapper>
                </TableCell>
              )}

              <TableCell sx={{width: '14%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Notes'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-notes-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.notes.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-notes-select'
                    name={'notes'}
                    multiple={true}
                    search={true}
                    value={props.filters['notes']}
                    options={props.filterOptions['notes']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              {!props.bulkEditMode && <TableCell sx={{width: '10%'}}>{/* Table Column for Edit Button */}</TableCell>}
            </TableRow>
          </TableHead>
        );
      }
    }
    if (displayedTable == 'features') {
      if (smallScreen) {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '100%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '5%'}}>{/* Table Column for Edit Button */}</TableCell>
            </TableRow>
          </TableHead>
        );
      } else {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '15%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '15%'}}>
                <TableHeadFilterButtonTitleWrapper title={'GPS Device'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-gpsDevice-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.gpsDevice.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-gpsDevice-select'
                    name={'gpsDevice'}
                    multiple={true}
                    search={true}
                    value={props.filters['gpsDevice']}
                    options={props.filterOptions['gpsDevice']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '5%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'IC'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'icActive'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-icactive-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleInfoIcActive.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-icactive-select'
                    name={'vehicleInfoIcActive'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleInfoIcActive']}
                    options={props.filterOptions['vehicleInfoIcActive']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '5%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'CabView'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'cabviewActive'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-cabviewactive-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleInfoCabviewActive.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-cabviewactive-select'
                    name={'vehicleInfoCabviewActive'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleInfoCabviewActive']}
                    options={props.filterOptions['vehicleInfoCabviewActive']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '5%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Coverage'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'coverageActive'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-coverageactive-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleInfoCoverageActive.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-coverageactive-select'
                    name={'vehicleInfoCoverageActive'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleInfoCoverageActive']}
                    options={props.filterOptions['vehicleInfoCoverageActive']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '5%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'ShopView'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'shopActive'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-shopactive-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleInfoShopActive.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-shopactive-select'
                    name={'vehicleInfoShopActive'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleInfoShopActive']}
                    options={props.filterOptions['vehicleInfoShopActive']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>

              <TableCell sx={{width: '5%'}}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Inspection'}
                  bulkEditMode={props.bulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'inspectionActive'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-inspectionactive-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleInfoInspectionActive.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-inspectionactive-select'
                    name={'vehicleInfoInspectionActive'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleInfoInspectionActive']}
                    options={props.filterOptions['vehicleInfoInspectionActive']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              {!props.bulkEditMode && <TableCell sx={{width: '5%'}}>{/* Table Column for Edit Button */}</TableCell>}
            </TableRow>
          </TableHead>
        );
      }
    }
    if (displayedTable == 'groups') {
      if (smallScreen) {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '90%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '10%'}}>{/* Table Column for Edit Button */}</TableCell>
            </TableRow>
          </TableHead>
        );
      } else {
        return (
          <TableHead>
            <TableRow>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclename-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehicleName.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclename-select'
                    name={'vehicleName'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehicleName']}
                    options={props.filterOptions['vehicleName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '20%'}}>
                <TableHeadFilterButtonTitleWrapper title={'GPS Device'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-gpsDevice-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.gpsDevice.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-gpsDevice-select'
                    name={'gpsDevice'}
                    multiple={true}
                    search={true}
                    value={props.filters['gpsDevice']}
                    options={props.filterOptions['gpsDevice']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={{width: '50%'}}>
                <TableHeadFilterButtonTitleWrapper title={'Groups'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#vehicleinfo-vehiclesGroups').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: props.filters.vehiclesGroups?.length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='vehicleinfo-vehiclesGroups'
                    key='vehicleinfo-vehiclesGroups'
                    name={'vehiclesGroups'}
                    multiple={true}
                    search={true}
                    value={props.filters['vehiclesGroups']}
                    options={props.filterOptions['vehiclesGroups']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              {!props.bulkEditMode && <TableCell sx={{width: '10%'}}></TableCell>}
            </TableRow>
          </TableHead>
        );
      }
    }
  }

  function generateTableBody() {
    let tableBody;
    let colorFlip = false;
    const nowDate = DateTime.local({zone: customerSettings.general.timeZone});
    const sevenDaysFromNow = nowDate.plus({days: 7});

    if (loading) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100}>
            <CircularProgress className='mt-4 mx-auto d-block' size={80} />
          </TableCell>
        </TableRow>
      );

      return tableBody;
    }

    if (tableData.length == 0) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100} sx={{textAlign: 'center', fontSize: '25px'}}>
            No Vehicles Found
          </TableCell>
        </TableRow>
      );

      return tableBody;
    }

    if (displayedTable == 'status') {
      tableBody = (
        <React.Fragment>
          {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map(
            (rowData, index) => {
              colorFlip = !colorFlip;
              const geotabDeviceId = rowData?.geotabDevice?.id != undefined ? rowData?.geotabDevice?.id : '';
              const geotabDeviceSerial =
                rowData?.geotabDevice?.serialNumber != undefined &&
                rowData?.geotabDevice?.serialNumber != '000-000-0000'
                  ? rowData?.geotabDevice?.serialNumber
                  : '';

              const expiryDateString = rowData.permanentTaskImpExpiryDate ? rowData.permanentTaskImpExpiryDate : '';

              // Try to find beWhereSerial from the geotab device comment
              let beWhereSerial = undefined;
              if (geotabDeviceId) {
                // BeWhere Serial in lower case
                const bewhereSerialRegex = /btsae[0-9]{14}/g;
                const geotabComment = activeDevices.geotab[geotabDeviceId]?.comment;
                if (geotabComment) {
                  // convert comment to all lowercase to avoid missing serial due to case sensitivity
                  const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
                  if (beWhereSerialFound) {
                    // convert matched serial to uppercase for display
                    beWhereSerial = beWhereSerialFound[0].toUpperCase();
                  }
                }
              }

              const editVehicleData = {
                name: rowData.name,
                vehicleSN: rowData.serialNumber,
                icActive: rowData.icActive,
                cabviewActive: rowData.cabviewActive,
                coverageActive: rowData.coverageActive,
                shopActive: rowData.shopActive,
                inspectionActive: rowData.inspectionActive,
                archived: rowData.archived,
                vin: rowData.vin,
                make: rowData.make,
                model: rowData.model,
                width: rowData.width,
                machineType: rowData.machineType,
                rowApplicationType: rowData.rowApplicationType,
                linkedTaskId: rowData.linkedTaskId,
                linkedImplementSN: rowData.linkedImplementSN,
                notes: rowData.notes,
                geotabDeviceId: geotabDeviceId,
                geotabDeviceSerial: geotabDeviceSerial,
                swapDevice: false,
                beWhereSerial: beWhereSerial,
                permanentTaskImpExpiryDate: expiryDateString,
                btHubId: rowData.btHubId,
                btHubIdCurr: rowData.btHubId,
              };

              if (rowData.linkedImplementSN != '') {
                if (Object.prototype.hasOwnProperty.call(implementSNDict, rowData.linkedImplementSN)) {
                  editVehicleData.linkedTaskId = implementSNDict[rowData.linkedImplementSN].linkedTaskId;
                }
              }
              const deviceStatus = deviceStatuses[rowData.serialNumber];

              const entryVehicleData = {
                ...entryVehicle,
                vehicleName: rowData.name,
                vehicleSN: rowData.serialNumber,
                machineType: rowData.machineType,
                engineHours: Math.round(parseFloat(rowData.engineHoursValue) * 100) / 100,
                odometer:
                  Math.round(parseFloat(unitsLengthDisplayConversion(rowData.odometerValue, unitsLength)) * 100) / 100,
                deviceStatus: deviceStatus,
              };
              const vehicleHasActiveGeotabDevice = rowData?.geotabDevice?.id in activeDevices.geotab;

              const row = smallScreen ? (
                <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                  <TableCell>
                    <div style={{textAlign: 'center'}}>
                      <strong>{rowData.name}</strong>
                    </div>
                    <div>
                      <div>
                        <div>
                          <strong>GPS Device: </strong>
                          {vehicleHasActiveGeotabDevice ? (
                            <React.Fragment>
                              {geotabDeviceSerial != undefined &&
                              geotabDeviceSerial != '' &&
                              geotabDeviceSerial != '000-000-0000'
                                ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                                : 'Not Installed'}
                            </React.Fragment>
                          ) : (
                            'Not Installed'
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <strong>Engine Hours: </strong>
                      {rowData.machineType == 3
                        ? '---' // Trailer type
                        : `${numberWithCommas(Math.round(rowData.engineHoursValue))} Hours`}
                    </div>
                    <div>
                      <strong>Odometer: </strong>
                      {rowData.machineType == 3
                        ? '---' // Trailer type
                        : `${numberWithCommas(
                            unitsLengthDisplayConversion(rowData.odometerValue, unitsLength).toFixed(0)
                          )} ${unitsLength}`}
                    </div>
                    {vehicleHasActiveGeotabDevice &&
                      geotabDeviceSerial != undefined &&
                      geotabDeviceSerial != '' &&
                      geotabDeviceSerial != '000-000-0000' &&
                      accessLevels[userSettings.role] >=
                        accessLevels[customerSettings.general.vehicleStatusVisibility] && (
                        <Box component='div'>
                          <div className='d-flex col-12 align-items-center px-0'>
                            <div className='d-flex col-sm-4 col-8 px-0'>
                              <strong>Cellular Network: </strong>
                            </div>
                            <div className='d-flex'>
                              {deviceStatus.cell == null ? (
                                <CircularProgress size={10} />
                              ) : (
                                <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.cell} />
                              )}
                            </div>
                          </div>
                          <div className='d-flex col-12 align-items-center px-0'>
                            <div className='d-flex col-sm-4 col-8 px-0'>
                              <strong>GPS Network: </strong>
                            </div>
                            <div className='d-flex'>
                              {deviceStatus.gps == null ? (
                                <CircularProgress size={10} />
                              ) : (
                                <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.gps} />
                              )}
                            </div>
                          </div>
                        </Box>
                      )}
                  </TableCell>
                  <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                  <TableCell>
                    <div className='font-weight-bold'>{rowData.name}</div>
                  </TableCell>
                  <TableCell>
                    <div>
                      {vehicleHasActiveGeotabDevice ? (
                        <React.Fragment>
                          {geotabDeviceSerial != undefined &&
                          geotabDeviceSerial != '' &&
                          geotabDeviceSerial != '000-000-0000'
                            ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                            : 'Not Installed'}
                        </React.Fragment>
                      ) : (
                        'Not Installed'
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      {rowData.machineType == 3 ? (
                        '---' // Trailer type
                      ) : // TODO: Remove `|| true` which disable this input temporary, revisit in V2
                      !props.bulkEditMode || true ? (
                        <span className={`${!rowData.lastKnownExists ? 'text-danger font-weight-bold' : ''}`}>
                          {numberWithCommas(Math.round(rowData.engineHoursValue))} Hours
                        </span>
                      ) : (
                        <BulkEditInputs
                          type='number'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditVehicles'
                          entityId={rowData.serialNumber}
                          entityIdName='vehicleSN'
                          defaultValue={Math.round(rowData.engineHoursValue)}
                          propertyName='engineHoursValue'
                          InputProps={{inputProps: {step: 1, min: 0}}}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      {rowData.machineType == 3 ? (
                        '---' // Trailer type
                      ) : // TODO: Remove `|| true` which disable this input temporary, revisit in V2
                      !props.bulkEditMode || true ? (
                        `${numberWithCommas(
                          unitsLengthDisplayConversion(rowData.odometerValue, unitsLength).toFixed(0)
                        )} ${unitsLength}`
                      ) : (
                        <BulkEditInputs
                          type='number'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditVehicles'
                          entityId={rowData.serialNumber}
                          entityIdName='vehicleSN'
                          defaultValue={unitsLengthDisplayConversion(rowData.odometerValue, unitsLength)}
                          propertyName='odometerValue'
                          InputProps={{inputProps: {step: 1, min: 0}}}
                        />
                      )}
                    </div>
                  </TableCell>
                  {!smallScreen && (
                    <TableCell>
                      {vehicleHasActiveGeotabDevice &&
                        geotabDeviceSerial != undefined &&
                        geotabDeviceSerial != '' &&
                        geotabDeviceSerial != '000-000-0000' && (
                          <React.Fragment>
                            {deviceStatus.lastCommunicatedTimeLocaleStr == null ? (
                              <LinearProgress />
                            ) : (
                              deviceStatus.lastCommunicatedTimeLocaleStr
                            )}
                          </React.Fragment>
                        )}
                      {vehicleHasActiveGeotabDevice &&
                        geotabDeviceSerial != undefined &&
                        geotabDeviceSerial != '' &&
                        geotabDeviceSerial != '000-000-0000' &&
                        accessLevels[userSettings.role] >=
                          accessLevels[customerSettings.general.vehicleStatusVisibility] && (
                          <React.Fragment>
                            <Box component='div'>
                              <div className='d-flex col-12 align-items-center px-0'>
                                <div className='d-flex col-8 px-0'>
                                  <strong>Cellular Network: </strong>
                                </div>
                                <div className='d-flex'>
                                  {deviceStatus.cell == null ? (
                                    <CircularProgress size={10} />
                                  ) : (
                                    <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.cell} />
                                  )}
                                </div>
                              </div>
                              <div className='d-flex col-12 align-items-center px-0'>
                                <div className='d-flex col-8 px-0'>
                                  <strong>GPS Network: </strong>
                                </div>
                                <div className='d-flex'>
                                  {deviceStatus.gps == null ? (
                                    <CircularProgress size={10} />
                                  ) : (
                                    <FontAwesomeIcon icon='fa-solid fa-square' color={deviceStatus.gps} />
                                  )}
                                </div>
                              </div>
                            </Box>
                          </React.Fragment>
                        )}
                    </TableCell>
                  )}
                  {!props.bulkEditMode && <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>}
                </TableRow>
              );
              return row;
            }
          )}
        </React.Fragment>
      );
    }

    if (displayedTable == 'attributes' || displayedTable == 'features') {
      tableBody = (
        <React.Fragment>
          {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map(
            (rowData, index) => {
              colorFlip = !colorFlip;
              const geotabDeviceId = rowData?.geotabDevice?.id != undefined ? rowData?.geotabDevice?.id : '';
              const geotabDeviceSerial =
                rowData?.geotabDevice?.serialNumber != undefined &&
                rowData?.geotabDevice?.serialNumber != '000-000-0000'
                  ? rowData?.geotabDevice?.serialNumber
                  : '';

              let permTaskImpExpired = false;
              let permTaskImpExpiryUpcoming = false;
              const expiryDateString = rowData.permanentTaskImpExpiryDate ? rowData.permanentTaskImpExpiryDate : '';
              if (rowData.permanentTaskImpExpiryDate && rowData.permanentTaskImpExpiryDate != '') {
                const expiryDate = DateTime.fromISO(rowData.permanentTaskImpExpiryDate).setZone(
                  customerSettings.general.timeZone,
                  {keepLocalTime: true}
                );
                if (nowDate > expiryDate) {
                  permTaskImpExpired = true;
                } else if (sevenDaysFromNow > expiryDate) {
                  permTaskImpExpiryUpcoming = true;
                }
              }

              // Try to find beWhereSerial from the geotab device comment
              let beWhereSerial = undefined;
              if (geotabDeviceId) {
                // BeWhere Serial in lower case
                const bewhereSerialRegex = /btsae[0-9]{14}/g;
                const geotabComment = activeDevices.geotab[geotabDeviceId]?.comment;
                if (geotabComment) {
                  // convert comment to all lowercase to avoid missing serial due to case sensitivity
                  const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
                  if (beWhereSerialFound) {
                    // convert matched serial to uppercase for display
                    beWhereSerial = beWhereSerialFound[0].toUpperCase();
                  }
                }
              }

              const editVehicleData = {
                name: rowData.name,
                vehicleSN: rowData.serialNumber,
                icActive: rowData.icActive,
                cabviewActive: rowData.cabviewActive,
                coverageActive: rowData.coverageActive,
                shopActive: rowData.shopActive,
                inspectionActive: rowData.inspectionActive,
                archived: rowData.archived,
                vin: rowData.vin,
                make: rowData.make,
                model: rowData.model,
                width: rowData.width,
                machineType: rowData.machineType,
                rowApplicationType: rowData.rowApplicationType,
                linkedTaskId: rowData.linkedTaskId || '',
                linkedImplementSN: rowData.linkedImplementSN || '',
                notes: rowData.notes,
                geotabDeviceId: geotabDeviceId,
                geotabDeviceSerial: geotabDeviceSerial,
                swapDevice: false,
                beWhereSerial: beWhereSerial,
                permanentTaskImpExpiryDate: expiryDateString,
                btHubId: rowData.btHubId,
                btHubIdCurr: rowData.btHubId,
              };

              let permTaskDisp = 'None';
              let permImpDisp = 'None';

              if (rowData.linkedImplementSN != '') {
                if (Object.prototype.hasOwnProperty.call(implementSNDict, rowData.linkedImplementSN)) {
                  editVehicleData.linkedTaskId = implementSNDict[rowData.linkedImplementSN].linkedTaskId;
                  const impObj = implementSNDict[rowData.linkedImplementSN];
                  permImpDisp = impObj.name + (impObj?.archived ? ' - Archived' : '');

                  if (
                    Object.prototype.hasOwnProperty.call(
                      taskConfigDict,
                      implementSNDict[rowData.linkedImplementSN].linkedTaskId
                    )
                  ) {
                    const taskObj = taskConfigDict[implementSNDict[rowData.linkedImplementSN].linkedTaskId];
                    permTaskDisp = taskObj.name + (taskObj?.archived ? ' - Archived' : '');
                  }
                }
              }

              if (
                permTaskDisp == 'None' &&
                rowData.linkedTaskId != '' &&
                Object.prototype.hasOwnProperty.call(taskConfigDict, rowData.linkedTaskId)
              ) {
                const taskObj = taskConfigDict[rowData.linkedTaskId];
                permTaskDisp = taskObj.name + (taskObj?.archived ? ' - Archived' : '');
              }

              const deviceStatus = deviceStatuses[rowData.serialNumber];

              const entryVehicleData = {
                ...entryVehicle,
                vehicleName: rowData.name,
                vehicleSN: rowData.serialNumber,
                machineType: rowData.machineType,
                engineHours: Math.round(parseFloat(rowData.engineHoursValue) * 100) / 100,
                odometer:
                  Math.round(parseFloat(unitsLengthDisplayConversion(rowData.odometerValue, unitsLength)) * 100) / 100,
                deviceStatus: deviceStatus,
              };

              const vehicleHasActiveGeotabDevice = rowData?.geotabDevice?.id in activeDevices.geotab;

              let row = null;
              const gpsDeviceDisplay = vehicleHasActiveGeotabDevice ? (
                <React.Fragment>
                  {geotabDeviceSerial != undefined && geotabDeviceSerial != '' && geotabDeviceSerial != '000-000-0000'
                    ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                    : 'Not Installed'}
                </React.Fragment>
              ) : (
                'Not Installed'
              );

              if (displayedTable == 'attributes') {
                row = smallScreen ? (
                  <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                    <TableCell>
                      <div style={{textAlign: 'center'}}>
                        <strong>{rowData.name}</strong>
                      </div>
                      <div>
                        <div>
                          <div>
                            <strong>GPS Device: </strong>
                            {vehicleHasActiveGeotabDevice ? (
                              <React.Fragment>
                                {geotabDeviceSerial != undefined &&
                                geotabDeviceSerial != '' &&
                                geotabDeviceSerial != '000-000-0000'
                                  ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                                  : 'Not Installed'}
                              </React.Fragment>
                            ) : (
                              'Not Installed'
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <strong>VIN:</strong> {rowData.vin}
                      </div>
                      <div>
                        <strong>Make:</strong> {rowData.make}
                      </div>
                      <div>
                        <strong>Model:</strong> {rowData.model}
                      </div>
                      <div>
                        <strong>Width:</strong>{' '}
                        {rowData.width && rowData.width > 0
                          ? unitsLengthSystem == 'metric'
                            ? rowData.width.toFixed(2) + ' M'
                            : unitsLengthDisplayConversion(rowData.width, 'ft').toFixed(2) + ' ft'
                          : 'N/A'}
                      </div>
                      <div>
                        <strong>Type:</strong>{' '}
                        {rowData.machineType ? (
                          machineTypeMapping[rowData.machineType]
                        ) : (
                          <span className='text-danger font-weight-bold'>Not Assigned</span>
                        )}
                      </div>
                      <div>
                        <strong>Permanent Task:</strong>
                        <span
                          className={`${
                            permTaskImpExpired
                              ? 'text-danger font-weight-bold'
                              : permTaskImpExpiryUpcoming
                              ? 'text-warning'
                              : ''
                          }`}
                        >
                          {permTaskDisp}{' '}
                          {permTaskImpExpired
                            ? '(Expired)'
                            : permTaskImpExpiryUpcoming
                            ? `(Expiring on ${expiryDateString})`
                            : ''}
                        </span>
                      </div>
                      <div>
                        <strong>Permanent Implement:</strong>
                        <span
                          className={`${
                            permTaskImpExpired
                              ? 'text-danger font-weight-bold'
                              : permTaskImpExpiryUpcoming
                              ? 'text-warning'
                              : ''
                          }`}
                        >
                          {permImpDisp}{' '}
                          {permTaskImpExpired
                            ? '(Expired)'
                            : permTaskImpExpiryUpcoming
                            ? `(Expiring on ${expiryDateString})`
                            : ''}
                        </span>
                      </div>
                      <div>
                        <strong>Notes:</strong> {rowData.notes}
                      </div>
                    </TableCell>
                    <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                    <TableCell>
                      <div className='font-weight-bold'>{rowData.name}</div>
                      {props.bulkEditMode && (
                        <sub>
                          <strong>GPS Device: </strong> {gpsDeviceDisplay}
                        </sub>
                      )}
                    </TableCell>
                    {!props.bulkEditMode || !userSettings.roleAccess['vehicleAttributesManagement'] ? (
                      <React.Fragment>
                        <TableCell>
                          <div>{gpsDeviceDisplay}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.vin}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.make}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.model}</div>
                        </TableCell>
                        {useVehWidth && (
                          <TableCell>
                            <div>
                              {rowData.width && rowData.width > 0
                                ? unitsLengthSystem == 'metric'
                                  ? rowData.width.toFixed(2) + ' M'
                                  : unitsLengthDisplayConversion(rowData.width, 'ft').toFixed(2) + ' ft'
                                : 'N/A'}
                            </div>
                          </TableCell>
                        )}
                        <TableCell>
                          <div>
                            {rowData.machineType ? (
                              machineTypeMapping[rowData.machineType]
                            ) : (
                              <span className='text-danger font-weight-bold'>Not Assigned</span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <span
                              className={`${
                                permTaskImpExpired ? 'text-danger' : permTaskImpExpiryUpcoming ? 'text-warning' : ''
                              }`}
                            >
                              {permTaskDisp}{' '}
                              {permTaskImpExpired
                                ? '(Expired)'
                                : permTaskImpExpiryUpcoming
                                ? `(Expiring on ${expiryDateString})`
                                : ''}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <span
                              className={`${
                                permTaskImpExpired ? 'text-danger' : permTaskImpExpiryUpcoming ? 'text-warning' : ''
                              }`}
                            >
                              {permImpDisp}{' '}
                              {permTaskImpExpired
                                ? '(Expired)'
                                : permTaskImpExpiryUpcoming
                                ? `(Expiring on ${expiryDateString})`
                                : ''}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.notes}</div>
                        </TableCell>
                        <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell>
                          <BulkEditInputs
                            type='text'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.vin}
                            propertyName='vin'
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='text'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.make}
                            propertyName='make'
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='text'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.model}
                            propertyName='model'
                          />
                        </TableCell>
                        {useVehWidth && (
                          <TableCell>
                            <div>
                              {rowData.width && rowData.width > 0
                                ? unitsLengthSystem == 'metric'
                                  ? rowData.width.toFixed(2) + ' M'
                                  : unitsLengthDisplayConversion(rowData.width, 'ft').toFixed(2) + ' ft'
                                : 'N/A'}
                            </div>
                          </TableCell>
                        )}
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            renderOptions={renderMachineTypeOptions}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.machineType || 0}
                            propertyName='machineType'
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTasks}
                            renderOptions={renderTaskOptions}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.linkedTaskId || ''}
                            propertyName='linkedTaskId'
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditImplements}
                            renderOptions={renderImplementOptions}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.linkedImplementSN || ''}
                            propertyName='linkedImplementSN'
                            submissionErrors={vehiclesBulkEditErrors?.[rowData.serialNumber]?.['linkedImplementSN']}
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='date'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            disabled={!rowData.linkedTaskId && !rowData.linkedImplementSN}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.permanentTaskImpExpiryDate}
                            propertyName='permanentTaskImpExpiryDate'
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='text'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.notes}
                            propertyName='notes'
                          />
                        </TableCell>
                      </React.Fragment>
                    )}
                  </TableRow>
                );
              }
              if (displayedTable == 'features') {
                row = smallScreen ? (
                  <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                    <TableCell>
                      <div style={{textAlign: 'center'}}>
                        <strong>{rowData.name}</strong>
                      </div>
                      <div>
                        <div>
                          <div>
                            <strong>GPS Device: </strong>
                            {vehicleHasActiveGeotabDevice ? (
                              <React.Fragment>
                                {geotabDeviceSerial != undefined &&
                                geotabDeviceSerial != '' &&
                                geotabDeviceSerial != '000-000-0000'
                                  ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                                  : 'Not Installed'}
                              </React.Fragment>
                            ) : (
                              'Not Installed'
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <strong>IC: </strong> {rowData.icActive ? 'Yes' : 'No'}
                      </div>
                      <div>
                        <strong>CabView: </strong> {rowData.cabviewActive ? 'Yes' : 'No'}
                      </div>
                      <div>
                        <strong>Coverage: </strong> {rowData.coverageActive ? 'Yes' : 'No'}
                      </div>
                      <div>
                        <strong>ShopView: </strong> {rowData.shopActive ? 'Yes' : 'No'}
                      </div>
                      <div>
                        <strong>Inspection: </strong> {rowData.inspectionActive ? 'Yes' : 'No'}
                      </div>
                    </TableCell>
                    <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                    <TableCell>
                      <div className='font-weight-bold'>{rowData.name}</div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {vehicleHasActiveGeotabDevice ? (
                          <React.Fragment>
                            {geotabDeviceSerial != undefined &&
                            geotabDeviceSerial != '' &&
                            geotabDeviceSerial != '000-000-0000'
                              ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                              : 'Not Installed'}
                          </React.Fragment>
                        ) : (
                          'Not Installed'
                        )}
                      </div>
                    </TableCell>
                    {!props.bulkEditMode || !userSettings.roleAccess['vehicleFeaturesManagement'] ? (
                      <React.Fragment>
                        <TableCell>
                          <div>{rowData.icActive ? 'Yes' : 'No'}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.cabviewActive ? 'Yes' : 'No'}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.coverageActive ? 'Yes' : 'No'}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.shopActive ? 'Yes' : 'No'}</div>
                        </TableCell>
                        <TableCell>
                          <div>{rowData.inspectionActive ? 'Yes' : 'No'}</div>
                        </TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell>
                          <BulkEditInputs
                            type='checkbox'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.icActive}
                            propertyName='icActive'
                            InputProps={{inputProps: {step: 1, min: 0}}}
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='checkbox'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.cabviewActive}
                            propertyName='cabviewActive'
                            InputProps={{inputProps: {step: 1, min: 0}}}
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='checkbox'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.coverageActive}
                            propertyName='coverageActive'
                            InputProps={{inputProps: {step: 1, min: 0}}}
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='checkbox'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.shopActive}
                            propertyName='shopActive'
                            InputProps={{inputProps: {step: 1, min: 0}}}
                          />
                        </TableCell>
                        <TableCell>
                          <BulkEditInputs
                            type='checkbox'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditVehicles'
                            entityId={rowData.serialNumber}
                            entityIdName='vehicleSN'
                            defaultValue={rowData.inspectionActive}
                            propertyName='inspectionActive'
                            InputProps={{inputProps: {step: 1, min: 0}}}
                          />
                        </TableCell>
                      </React.Fragment>
                    )}
                    {!props.bulkEditMode && (
                      <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                    )}
                  </TableRow>
                );
              }
              return row;
            }
          )}
        </React.Fragment>
      );
    }

    if (displayedTable == 'groups') {
      tableBody = (
        <React.Fragment>
          {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map(
            (rowData, index) => {
              colorFlip = !colorFlip;
              const geotabDeviceId = rowData?.geotabDevice?.id != undefined ? rowData?.geotabDevice?.id : '';
              const geotabDeviceSerial =
                rowData?.geotabDevice?.serialNumber != undefined &&
                rowData?.geotabDevice?.serialNumber != '000-000-0000'
                  ? rowData?.geotabDevice?.serialNumber
                  : '';

              const expiryDateString = rowData.permanentTaskImpExpiryDate ? rowData.permanentTaskImpExpiryDate : '';

              // Try to find beWhereSerial from the geotab device comment
              let beWhereSerial = undefined;
              if (geotabDeviceId) {
                // BeWhere Serial in lower case
                const bewhereSerialRegex = /btsae[0-9]{14}/g;
                const geotabComment = activeDevices.geotab[geotabDeviceId]?.comment;
                if (geotabComment) {
                  // convert comment to all lowercase to avoid missing serial due to case sensitivity
                  const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
                  if (beWhereSerialFound) {
                    // convert matched serial to uppercase for display
                    beWhereSerial = beWhereSerialFound[0].toUpperCase();
                  }
                }
              }

              const editVehicleData = {
                name: rowData.name,
                vehicleSN: rowData.serialNumber,
                icActive: rowData.icActive,
                cabviewActive: rowData.cabviewActive,
                coverageActive: rowData.coverageActive,
                shopActive: rowData.shopActive,
                inspectionActive: rowData.inspectionActive,
                archived: rowData.archived,
                vin: rowData.vin,
                make: rowData.make,
                model: rowData.model,
                width: rowData.width,
                machineType: rowData.machineType,
                rowApplicationType: rowData.rowApplicationType,
                linkedTaskId: rowData.linkedTaskId,
                linkedImplementSN: rowData.linkedImplementSN,
                notes: rowData.notes,
                geotabDeviceId: geotabDeviceId,
                geotabDeviceSerial: geotabDeviceSerial,
                swapDevice: false,
                beWhereSerial: beWhereSerial,
                permanentTaskImpExpiryDate: expiryDateString,
                btHubId: rowData.btHubId,
                btHubIdCurr: rowData.btHubId,
                vehiclesGroups: vehiclesGroupsByVehicleSN[rowData.serialNumber] || [],
              };

              if (rowData.linkedImplementSN != '') {
                if (Object.prototype.hasOwnProperty.call(implementSNDict, rowData.linkedImplementSN)) {
                  editVehicleData.linkedTaskId = implementSNDict[rowData.linkedImplementSN].linkedTaskId;
                }
              }
              const deviceStatus = deviceStatuses[rowData.serialNumber];

              const entryVehicleData = {
                ...entryVehicle,
                vehicleName: rowData.name,
                vehicleSN: rowData.serialNumber,
                machineType: rowData.machineType,
                engineHours: Math.round(parseFloat(rowData.engineHoursValue) * 100) / 100,
                odometer:
                  Math.round(parseFloat(unitsLengthDisplayConversion(rowData.odometerValue, unitsLength)) * 100) / 100,
                deviceStatus: deviceStatus,
              };
              const vehicleHasActiveGeotabDevice = rowData?.geotabDevice?.id in activeDevices.geotab;

              const row = smallScreen ? (
                <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                  <TableCell>
                    <div style={{textAlign: 'center'}}>
                      <strong>{rowData.name}</strong>
                    </div>
                    <div>
                      <div>
                        <div>
                          <strong>GPS Device: </strong>
                          {vehicleHasActiveGeotabDevice ? (
                            <React.Fragment>
                              {geotabDeviceSerial != undefined &&
                              geotabDeviceSerial != '' &&
                              geotabDeviceSerial != '000-000-0000'
                                ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                                : 'Not Installed'}
                            </React.Fragment>
                          ) : (
                            'Not Installed'
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <strong>Groups: </strong>
                      </div>
                      <Tooltip title='Vehicle Type'>
                        <Chip
                          size='small'
                          variant='outlined'
                          className='mr-1'
                          label={rowData.machineType ? machineTypeMapping[rowData.machineType] : machineTypeMapping['0']}
                          color='info'
                        />
                      </Tooltip>
                      {(vehiclesGroupsByVehicleSN[rowData.serialNumber] || []).map((groupId) => {
                        return (
                          <Chip
                            key={`${rowData.serialNumber}${groupId}`}
                            size='small'
                            variant='outlined'
                            className='mr-1'
                            label={vehiclesGroupsDict[groupId].name}
                            color='warning'
                          />
                        );
                      })}
                    </div>
                  </TableCell>
                  <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                  <TableCell>
                    <div className='font-weight-bold'>{rowData.name}</div>
                  </TableCell>
                  <TableCell>
                    <div>
                      {vehicleHasActiveGeotabDevice ? (
                        <React.Fragment>
                          {geotabDeviceSerial != undefined &&
                          geotabDeviceSerial != '' &&
                          geotabDeviceSerial != '000-000-0000'
                            ? editVehicleData.beWhereSerial ?? geotabDeviceSerial
                            : 'Not Installed'}
                        </React.Fragment>
                      ) : (
                        'Not Installed'
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip title='Vehicle Type'>
                      <Chip
                        size='small'
                        variant='outlined'
                        className='mr-1'
                        label={rowData.machineType ? machineTypeMapping[rowData.machineType] : machineTypeMapping['0']}
                        color='info'
                      />
                    </Tooltip>
                    {(vehiclesGroupsByVehicleSN[rowData.serialNumber] || []).map((groupId) => {
                      return (
                        <Chip
                          key={`${rowData.serialNumber}${groupId}`}
                          size='small'
                          variant='outlined'
                          className='mr-1'
                          label={vehiclesGroupsDict[groupId].name}
                          color='warning'
                        />
                      );
                    })}
                  </TableCell>
                  {!props.bulkEditMode && <TableCell>{renderEditButton(editVehicleData, entryVehicleData)}</TableCell>}
                </TableRow>
              );
              return row;
            }
          )}
        </React.Fragment>
      );
    }

    return tableBody;
  }

  function generateTable() {
    return (
      <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
        {generateTableHeaders()}
        <TableBody>{generateTableBody()}</TableBody>
      </Table>
    );
  }

  return (
    <React.Fragment>
      {(newEquipmentEnabled || userSettings.role == 'ic_admin') && (
        <NewEquipmentModal vehicleInfoHandleUpdate={handleUpdateData} />
      )}
      <VehicleEditModal
        smallScreen={smallScreen}
        displayedTable={displayedTable}
        editModalOpen={editModalOpen}
        entryVehicle={entryVehicle}
        editVehicle={editVehicle}
        unitsLength={unitsLength}
        handleEditModalOpen={handleEditModalOpen}
        handleUpdateData={handleUpdateData}
        handleUpdateAsyncData={handleUpdateAsyncData}
        toggleGroupModalOpened={toggleGroupModalOpened}
        postVehicleUpdates={props.postVehicleUpdates}
        postVehiclesGroupsUpdateByVehicles={props.postVehiclesGroupsUpdateByVehicles}
      />
      <VehiclesGroupsModal
        opened={groupModalOpened}
        toggleGroupModalOpened={toggleGroupModalOpened}
        handleUpdateData={handleUpdateData}
      />
      <TabMenuTableWrapper
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </React.Fragment>
  );
}

export {VehicleInfoTable};
