import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ModalRowSection, ModalWideColumnSectionCentered, ModalBody} from '../../components/Modal';

import {
  setStoreInspectionItems,
  setInspectionSelectedVehicle,
  setInspectionFormSubmitted,
  setInspectionReport,
} from './inspectionFormSlice';
import {CircularProgress} from '@mui/material';

function InspectionFormSubmissionConfirmModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleCreate() {
    // Reset all inspection form temp data
    dispatch(setStoreInspectionItems([]));
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionFormSubmitted(false));
    dispatch(setInspectionReport({}));
    dispatch(setStoreInspectionItems([]));
  }

  function handleTryAgain() {
    // Reset all inspection form temp data
    dispatch(setInspectionFormSubmitted(false));
  }

  function handleTryAgain() {
    // Reset all inspection form temp data
    dispatch(setInspectionFormSubmitted(false));
  }

  function handleHome() {
    // Reset all inspection form temp data
    dispatch(setStoreInspectionItems([]));
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionFormSubmitted(false));
    dispatch(setInspectionReport({}));
    dispatch(setStoreInspectionItems([]));

    // Navigate back to home page
    navigate('/');
  }

  if (props.submissionState == 'pending') {
    return (
      <div>
        <ModalBody>
          <ModalRowSection>
            <ModalWideColumnSectionCentered>
              <CircularProgress size={100} />
            </ModalWideColumnSectionCentered>
          </ModalRowSection>
        </ModalBody>
      </div>
    );
  } else if (props.submissionState == 'success') {
    return (
      <div>
        <ModalBody>
          <ModalRowSection>
            <ModalWideColumnSectionCentered>
              <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' color='green' size='5x' className='mb-3' />
              <h2 className='font-weight-bold'>Inspection Log Submitted!</h2>
              <h3 className='font-weight-bold'>(Formulario De Inspección Enviado!)</h3>
            </ModalWideColumnSectionCentered>

            <ModalWideColumnSectionCentered>
              <button
                type='button'
                className='btn btn-primary mt-3'
                onClick={handleHome}
                style={{width: 200, height: 70}}
              >
                Home
                <br />
                (Inicio)
              </button>
            </ModalWideColumnSectionCentered>

            <ModalWideColumnSectionCentered>
              <button
                type='button'
                className='btn btn-secondary mt-3'
                onClick={handleCreate}
                style={{width: 200, height: 70}}
              >
                Create Another
                <br />
                (Crear Otro)
              </button>
            </ModalWideColumnSectionCentered>
          </ModalRowSection>
        </ModalBody>
      </div>
    );
  } else {
    return (
      <div>
        <ModalBody>
          <ModalRowSection>
            <ModalWideColumnSectionCentered>
              <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' color='red' size='5x' className='mb-3' />
              <h2 className='font-weight-bold'>Inspection Log Failed To Submit Due To Poor Connection</h2>
              <h3 className='font-weight-bold'>(Formulario De Inspección No Se Pudo Enviar Debido A Mala Conexión)</h3>
            </ModalWideColumnSectionCentered>

            <ModalWideColumnSectionCentered>
              <button
                type='button'
                className='btn btn-primary mt-3'
                onClick={handleTryAgain}
                style={{width: 200, height: 70}}
              >
                Try Again
                <br />
                (Intenta De Nuevo)
              </button>
            </ModalWideColumnSectionCentered>
          </ModalRowSection>
        </ModalBody>
      </div>
    );
  }
}

export {InspectionFormSubmissionConfirmModal};
