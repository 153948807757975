import React, {useEffect} from 'react';

function Tailselect({
  id,
  name,
  search,
  className,
  placeholder,
  width,
  addCustom,
  addCustomDefaultGroup,
  onChange,
  value,
  multiple,
  options,
  children,
  disabled,
}) {
  useEffect(() => {
    const select = tail.select(`#${id}`, {
      search: search,
      classNames: className,
      searchMarked: false,
      // hideSelected: false,
      // hideDisabled: true,
      // multiContainer: false,
      // multiShowCount: true,
      // multipleSelectAll: Option to show [All] [None] to select all/deselect all
      multiSelectAll: multiple ? true : false,
      placeholder: placeholder,
      width: width,
      addCustom: addCustom,
      addCustomDefaultGroup: addCustomDefaultGroup,
      pauseOnChangeEvents: pauseOnChangeEvents,
      activateOnChangeEvents: activateOnChangeEvents,
    });
  }, []);

  useEffect(() => {
    const select = tail.select(`#${id}`);
    select.reload();

    // Remove existing callbacks and add new
    select.events = {};
    if (onChange) {
      select.on('change', function (item, state) {
        onChange(document.getElementById(id), item, state);
      });
    }
  }, [children, options]);

  function activateOnChangeEvents() {
    // Remove existing callbacks and add new
    const select = tail.select(`#${id}`);
    if (select) {
      select.events = {};
      if (onChange) {
        select.on('change', function (item, state) {
          onChange(document.getElementById(id), item, state);
        });
        onChange(document.getElementById(id));
      }
    }
  }

  function pauseOnChangeEvents() {
    const select = tail.select(`#${id}`);
    if (select && select?.events) {
      select.events = {};
    }
  }

  return (
    <select id={id} name={name} multiple={multiple} value={value} onChange={onChange} disabled={disabled}>
      {populateOptions()}
    </select>
  );

  function populateOptions() {
    // Options for the select can be decalred in three ways:
    // 1. HTML option elements passed directly as children of the Tailselect element
    // 2. Array of objects with the properties: value, text, group (optional)
    // 3. Array of strings or numbers, in which case the option value and text will be equal

    if (children) {
      return children;
    } else if (Array.isArray(options)) {
      if (typeof options[0] === 'object') {
        // Deep copy options to be safe
        options = options.map((option) => {
          return {...option};
        });
        // Separate options into groups
        const groups = options.reduce((r, a) => {
          a.group = a.group || '';
          r[a.group] = r[a.group] || [];
          r[a.group].push(a);
          return r;
        }, Object.create(null));

        return Object.keys(groups).map((grpLabel, grpIndex) => {
          if (grpLabel != '') {
            return (
              <optgroup label={grpLabel} key={grpIndex} disabled={disabled}>
                {groups[grpLabel].map((option, index) => {
                  return (
                    <option value={option.value} key={index} disabled={disabled}>
                      {option.text}
                    </option>
                  );
                })}
              </optgroup>
            );
          } else {
            return groups[grpLabel].map((option, index) => {
              return (
                <option value={option.value} key={index} disabled={disabled}>
                  {option.text}
                </option>
              );
            });
          }
        });
      } else {
        return options.map((option, index) => {
          return (
            <option value={option} key={index} disabled={disabled}>
              {option}
            </option>
          );
        });
      }
    }
  }
}

export {Tailselect};
