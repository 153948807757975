import React, {useState, useEffect, useMemo} from 'react';
import {FormControl, Select, MenuItem, ListSubheader, TextField, InputAdornment, Typography, InputLabel, Paper} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const containsText = function (text, searchText) {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

function IntelliSearchSelect({
  name,
  id,
  value,
  options,
  disabled,
  onChange,
  className,
  label,
  allNoneEnabled = false,
  multiple = false,
  onOpenFocusSearch = false,
  placeholder = 'Select...',
  optionDisplayLimit = 4,
}) {
  const [searchText, setSearchText] = useState('');
  const [hoverAll, setHoverAll] = useState(false);
  const [hoverNone, setHoverNone] = useState(false);

  const displayedOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    return options.filter((option) => {
      return containsText(option.label, searchText);
    });
  }, [searchText, options]);

  useEffect(() => {}, [value]);

  return (
    <FormControl fullWidth>
      {label && (
        <InputLabel sx={{maxWidth: 'none'}} shrink>
          {label}
        </InputLabel>
      )}
      <Select
        // Disables auto focus on MenuItems to allow TextField search to be in focus
        MenuProps={{
          autoFocus: false,
          sx: {
            '& .MuiList-root': {
              paddingTop: 0,
              maxHeight: '300px',
              height: 'auto',
            },
            '& .MuiListSubheader-root': {
              padding: 1,
              paddingBottom: 0,
            },
            '& .MuiMenuItem-root': {
              paddingY: '2px',
            },
          },
        }}
        sx={{backgroundColor: 'white'}}
        id={id}
        name={name}
        value={value}
        label={label}
        className={className}
        multiple={multiple}
        disabled={disabled}
        size='small'
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onClose={() => {
          setSearchText('');
        }}
        displayEmpty
        renderValue={(value) => {
          if (!multiple && value) {
            const valueOption = options.find((option) => {
              return option.value == value;
            });
            if (valueOption) {
              return valueOption.label;
            }
          }
          if (multiple && value && value.length > 0) {
            const valueOptions = value.map((singleValue) => {
              const valueOption = options.find((option) => {
                return option.value == singleValue;
              });
              return valueOption ? valueOption.label : 'unknown value';
            });
            return valueOptions.length > optionDisplayLimit
              ? `${valueOptions.length} options selected`
              : valueOptions.join(', ');
          }
          return <Typography color='gray'>{placeholder}</Typography>;
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size='small'
            // disable Autofocus on textfield so keybaord does not open by default
            autoFocus={onOpenFocusSearch}
            placeholder='Type to search...'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {allNoneEnabled && multiple && (
          <ListSubheader>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '3px',}}>
              <button
                onClick={() => {
                  const allOptions = displayedOptions.map((option) => {
                    return option.value;
                  })
                  onChange(allOptions);
                }}
                onMouseOut={() => {
                  setHoverAll(false);
                }}
                onMouseOver={() => {
                  setHoverAll(true);
                }}
                style={{
                  textShadow: 'none',
                  margin: '0 2px',
                  padding: '2px 6px',
                  lineHeight: '14px',
                  fontSize: '10px',
                  borderWidth: '1px',
                  borderRadius: '3px',
                  color: !hoverAll ? '#aaa' : '#62c462',
                  backgroundColor: 'transparent',
                  borderColor: !hoverAll ? '#ccc' : '#62c462',
                }}
              >
                All
              </button>
              <button
                onClick={() => {
                  onChange([]);
                }}
                onMouseOut={() => {
                  setHoverNone(false);
                }}
                onMouseOver={() => {
                  setHoverNone(true);
                }}
                style={{
                  textShadow: 'none',
                  margin: '0 2px',
                  padding: '2px 6px',
                  lineHeight: '14px',
                  fontSize: '10px',
                  borderWidth: '1px',
                  borderRadius: '3px',
                  color: !hoverNone ? '#aaa' : '#ee5f5b',
                  backgroundColor: 'transparent',
                  borderColor: !hoverNone ? '#ccc' : '#ee5f5b',
                }}
              >
                None
              </button>
            </div>
          </ListSubheader>
        )}
        {displayedOptions.map((option, i) => {
          return (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export {IntelliSearchSelect};
