import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {ModalHeader, ModalFooter, ModalBody, ModalWarning} from '../../components/Modal';
import {InspectionFormTable} from './inspectionFormTable';

import {
  setInspectionSelectedVehicle,
  setInspectionFormSubmitted,
  setInspectionReport,
  setStoreInspectionItems,
  setInspectionScannedVehicle,
} from './inspectionFormSlice';

function InspectionFormTableModal(props) {
  const dispatch = useDispatch();

  // Collect the needed data to populate the table modal
  const inspectionReportConfigs = useSelector((state) => {
    return state.app.customerSettings.inspection;
  });

  const inspectionReport = useSelector((state) => {
    return state.inspectionForm.inspectionReport;
  });

  const inspectionNameInput = useSelector((state) => {
    return state.inspectionForm.inspectionNameInput;
  });

  const [formTableModalWarnings, setFormTableModalWarnings] = useState({
    noInspectionEntered: false,
    notAllInspectionsEntered: false,
    notAllFailedInspectionsCommented: false,
  });

  // Function handler for when the submit button is pressed
  function inspectionFormCancel() {
    dispatch(setInspectionSelectedVehicle({}));
    dispatch(setInspectionReport({}));
    dispatch(setStoreInspectionItems([]));
  }

  // Function handler for when the submit button is pressed
  function inspectionFormSubmit() {
    // Check the collection of items in inspectionReport
    if (
      !inspectionReport ||
      Object.keys(inspectionReport).length === 0 ||
      !Object.prototype.hasOwnProperty.call(inspectionReport, 'items')
    ) {
      setFormTableModalWarnings({
        noInspectionEntered: false,
        notAllInspectionsEntered: true,
        notAllFailedInspectionsCommented: false,
      });
      return;
    } else {
      // Remember if any non unknowns are found
      let knownsFound = false;

      for (let i = 0; i < inspectionReport.items.length; i++) {
        if (typeof inspectionReport.items[i].passed == 'boolean') {
          knownsFound = true;
        }

        if (
          inspectionReportConfigs.inspectionReportAllItemsComplete &&
          (typeof inspectionReport.items[i].passed != 'boolean' || inspectionReport.items[i].passed == 'unknown')
        ) {
          // Enforce all items selected if enabled
          setFormTableModalWarnings({
            noInspectionEntered: false,
            notAllInspectionsEntered: true,
            notAllFailedInspectionsCommented: false,
          });
          return;
        } else if (
          inspectionReportConfigs.inspectionReportCommentAllFailures &&
          inspectionReport.items[i].passed === false &&
          inspectionReport.items[i].comments === ''
        ) {
          // Enforce all failed items have comments if enabled
          setFormTableModalWarnings({
            noInspectionEntered: false,
            notAllInspectionsEntered: false,
            notAllFailedInspectionsCommented: true,
          });
          return;
        } else if (i == inspectionReport.items.length - 1) {
          if (!knownsFound) {
            // Enforce all items selected if enabled
            setFormTableModalWarnings({
              noInspectionEntered: true,
              notAllInspectionsEntered: false,
              notAllFailedInspectionsCommented: false,
            });
            return;
          } else {
            // Clear the scanned vehicle status such the select option is no longer tied to cabview
            dispatch(setInspectionScannedVehicle(''));
            // Change the form state which should trigger the correct view transitions
            dispatch(setInspectionFormSubmitted(true));
          }
        }
      }
    }
  }

  // Create modal for a inspection statuses to entered
  return (
    <div>
      <ModalHeader title='Create Inspection Report' noCloseButton={true} />
      <ModalBody>
        {inspectionReportConfigs.inspectionReportNameInput && (
          <div>
            Enter by: <strong>{inspectionNameInput}</strong>
          </div>
        )}
        <InspectionFormTable />
        {formTableModalWarnings.noInspectionEntered && <ModalWarning text='Please check an inspection. ' />}
        {formTableModalWarnings.notAllInspectionsEntered && <ModalWarning text='Please check all inspections. ' />}
        {formTableModalWarnings.notAllFailedInspectionsCommented && (
          <ModalWarning text='Please provide comments on all failed inspections. ' />
        )}
      </ModalBody>
      <ModalFooter onSubmit={inspectionFormSubmit} submitText='Submit' onCancel={inspectionFormCancel} />
    </div>
  );
}

export {InspectionFormTableModal};
