import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, deepCopy, isNumeric, unionLabelsAndDataVehicles} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genServiceCostsGraphData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiServiceCosts(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const compareByServicesData = useSelector((state) => {
    return state.dashboard.compareByServicesData;
  });
  const compareByVehicleSNDict = useSelector((state) => {
    return state.dashboard.compareByVehicleSNDict;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });

  const [serviceCostsData, setServiceCostsData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [vehicleSnOptions, setVehicleSnOptions] = useState([]);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [stackedComparison, setStackedComparison] = useState(false);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);

  const [serviceCostsFilters, setServiceCostsFilters] = useState({
    serviceCostsVehicleSNs: [],
    serviceCostsVehicleType: [],
    serviceCostsVehicleCount: '10',
    serviceCostsVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (servicesData != null) {
      generateData();
    }
  }, [
    servicesData,
    vehicleSNDict,
    serviceCostsFilters,
    displayedAxis,
    stackedComparison,
    compareByToggle,
    compareByServicesData,
  ]);

  useEffect(() => {
    if (servicesData != null) {
      generateVehicleFilterOptions();
    }
  }, [servicesData]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.stackedComparison) {
      setStackedComparison(props.pageStates?.stackedComparison);
    } else {
      setStackedComparison(false);
    }
    if (props?.pageFilters) {
      setServiceCostsFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  function generateData() {
    // Generate data
    let compareByServiceCostsDataTemp = null;
    const servicesDataCopy = deepCopy(servicesData);
    const compareByServicesDataCopy = deepCopy(compareByServicesData);
    if (compareByToggle && compareByServicesData != null) {
      const [unifiedServicesDataVehicles, unifiedCompareByServicesDataVehicles] = unionLabelsAndDataVehicles(
        servicesDataCopy.vehicleServices,
        compareByServicesDataCopy.vehicleServices,
        ['vehicleName']
      );
      servicesDataCopy.vehicleServices = unifiedServicesDataVehicles;
      compareByServicesDataCopy.vehicleServices = unifiedCompareByServicesDataVehicles;
      compareByServiceCostsDataTemp = genServiceCostsGraphData(
        compareByServicesDataCopy,
        displayedAxis,
        serviceCostsFilters,
        compareByVehicleSNDict,
        true
      );
    }

    const serviceCostsDataTemp = genServiceCostsGraphData(
      servicesDataCopy,
      displayedAxis,
      serviceCostsFilters,
      vehicleSNDict
    );
    if (Object.keys(servicesData.vehicleServices).length > 0) {
      setServiceCostsData(serviceCostsDataTemp);
    } else {
      setServiceCostsData(null);
    }

    const tempDatasets = [];
    serviceCostsDataTemp.datasets.forEach((setObj) => {
      const splitSetObj = setObj.id.split('.');
      if (setObj.id == displayedAxis && stackedComparison == false) {
        tempDatasets.push(setObj);
        if (compareByServiceCostsDataTemp != null && compareByToggle) {
          compareByServiceCostsDataTemp.datasets.forEach((compareSetObj) => {
            if (compareSetObj.id == displayedAxis && stackedComparison == false) {
              tempDatasets.push(compareSetObj);
            }
          });
        }
      } else if (splitSetObj[0] == displayedAxis && setObj.id.includes('.') && stackedComparison == true) {
        tempDatasets.push(setObj);
        if (compareByServiceCostsDataTemp != null && compareByToggle) {
          compareByServiceCostsDataTemp.datasets.forEach((compareSetObj) => {
            const compareSplitSetObj = compareSetObj.id.split('.');

            if (
              compareSplitSetObj[0] == displayedAxis &&
              compareSetObj.id.includes('.') &&
              stackedComparison == true &&
              compareSetObj.id == setObj.id
            ) {
              tempDatasets.push(compareSetObj);
            }
          });
        }
      }
    });
    setDatasets(tempDatasets);
  }

  function generateVehicleFilterOptions() {
    // Get vehicle filter options
    const newVehicleSnOptions = Object.keys(servicesData.vehicleServices).map((vehicleSN) => {
      return {value: vehicleSN, text: servicesData.vehicleServices[vehicleSN].vehicleName};
    });
    setVehicleSnOptions(newVehicleSnOptions);
  }

  function handleVehiclesFilters(value, selectName) {
    setServiceCostsFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function serviceCostsGraph() {
    let graphTitle;
    let yAxisTitle;
    let xAxisTitle;

    switch (displayedAxis) {
      case 'PartsCosts':
        graphTitle = 'Total Parts Costs';
        yAxisTitle = 'Parts Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborCosts':
        graphTitle = 'Total Labor Costs';
        yAxisTitle = 'Labor Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborTime':
        graphTitle = 'Total Labor Time';
        yAxisTitle = 'Labor Time (hrs)';
        xAxisTitle = 'Vehicle';
        break;
      default:
        graphTitle = 'Total Service Costs (Parts + Labor)';
        yAxisTitle = 'Service Costs ($)';
        xAxisTitle = 'Vehicle';
    }

    return (
      <div style={{height: '50vh', minHeight: '400px'}}>
        {/* Chart */}
        <BarChart
          data={{...serviceCostsData, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                stacked: stackedComparison,
                title: {
                  text: yAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
              },
              x: {
                stacked: stackedComparison,
                ticks: {
                  autoSkip: serviceCostsData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 0,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: xAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function filterControlComponents() {
    return [
      /* Vehicle Filter */
      <IntelliSearchSelect
        allNoneEnabled
        label='Vehicles'
        id='dashboard-vehicles-serviceCostsVehicleSNs-filter'
        key='dashboard-vehicles-serviceCostsVehicleSNs-filter'
        name='serviceCostsVehicleSNs'
        multiple={true}
        search={true}
        value={serviceCostsFilters.serviceCostsVehicleSNs}
        options={vehicleSnOptions.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceCostsVehicleSNs');
        }}
        placeholder='Filter for Vehicles'
      />,
      /* Vehicle Type Filter */
      <IntelliSearchSelect
        allNoneEnabled
        label='Vehicle Type'
        id='dashboard-vehicles-serviceCostsVehicleType-filter'
        key='dashboard-vehicles-serviceCostsVehicleType-filter'
        name='serviceCostsVehicleType'
        multiple={true}
        search={true}
        value={serviceCostsFilters.serviceCostsVehicleType}
        options={filterOptions.vehicles.vehicleType.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceCostsVehicleType');
        }}
        placeholder='Filter for Vehicle Type'
      />,
    ];
  }

  function displayControlComponents() {
    return [
      /* Vehicle Sort Filter */
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-vehicles-serviceCostsVehicleSort-filter'
        key='dashboard-vehicles-serviceCostsVehicleSort-filter'
        name='serviceCostsVehicleSort'
        multiple={false}
        value={serviceCostsFilters.serviceCostsVehicleSort}
        options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceCostsVehicleSort');
        }}
        placeholder='Sorting method'
      />,
      /* Vehicle Count Filter */
      <IntelliSearchSelect
        label='Vehicle Count'
        id='dashboard-vehicles-serviceCostsVehicleCount-filter'
        key='dashboard-vehicles-serviceCostsVehicleCount-filter'
        name='serviceCostsVehicleCount'
        multiple={false}
        value={serviceCostsFilters.serviceCostsVehicleCount}
        options={filterOptions.vehicles.vehicleCount.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'serviceCostsVehicleCount');
        }}
        placeholder={`Show  All Vehicles`}
      />,
      <div key='stack-comparison' className='col-12 flex-col text-center px-0 align-items-center my-auto'>
        <div className='text-center align-items-center my-auto'>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setStackedComparison(!stackedComparison);
                }}
                checked={stackedComparison}
              />
            }
            label='Compare Scheduled / Unscheduled'
          />
        </div>
      </div>,
    ];
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!serviceCostsData ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            serviceCostsGraph()
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Service Costs'}
        pageFilters={serviceCostsFilters}
        pageStates={{
          displayedAxis: displayedAxis,
          stackedComparison: stackedComparison,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {!serviceCostsData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              {/* Filters */}
              <StatsViewReportFiltersWrapper key={`serviceCostsFilter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              {/* Sort By */}
              <StatsViewReportSortByWrapper key={`serviceCostsSort`}>
                {displayControlComponents()}
              </StatsViewReportSortByWrapper>
            </React.Fragment>
          )}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Y-Axis Controls */}
                <Grid
                  item
                  xs={2}
                  lg={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '5px',
                      },
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      transformOrigin: 'center',
                      transform: 'rotate(270deg)',
                      margin: 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{m: 1}}>
                      <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                      <Select
                        sx={{padding: '3px'}}
                        size='small'
                        label='Measure by'
                        value={displayedAxis}
                        onChange={(e) => {
                          setDisplayedAxis(e.target.value);
                        }}
                      >
                        <MenuItem value={'Total'}>{'Total Costs'}</MenuItem>
                        <MenuItem value={'PartsCosts'}>{'Parts Costs'}</MenuItem>
                        <MenuItem value={'LaborCosts'}>{'Labor Costs'}</MenuItem>
                        <MenuItem value={'LaborTime'}>{'Labor Time'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Graph */}
                <Grid item xs={10} lg={9} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {serviceCostsGraph()}
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiServiceCosts};
