import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {CustomTablePagination} from '../../../components/CustomTablePagination';

import {AlertViewModal} from './AlertViewModal';

import {searchFind, machineTypeMapping, KM_TO_MI} from '../../../app/utils';

import {Table, TableHead, TableBody, TableCell, TableRow} from '@mui/material';

function AlertsTab(props) {
  const loading = useSelector((state) => {
    return state.alertview.loading;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [filters, setFilters] = useState({
    alert: [],
    vehicle: [],
    type: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    alert: [],
    vehicle: [],
    type: [],
  });
  const [dates, setDates] = useState({start: null, end: null, max: null});
  const [tableData, setTableData] = useState([]);
  const [tableDataUnfiltered, setTableDataUnfiltered] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertEventData, setAlertEventData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [alertsLoading, setAlertsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (dates.start == null || dates.end == null) {
      initData();
    }
  }, []);

  useEffect(() => {
    if (dates.start !== null && dates.end !== null) {
      getAlerts();
    }
  }, [dates]);

  useEffect(() => {
    filterTableRows(tableDataUnfiltered);
  }, [tableDataUnfiltered, filters, searchText]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function filterTableRows(allTableData) {
    const tempTableData = allTableData.filter((rowData) => {
      return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }

    setTableData(tempTableData);
  }

  function rowFilter(rowData, row) {
    const alertInFilter = filters.alert.includes(rowData.alert) || filters.alert.length == 0;
    const vehicleInFilter = filters.vehicle.includes(rowData.vehicle) || filters.vehicle.length == 0;
    const typeInFilter = filters.type.includes(rowData.type) || filters.type.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return alertInFilter && vehicleInFilter && typeInFilter && search;
  }

  function handleModalOpen() {
    setModalOpen(!modalOpen);
  }

  function handleResetAlertEventData() {
    setAlertEventData({});
  }

  function datePickerRender() {
    let buttonDisplayString = '';

    if (
      typeof dates.start !== 'undefined' &&
      typeof dates.end !== 'undefined' &&
      dates.start != null &&
      dates.end != null
    ) {
      const startDateObj = DateTime.fromISO(dates.start).setZone(customerSettings.general.timeZone);
      const endDateObj = DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone);
      buttonDisplayString =
        ` ${startDateObj.toFormat('L/d/yy h:mm a')} ` + `- ${endDateObj.toFormat('L/d/yy h:mm a')} `;

      // Often for when selecting a single day
      // Due to a certain shift time the start and end time will be the same
      // This is to prevent selection of a 0 minute range
      let zeroMinuteRange = false;
      if (startDateObj.hasSame(endDateObj, 'minute')) {
        zeroMinuteRange = true;
      }

      return (
        <DateRangePicker
          // key={keyRef.current}
          onApply={dateSelection}
          initialSettings={{
            startDate: DateTime.fromISO(dates.start)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            endDate: DateTime.fromISO(dates.end)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            maxDate: DateTime.fromISO(dates.max)
              .setZone(customerSettings.general.timeZone)
              .toFormat('MM/dd/yyyy HH:mm'),
            timePicker: true,
            timePicker24Hour: true,
            locale: {
              format: 'MM/DD/YYYY HH:mm',
            },
          }}
          style={{height: '100%'}}
        >
          <button className='btn border-dark btn-light col-12 cropview-menu-text'>
            <FontAwesomeIcon icon='fas fa-calendar-alt' />
            {zeroMinuteRange ? (
              <span className='text-danger font-weight-bold'> 0 MIN TIME RANGE SELECTED</span>
            ) : (
              buttonDisplayString
            )}
          </button>
        </DateRangePicker>
      );
    }
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <div className='row my-2 mx-0'>
          <div className='col-6 col-md-5 px-0'>{datePickerRender()}</div>
          <button className='btn border-dark btn-light ml-auto col-6 col-md-2' onClick={downloadCsv}>
            Download CSV
          </button>
        </div>
      </React.Fragment>
    );
  }

  function generateTableData() {
    if (loading || alertsLoading) {
      return (
        <TableBody id='table-body'>
          <TableRow>
            <TableCell colSpan={100}>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let colorFlip = false;
    const paginatedData =
      rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData;

    return (
      <TableBody id='table-body'>
        {paginatedData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }

          colorFlip = !colorFlip;
          const row = (
            <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
              <TableCell>
                <div className='mb-2'>
                  <div className='text-secondary font-weight-bold'>{rowData.alert}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.vehicle}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.type}</div>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <div className='text-secondary'>{rowData.alertEventList.length}</div>
                </div>
              </TableCell>
              <TableCell className='text-center align-right'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm'
                    onClick={() => {
                      setAlertEventData(rowData);
                      setModalOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-map-marker-alt' />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          );
          return row;
        })}
      </TableBody>
    );
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Alert'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-alerts-alert-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.alert.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-alerts-alert-select'
                name='alert'
                multiple={true}
                search={true}
                value={filters.alert}
                options={filterOptions.alert}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='25%'>
            <TableHeadFilterButtonTitleWrapper title='Equipment'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-alerts-vehicle-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.vehicle.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-alerts-vehicle-select'
                name='vehicle'
                multiple={true}
                search={true}
                value={filters.vehicle}
                options={filterOptions.vehicle}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Alert Type'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-alerts-type-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.type.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-alerts-type-select'
                name='type'
                multiple={true}
                search={true}
                value={filters.type}
                options={filterOptions.type}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell width='30%'>Occurrences</TableCell>
          <TableCell width='5%'></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className='tab-wrapper'>
      <AlertViewModal
        modalOpen={modalOpen}
        handleModalOpen={handleModalOpen}
        alertEventData={alertEventData}
        handleResetAlertEventData={handleResetAlertEventData}
      />

      <div className='table-responsive'>
        <TabMenuTableWrapper
          menu={generateMenu()}
          table={
            <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
              {generateTableHeaders()}
              {generateTableData()}
            </Table>
          }
          pagination={
            <CustomTablePagination
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        />
      </div>
    </div>
  );

  function getShiftDates() {
    // Get startDate for current shift
    const now = DateTime.local({zone: customerSettings.general.timeZone});
    let shiftStartDate = DateTime.local({
      zone: customerSettings.general.timeZone,
    }).set({
      hour: customerSettings.general.hourOffSet,
      minute: customerSettings.general.minuteOffSet,
      second: 0,
      millisecond: 0,
    });

    // Shift started previous day if startDate is greater than now
    if (shiftStartDate > now) {
      shiftStartDate = shiftStartDate.plus({days: -1});
    }

    // Get endDate for current shift
    const shiftEndDate = shiftStartDate.plus({days: 1, milliseconds: -1});

    return [shiftStartDate, shiftEndDate];
  }

  function dateSelection(event, picker) {
    // Clear the filters
    setFilters({
      alert: [],
      vehicle: [],
      type: [],
    });

    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});

    // Get shift dates
    const [shiftStartDate, shiftEndDate] = getShiftDates();

    const newDataDates = {
      start: startDate.toISO(),
      end: endDate.toISO(),
      max: shiftEndDate.toISO(),
    };

    setDates(newDataDates);
  }

  function initData() {
    // Init using current shift
    const [shiftStartDate, shiftEndDate] = getShiftDates();

    // Check if values were inputted through url
    const startParam = decodeURIComponent(searchParams.get('start'));
    const endParam = decodeURIComponent(searchParams.get('end'));

    let startTime = DateTime.fromISO(startParam).setZone(customerSettings.general.timeZone);
    let endTime = DateTime.fromISO(endParam).setZone(customerSettings.general.timeZone);

    // Ensure the url values are valid or else they need to be re-initilizationed
    if (!startTime.isValid || !endTime.isValid || (startTime >= shiftEndDate && endTime >= shiftEndDate)) {
      // If dates are after the lastUploadTime, or the values are invalid, set to the 24hr before
      startTime = shiftStartDate;
      endTime = shiftEndDate;
    } else if (startTime.isValid && endTime.isValid && startTime < shiftEndDate && endTime >= shiftEndDate) {
      // Otherwise the dates are valid, check if the end time is after the lastUploadTime
      endTime = shiftEndDate;
    }

    // keyRef.current = DateTime.now();
    const newDates = {
      start: startTime.toISO(),
      end: endTime.toISO(),
      max: shiftEndDate.toISO(),
    };
    setDates(newDates);
  }

  function downloadCsv() {
    const csvList = [];
    tableData.forEach((rowData, index) => {
      rowData.alertEventList.forEach((alertEvent) => {
        csvList.push([
          [
            `\"${rowData.alert}\"`,
            `\"${rowData.vehicle}\"`, // Used to ignore commas and breaks
            rowData.type,
            rowData.rule.conditionString,
            alertEvent.startTime,
            alertEvent.endTime,
            alertEvent.targetValue,
            alertEvent.measuredValue,
            alertEvent.units,
          ],
        ]);
      });
    });

    // eslint-disable-next-line max-len
    let csv = `Alert,Vehicle,Alert Type,Condition, Start Time,End Time,Target Value,Measured Value,Units\n`;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'alertEvents.csv';
    hiddenElement.click();
  }

  async function getAlerts() {
    setAlertsLoading(true);

    // Update url and replace in history
    window.history.replaceState(
      null,
      '',
      window.location.origin +
        window.location.pathname +
        `?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}`
    );

    // Fetch data
    const alertsDataResponse = await fetch(
      `/alertview/getAlertsData?start=${encodeURIComponent(dates.start)}&end=${encodeURIComponent(dates.end)}`,
      {cache: 'no-store'}
    );
    const alertsData = await alertsDataResponse.json();

    // Format alert events into table data, group by alert name and vehicle
    const tempTableData = [];
    const alertFilterOptions = [];
    const vehicleFilterOptions = [];
    const typeFilterOptions = [];
    // Iterate through rules
    Object.keys(alertsData.alertEventsDict).forEach((ruleId) => {
      // Get rule name and type
      const rule = alertsData.rulesDict[ruleId];

      let ruleTypeText;
      if (rule.type == 'farmSpeeding') {
        ruleTypeText = 'Farm Speeding';
      } else if (rule.type == 'roadSpeeding') {
        ruleTypeText = 'Road Speeding';
      } else if (rule.type == 'idling') {
        ruleTypeText = 'Idling';
      } else if (rule.type == 'afterHours') {
        ruleTypeText = 'After Hours Work';
      }

      // Iterate through vehicles
      Object.keys(alertsData.alertEventsDict[ruleId]).forEach((vehicleSN) => {
        let vehicleName = '';
        let machineType = 'Not Assigned';

        // Iterate through alert events
        const conditionsGroups = {};
        alertsData.alertEventsDict[ruleId][vehicleSN].forEach((alertEvent) => {
          // Determine speed unit conversion
          const speedUnits = unitsLengthSystem == 'imperial' ? 'MPH' : 'KPH';
          let ruleValue = alertEvent.ruleconditions.value;
          if (
            ['farmSpeeding', 'roadSpeeding'].includes(rule.type) &&
            ruleValue != 'taskTargetSpeed' &&
            unitsLengthSystem == 'imperial'
          ) {
            ruleValue = Math.round(ruleValue * KM_TO_MI);
          }

          // Determine the condition string
          let logicText;
          if (alertEvent.ruleconditions.logic == 'greaterThan') {
            logicText = 'greater than';
          } else if (alertEvent.ruleconditions.logic == 'lessThan') {
            logicText = 'less than';
          } else if (alertEvent.ruleconditions.logic == 'notEqualTo') {
            logicText = 'not equal to';
          }

          let conditionString;
          if (rule.type == 'farmSpeeding') {
            let speedText = `${ruleValue} ${speedUnits}`;
            if (ruleValue == 'taskTargetSpeed') {
              speedText = 'task target speed';
            }

            conditionString = `If farm speed is ${logicText} ${speedText}`;
          } else if (rule.type == 'roadSpeeding') {
            conditionString = `If road speed is greater than speed limit by ${ruleValue} ${speedUnits}`;
          } else if (rule.type == 'idling') {
            conditionString = `If idling duration is greater than ${ruleValue} minutes`;
          } else if (rule.type == 'afterHours') {
            const startTimeText = DateTime.now()
              .set({
                hour: ruleValue.startHour,
                minute: ruleValue.startMinute,
              })
              .toLocaleString(DateTime.TIME_SIMPLE);

            const endTimeText = DateTime.now()
              .set({
                hour: ruleValue.endHour,
                minute: ruleValue.endMinute,
              })
              .toLocaleString(DateTime.TIME_SIMPLE);

            conditionString = `If after hours work is performed outside of the hours ${startTimeText} and ${endTimeText}`;
          }

          // Get vehicle name
          vehicleName = alertEvent.vehicleName;
          machineType = alertEvent.vehicleMachineType;

          // Create date string
          const startTimeStr = DateTime.fromISO(alertEvent.starttime)
            .setZone(customerSettings.general.timeZone)
            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
          const endTimeStr = DateTime.fromISO(alertEvent.endtime)
            .setZone(customerSettings.general.timeZone)
            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
          const dateString = `${startTimeStr} - ${endTimeStr}`;

          // Determine speed unit conversion
          let targetValue = alertEvent.targetvalue;
          let measuredValue = alertEvent.measuredvalue;
          if (['farmSpeeding', 'roadSpeeding'].includes(rule.type) && unitsLengthSystem == 'imperial') {
            targetValue = Math.round(targetValue * KM_TO_MI);
            measuredValue = Math.round(measuredValue * KM_TO_MI);
          }

          let units = '';
          if (rule.type == 'idling') {
            units = 'minutes';
          } else if (['farmSpeeding', 'roadSpeeding'].includes(rule.type)) {
            units = speedUnits;
          }

          // Alert data formatted
          const formattedAlertData = {
            id: alertEvent.uid,
            vehicle: vehicleName,
            startTime: alertEvent.starttime,
            endTime: alertEvent.endtime,
            dateString: dateString,
            measuredValue: measuredValue,
            targetValue: targetValue,
            units: units,
            path: alertEvent.logRecords,
          };

          if (typeof conditionsGroups[conditionString] == 'undefined') {
            conditionsGroups[conditionString] = [];
          }
          conditionsGroups[conditionString].push(formattedAlertData);
        });

        Object.keys(conditionsGroups).forEach((conditionString) => {
          const formattedGroupData = {
            alert: rule.name,
            vehicle: vehicleName,
            vehicleSN: vehicleSN,
            type: ruleTypeText,
            rule: rule,
            conditionString: conditionString,
            alertEventList: conditionsGroups[conditionString],
          };

          tempTableData.push(formattedGroupData);
        });

        // Add to filter options
        alertFilterOptions.push({
          value: rule.name,
          text: rule.name,
        });
        vehicleFilterOptions.push({
          value: vehicleName,
          text: vehicleName,
          group: machineTypeMapping[machineType],
        });
        typeFilterOptions.push({
          value: ruleTypeText,
          text: ruleTypeText,
        });
      });
    });

    const alertFilterOptionsSorted = alertFilterOptions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    const vehicleFilterOptionsSorted = vehicleFilterOptions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    const tempTableDataSortedByVeh = tempTableData.sort((a, b) => {
      return a.vehicle.localeCompare(b.vehicle);
    });
    const tempTableDataSorted = tempTableDataSortedByVeh.sort((a, b) => {
      return a.alert.localeCompare(b.alert);
    });

    setTableDataUnfiltered(tempTableDataSorted);
    setFilterOptions({
      ...filterOptions,
      alert: alertFilterOptionsSorted,
      vehicle: vehicleFilterOptionsSorted,
      type: typeFilterOptions,
    });
    setAlertsLoading(false);
  }
}

export {AlertsTab};
